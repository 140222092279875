import { useState, useRef, useEffect } from "react";
import * as Sentry from "@sentry/react";
import {
  useLocation,
  Routes,
  Route,
  Navigate,
  useNavigate,
  useParams,
} from "react-router-dom";
import { saveAs } from "file-saver";
import FullLoader from "components/FullLoader";
import { IoClose, IoResize } from "react-icons/io5";
import Cluster from "./Cluster";
import ErrorBoundary from "./ErrorBoundary";
import ClusterList from "./ClusterList";
import AddCluster from "./AddCluster";
import ImportCluster from "./ImportCluster";
import BillingSetupFlow from "./BillingSetupFlow";
import ObjectStorage from "./ObjectStorage";
import AddAddress from "./BillingSetupFlow/AddAddress";
import AddProject from "./AddProject";
import Project from "./Project";
import Users from "./Users";
import GeneralSettings from "./Settings/General.jsx";
import BillingSettings from "./Settings/Billing.jsx";
import TestSettings from "./TestSettings";
import Overview from "./Overview";
import ApiKeys from "./ApiKeys";
import Header from "components/Header";
import Sidebar from "components/Sidebar";
import {
  useAxios,
  useInterval,
  useApiResource,
  usePaginatedApiResource,
} from "hooks";
import { TeamContext } from "hooks/useTeam";
import { UserContext } from "hooks/useUser";
import { ToastContext } from "hooks/useToast";
import cn from "classnames";

import styles from "./index.module.scss";

const ScrollToTop = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
};

const REFRESH_PROJECTS_INTERVAL_MS = 15000;
const REFRESH_CLUSTERS_INTERVAL_MS = 15000;
const REFRESH_FORECAST_INTERVAL_MS = 15 * 60 * 1000;

function ToastItem({
  type,
  createdAt,
  title,
  message,
  expanded,
  onRemove,
  onToggleExpand,
}) {
  const clampedEl = useRef();
  const [isClamped, setIsClamped] = useState(false);
  useEffect(
    () =>
      setIsClamped(
        clampedEl.current.scrollHeight > clampedEl.current.clientHeight
      ),
    [expanded]
  );

  // Store whether it has clamped at any point so we can override the conditional for showing the more/less button, as otherwise it will flicker on update
  const [hasClampedAtOnePoint, setHasClampedAtOnePoint] = useState(false);
  useEffect(
    () =>
      setHasClampedAtOnePoint(
        clampedEl.current.scrollHeight > clampedEl.current.clientHeight
      ),
    []
  );

  return (
    <dl
      className={cn(
        {
          "bg-red-400": type === "error",
          "bg-orange-300": type === "warn",
          "bg-blue-400": type === "info",
        },
        "w-80 rounded-lg bg-red-400 text-white p-3 text-sm"
      )}
      key={createdAt}
    >
      <dt className="font-bold mb-1 w-full flex justify-between">
        {title}
        <button type="button" className="text-right" onClick={() => onRemove()}>
          <IoClose />
        </button>
      </dt>
      <dd
        ref={clampedEl}
        className={cn(
          expanded && styles.toastExpanded,
          styles.toastMessage,
          "text-ellipsis overflow-hidden"
        )}
      >
        {message}
      </dd>
      {(isClamped || hasClampedAtOnePoint) && (
        <button
          type="button"
          className="mt-1 font-bold"
          onClick={() => onToggleExpand()}
        >
          {expanded ? "Less" : "More"} <IoResize className="inline-block" />
        </button>
      )}
    </dl>
  );
}

function ToastManager({ children }) {
  const [toasts, setToasts] = useState([]);

  const handleToast = (title, message, type) => {
    setToasts([
      ...toasts,
      {
        title,
        message,
        type,
        expanded: false,
        createdAt: new Date(),
      },
    ]);
  };
  const context = {
    error: (title, msg) => handleToast(title, msg, "error"),
    info: (title, msg) => handleToast(title, msg, "info"),
    warn: (title, msg) => handleToast(title, msg, "warn"),
    // Creates an error-toast based on the exception thrown, handling AxiosExceptions and Errors
    exception: (title, exception) => {
      const errorMessage = exception.response
        ? exception.response.data?.message
        : exception.message;
      const errorTitle =
        title || exception.response?.data?.error || exception.name;

      handleToast(errorTitle, errorMessage, "error");
    },
  };

  const handleRemoveToast = (index) => {
    setToasts(toasts.filter((_, i) => i !== index));
  };

  const handleToggleExpand = (index) => {
    const newToasts = [...toasts];
    newToasts[index].expanded = !newToasts[index].expanded;
    setToasts(newToasts);
  };

  return (
    <ToastContext.Provider value={context}>
      <div className="fixed right-3 top-3 z-30 grid gap-3">
        {toasts.map((x, i) => (
          <ToastItem
            key={`${x.title}${x.createdAt}`}
            title={x.title}
            message={x.message}
            type={x.type}
            expanded={x.expanded}
            onRemove={() => handleRemoveToast(i)}
            onToggleExpand={() => handleToggleExpand(i)}
          />
        ))}
      </div>
      {children}
    </ToastContext.Provider>
  );
}

function DownloadClusterIdentity() {
  const { clusterId } = useParams();
  const axios = useAxios();
  const navigate = useNavigate();

  useEffect(() => {
    async function download() {
      const { data } = await axios.get(
        `/rest/v1/cluster/${clusterId}/identity`
      );
      var blob = new Blob([data.kubeConfig], {
        type: "text/plain;charset=utf-8",
      });

      saveAs(blob, "config");
      navigate(`/clusters/${clusterId}`);
    }
    download();
  }, [axios, clusterId, navigate]);

  return null;
}

export default function TeamApp({ teamId, onLogout, onChangeTeam }) {
  const navigate = useNavigate();
  const {
    data: team,
    isFetching: isFetchingTeam,
    error: teamError,
  } = useApiResource(`/rest/v1/team/${teamId}`);
  const {
    data: user,
    isFetching: isFetchingUser,
    error: userError,
  } = useApiResource(`/rest/v1/user`);
  const {
    data: billingForecast,
    isFetching: isFetchingBillingForecast,
    refresh: refreshForecast,
  } = useApiResource(`/rest/v1/billing/forecast`);
  const { data: projects, refresh: refreshProjects } =
    useApiResource(`/rest/v1/project`);

  const { content: clusters, refresh: refreshClusters } =
    usePaginatedApiResource(`/rest/v1/cluster`, 0, { sort: "name" });

  useInterval(refreshProjects, REFRESH_PROJECTS_INTERVAL_MS);
  useInterval(refreshClusters, REFRESH_CLUSTERS_INTERVAL_MS);
  useInterval(refreshForecast, REFRESH_FORECAST_INTERVAL_MS);

  // Configure Sentry context once team and user is loaded
  useEffect(() => {
    if (user && team) {
      Sentry.setUser({
        id: user.id,
        username: user.name,
        email: user.email,
        team_id: team.team.id,
        team_name: team.team.name,
        ip_address: "{{auto}}",
      });
    }
  }, [user, team]);

  const axios = useAxios();
  axios.defaults.headers.common["X-Stim-Team"] = teamId;

  if (isFetchingTeam || isFetchingUser || isFetchingBillingForecast) {
    return <FullLoader className="h-screen" />;
  }

  if (userError || teamError) {
    return <Navigate to="/select-team" />;
  }

  return (
    <TeamContext.Provider value={team}>
      <UserContext.Provider value={user}>
        <ToastManager>
          <ScrollToTop />
          <Header
            className="z-20 relative"
            billingForecast={billingForecast}
            onLogout={onLogout}
            onChangeTeam={onChangeTeam}
          />
          <Sidebar clusters={clusters} projects={projects || []}>
            <main className="h-full">
              <ErrorBoundary>
                <Routes>
                  <Route path="projects/new" element={<AddProject />} />
                  <Route path="projects/:projectName/*" element={<Project />} />
                  <Route
                    path="clusters/new"
                    element={<AddCluster onSubmitted={refreshClusters} />}
                  />
                  <Route
                    path="clusters/import"
                    element={<ImportCluster onSubmitted={refreshClusters} />}
                  />
                  <Route
                    path="clusters/:clusterId/identity"
                    element={<DownloadClusterIdentity />}
                  />
                  <Route path="clusters/:clusterId/*" element={<Cluster />} />
                  <Route path="clusters" element={<ClusterList />} />
                  <Route path="object-storage" element={<ObjectStorage />} />
                  <Route path="users" element={<Users />} />
                  <Route path="api-keys" element={<ApiKeys />} />
                  <Route
                    path="billing-setup/*"
                    element={<BillingSetupFlow />}
                  />
                  <Route
                    path="settings/billing/address"
                    element={
                      <AddAddress
                        onSubmit={() => navigate(`/settings/billing`)}
                      />
                    }
                  />
                  <Route
                    path="settings/billing"
                    element={<BillingSettings />}
                  />
                  <Route path="settings" element={<GeneralSettings />} />
                  <Route path="test-settings" element={<TestSettings />} />
                  <Route path="*" element={<Overview />} />
                </Routes>
              </ErrorBoundary>
            </main>
          </Sidebar>
        </ToastManager>
      </UserContext.Provider>
    </TeamContext.Provider>
  );
}
