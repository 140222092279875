import { useNavigate } from "react-router-dom";
import useAxios from "hooks/useAxios";

export default function Deactivated() {
  const navigate = useNavigate();
  const axios = useAxios();

  const handleReactivate = async () => {
    await axios.post("/rest/v1/user/activate");
    navigate("/select-team");
  };

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Page content */}
      <main className="flex-grow bg-gradient-to-b from-gray-100 to-white">
        <section className="max-w-lg mx-auto">
          <h1 className="h1 text-center pt-20 pb-8">
            Your account is deactivated.
          </h1>
          <button
            className="w-full btn-sm btn-blue mt-10"
            onClick={handleReactivate}
          >
            Re-activate
          </button>
        </section>
      </main>
    </div>
  );
}
