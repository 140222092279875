import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Field } from "components";
import AxiosErrorAlert from "components/AxiosErrorAlert";
import useAxios from "hooks/useAxios";
import { HiCog } from "react-icons/hi";

const createTeamSchema = Yup.object().shape({
  teamName: Yup.string()
    .min(3, "Team name must be longer than 3 characters")
    .max(64, "Team name can't be more than 64 characters")
    .required("You need to provide a team name to continue"),
});

export default function CreateTeam({ onChangeTeam }) {
  const axios = useAxios();
  const navigate = useNavigate();

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Page content */}
      <main className="flex-grow bg-gradient-to-b from-gray-100 to-white">
        <Link
          className="py-5 px-10 float-right flex items-center gap-1"
          to="/profile"
        >
          <HiCog className="inline-block" /> My Account
        </Link>
        <section className="max-w-6xl mx-auto px-4 sm:px-6">
          <Formik
            validateOnBlur
            validationSchema={createTeamSchema}
            initialValues={{ teamName: "" }}
            onSubmit={async (
              values,
              { setSubmitting, setStatus, setErrors }
            ) => {
              try {
                const { data } = await axios.post("/rest/v1/team", {
                  name: values.teamName,
                });
                onChangeTeam(data.id);
                navigate("/");
              } catch (err) {
                const fieldErrors = err?.response?.data?.errors;
                if (fieldErrors) {
                  const fieldErrorMappings = fieldErrors.reduce(
                    (a, v) => ({ ...a, [v.field]: v.defaultMessage }),
                    {}
                  );
                  setErrors(fieldErrorMappings);
                }
                setStatus(err);
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({ isSubmitting, status }) => {
              return (
                <Form className="max-w-sm mx-auto">
                  <div className="pt-20 pb-4">
                    <h1 className="h1 text-center pb-2">Create a Team.</h1>
                    <p className="mt-3 text-gray-700">
                      Teams form the basis for organising resources in
                      Symbiosis. Your team name can be changed later on.
                    </p>
                    <p className="mt-3 text-gray-700">
                      <b>Note:</b> The team name will appear on future invoices.
                    </p>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-4 px-3">
                    <Field.Text
                      autoFocus
                      className="w-full text-gray-800"
                      name="teamName"
                      placeholder="Enter a team name..."
                    />
                  </div>
                  <AxiosErrorAlert className="mb-2" error={status} />
                  <div className="flex flex-wrap -mx-3 mt-6">
                    <div className="w-full px-3">
                      <button
                        disabled={isSubmitting}
                        type="submit"
                        className="btn text-white bg-blue-600 hover:bg-blue-700 w-full"
                      >
                        Create
                      </button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </section>
      </main>
    </div>
  );
}
