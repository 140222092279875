import FullLoader from "components/FullLoader";
import useApiResource from "hooks/useApiResource";
import { Routes, Route, useParams, Navigate } from "react-router-dom";
import Run from "./Run";
import Preview from "./Preview";
import routes from "./routes";

export default function ProjectRouter() {
  const { projectName } = useParams();
  const {
    data: project,
    isFetching,
    refresh,
    error,
  } = useApiResource(`/rest/v1/project/${projectName}`);

  if (isFetching) {
    return <FullLoader />;
  }

  // Throw error only if we don't have any data, otherwise it might be an error due to a refresh, in which case we ignore it
  if (error && !project) {
    throw error;
  }

  if (!project) return <Navigate to="/projects/new" />;

  return (
    <Routes>
      {routes.map((x) => (
        <Route
          key={x.path}
          exact
          path={x.path}
          element={<x.component project={project} onRefreshProject={refresh} />}
        />
      ))}
      <Route path="run/:runId" element={<Run project={project} />} />
      <Route
        path="preview/:pullRequestId"
        element={<Preview project={project} />}
      />
      <Route path="*" render={<Navigate to={`/projects/${projectName}`} />} />
    </Routes>
  );
}
