import FullLoader from "components/FullLoader";
import CopyToClipboardField from "components/CopyToClipboardField";
import useApiResource from "hooks/useApiResource";
import usePrettyTimeElapsedSince from "hooks/usePrettyTimeElapsedSince";
import { Table } from "components";

function LoadBalancerTableRow({ loadBalancer }) {
  const [name, namespace] = loadBalancer.name.split(".");
  const elapsedSince = usePrettyTimeElapsedSince(loadBalancer.createdAt);
  return (
    <Table.Row className="">
      <Table.Cell>
        <span className="font-medium text-gray-700">{name}</span>
      </Table.Cell>
      <Table.Cell>
        <span className="text-gray-700">{namespace}</span>
      </Table.Cell>
      <Table.Cell>
        <CopyToClipboardField text={loadBalancer.ipv4Address}>
          <span className="text-gray-700 font-mono">
            {loadBalancer.ipv4Address}
          </span>
        </CopyToClipboardField>
      </Table.Cell>
      <Table.Cell>
        <CopyToClipboardField text={loadBalancer.ipv6Address}>
          <span className="text-gray-700 font-mono">
            {loadBalancer.ipv6Address || "None"}
          </span>
        </CopyToClipboardField>
      </Table.Cell>
      <Table.Cell>{elapsedSince}</Table.Cell>
    </Table.Row>
  );
}

export default function LoadBalancers({ cluster }) {
  const {
    data: loadBalancers,
    error,
    isFetching,
  } = useApiResource(`/rest/v1/load-balancer/layer-4?clusterId=${cluster.id}`);
  if (error) throw error;

  if (isFetching) return <FullLoader />;

  return (
    <Table className="mt-5">
      <Table.Head>
        <Table.Row>
          <Table.Cell>Service Name</Table.Cell>
          <Table.Cell>Namespace</Table.Cell>
          <Table.Cell>Ipv4 Address</Table.Cell>
          <Table.Cell>Ipv6 Address</Table.Cell>
          <Table.Cell>Created</Table.Cell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {loadBalancers?.map((x) => (
          <LoadBalancerTableRow key={x.id} loadBalancer={x} />
        ))}
        {!loadBalancers?.length && !error && (
          <Table.ContentRow>
            <div className="py-3 flex justify-center">
              No load balancers found.
            </div>
          </Table.ContentRow>
        )}
      </Table.Body>
    </Table>
  );
}
