import { useState, useEffect } from "react";
import { FiCornerDownLeft } from "react-icons/fi";
import PsuedoSiteHeader from "partials/PsuedoSiteHeader";
import { useAxios } from "hooks";
import { Link, useNavigate, useLocation } from "react-router-dom";
import ory from "components/kratos/sdk/index.ts"
import { handleFlowError } from "components/kratos/errors"
import { Flow, ActionCard, CenterLink, MarginCard } from "components/kratos"
import queryString from "query-string";

export default function ResetPassword() {
  const [flow, setFlow] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const { flow: flowId, return_to: returnTo } = queryString.parse(location.search)

  useEffect(() => {
    if (flow) {
      return
    }

    if (flowId) {
      ory
        .getRecoveryFlow({ id: String(flowId) })
        .then(({ data }) => {
          setFlow(data)
        })
        .catch(handleFlowError(navigate, "recovery", setFlow))
      return
    }

    ory
      .createBrowserRecoveryFlow()
      .then(({ data }) => {
        setFlow(data)
      })
      .catch(handleFlowError(navigate, "recovery", setFlow))
      .catch((err) => {
        if (err.response?.status === 400) {
          setFlow(err.response?.data)
          return
        }
        return Promise.reject(err)
      })
  }, [flowId, navigate, returnTo, flow])

  const onSubmit = (values) => {
    return ory
      .updateRecoveryFlow({
        flow: String(flow?.id),
        updateRecoveryFlowBody: values,
      })
      .then(({ data }) => {
        // Form submission was successful, show the message to the user!
        setFlow(data)
      })
      .catch(handleFlowError(navigate, "recovery", setFlow))
      .catch((err) => {
        if (err.response?.status === 400) {
          setFlow(err.response?.data)
          return
        }

      return Promise.reject(err)
      })
  }


  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <main className="flex-grow">
        <section className="bg-gradient-to-b from-gray-100 to-white">
          <PsuedoSiteHeader />
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-5 md:pt-4">
              {/* Page header */}
              <div className="max-w-3xl mx-auto text-center pb-4 md:pb-8">
                <h1 className="h1">Reset password.</h1>
              </div>

              <div className="max-w-sm mx-auto">
                <Flow onSubmit={onSubmit} flow={flow} />
                <div className="flex flex-wrap -mx-3 mt-6">
                  <div className="w-full border-t border-gray-300 pt-6 mx-3">
                    <Link
                      to="/signin"
                      className="btn text-white bg-gray-800 hover:bg-gray-900 w-full text-center block"
                    >
                      Go back <FiCornerDownLeft className="inline-block" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
