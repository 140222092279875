import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import PsuedoSiteHeader from "partials/PsuedoSiteHeader";
import ory from "components/kratos/sdk/index.ts"

export default function AuthError() {
  const [error, setError] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = queryString.parse(location.search)

  useEffect(() => {
    // If the router is not ready yet, or we already have an error, do nothing.
    if (error) {
      return
    }

    ory
      .getFlowError({ id: String(id) })
      .then(({ data }) => {
        setError(data)
      })
      .catch((err) => {
        switch (err.response?.status) {
          case 404:
          // eslint-disable-next-line no-fallthrough
          case 403:
          // eslint-disable-next-line no-fallthrough
          case 410:
            // The error id expired. Let's just redirect home!
            return navigate("/")
          default:
        }

        return Promise.reject(err)
      })
  }, [id, error, navigate])

  if (!error) {
    return null
  }

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Page content */}
      <main className="flex-grow">
        <section className="bg-gradient-to-b from-gray-100 to-white">
          <PsuedoSiteHeader />
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-5 md:pt-4">
              {/* Page header */}
              <div className="max-w-3xl mx-auto text-center pb-4 md:pb-8">
                <h1 className="h1">Error.</h1>
              </div>

              {/* Form */}
              <div className="max-w-sm mx-auto">
                {error.error.message}
                <Link to="/">
                  Go back
                </Link>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
