import useFeatureGate from "hooks/useFeatureGate";

export default function FeatureGate({
  name,
  children = null,
  fallback = null,
}) {
  const { enabled } = useFeatureGate(name);
  if (!enabled) return fallback;
  return children;
}
