import cn from "classnames";

export default function UserIdenticon({ email, pictureUrl, size, className }) {
  return pictureUrl ? (
    <img
      alt={`Avatar for ${email}`}
      src={pictureUrl}
      className={cn(className, "inline-block rounded shadow")}
      style={{ width: size, height: size }}
    />
  ) : null;
}
