import { useState } from "react";
import { saveAs } from "file-saver";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "hooks/useAxios";
import { Dialog, Input, CopyToClipboardField } from "components";

function DeleteClusterDialog({
  clusterId,
  isOpen,
  onSubmit,
  isSubmitting,
  onClose,
}) {
  const [confirmValue, setConfirmValue] = useState("");
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Dialog.Panel>
        <Dialog.Title className="whitespace-nowrap text-ellipsis overflow-hidden">
          Delete cluster
        </Dialog.Title>
        <Dialog.Description>
          <div>
            Are you sure you want to delete cluster <b>{clusterId}</b>? This
            action is irreversible.
          </div>
          <div className="mt-1">
            Enter <b>{clusterId}</b> in the box below and confirm to proceed.
          </div>
          <Input
            value={confirmValue}
            onChange={(e) => setConfirmValue(e.target.value)}
            className="my-3"
            placeholder="Enter the name of the cluster"
          />
        </Dialog.Description>
        <div className="flex justify-end">
          <button
            className="w-full sm:w-auto btn btn-outline"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="w-full ml-3 sm:w-auto btn btn-red"
            disabled={isSubmitting || confirmValue !== clusterId}
            onClick={onSubmit}
          >
            Delete
          </button>
        </div>
      </Dialog.Panel>
    </Dialog>
  );
}

function generatePulumiExport(cluster) {
  const elements = [{
    "type": "symbiosis:index/cluster:Cluster",
    "name": cluster.name,
    "id": cluster.name,
  }];
  cluster.nodePools.forEach(pool => {
    elements.push({
      "type": "symbiosis:index/nodePool:NodePool",
      "name": pool.name,
      "id": pool.id,
    })
  });
  return JSON.stringify({resources: elements}, null, 4);
}

export default function ClusterSettings({ cluster }) {
  const axios = useAxios();
  let { clusterId } = useParams();
  const navigate = useNavigate();
  const [deleteClusterDialogOpen, setDeleteClusterDialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleDeleteCluster = async () => {
    setIsSubmitting(true);
    try {
      await axios.delete(`/rest/v1/cluster/${clusterId}`);
    } finally {
      setIsSubmitting(false);
    }
    navigate("/cluster");
  };

  const handlePulumiDownload = async () => {
    const pulumiExport = generatePulumiExport(cluster)
    const blob = new Blob([pulumiExport], {
      type: "application/json;charset=utf-8",
    });
    saveAs(blob, `${cluster.name}.json`);
  };

  return (
    <div className="rounded-lg my-5">
      <div className="flex justify-between py-5 px-5">
        <div>
          <h3 className="font-medium text-gray-700 text-lg leading-none">
            Export to Pulumi
          </h3>
          <span className="text-gray-600 text-sm">
            Download and import your cluster with <CopyToClipboardField text={`pulumi import -f ${cluster.name}.json`}>pulumi import -f {cluster.name}.json</CopyToClipboardField>.
          </span>
        </div>
        <button
          className="btn-sm btn-outline flex items-center"
          onClick={handlePulumiDownload}
        >
          Download
        </button>
      </div>
      <div className="flex justify-between py-5 px-5">
        <div>
          <h3 className="font-medium text-gray-700 text-lg leading-none">
            Delete this cluster
          </h3>
          <span className="text-gray-600 text-sm">
            Deleting a cluster is permanent!
          </span>
        </div>
        <button
          className="btn-sm btn-red flex items-center"
          onClick={() => setDeleteClusterDialogOpen(true)}
        >
          Delete cluster
        </button>
        {/* Bug: Hack since it won't render if user has routed from another page with a Dialog */}
        {deleteClusterDialogOpen && (
          <DeleteClusterDialog
            clusterId={clusterId}
            onClose={() => setDeleteClusterDialogOpen(false)}
            onSubmit={handleDeleteCluster}
            isSubmitting={isSubmitting}
            isOpen={deleteClusterDialogOpen}
          />
        )}
      </div>
    </div>
  );
}
