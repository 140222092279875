import cn from "classnames";

export default function HelperText({ children, valid, className }) {
  return (
    <div
      className={cn(
        valid === true && "text-green-600",
        valid === false && "text-red-600",
        "text-xs mx-1",
        className
      )}
    >
      {children}
    </div>
  );
}
