import { useState } from "react";
import { Alert } from "components";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

export default function AddPaymentMethod({ returnUrl }) {
  const [error, setError] = useState();
  const [isReady, setIsReady] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async () => {
    setIsSubmitting(true);
    if (!stripe || !elements) {
      return;
    }

    try {
      const { error } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: returnUrl,
        },
      });

      if (error) setError(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="max-w-md mx-auto">
      <h4 className="h4 text-gray-900 mt-16">Payment Method</h4>
      <p className="text-gray-700 mt-2 mb-3">
        Stripe may perform a test charge to verify your card.
      </p>
      <PaymentElement onReady={() => setIsReady(true)} />
      {error && (
        <div>
          <Alert type="danger">{error}</Alert>
        </div>
      )}
      {isReady && (
        <button
          className="mt-2 sm:w-auto btn-lg btn-blue"
          disabled={!isReady || isSubmitting}
          type="button"
          onClick={handleSubmit}
        >
          Save
        </button>
      )}
    </div>
  );
}
