import { useCallback, useMemo, useState, useEffect, useRef } from "react";
import * as Sentry from "@sentry/react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Deactivated from "pages/Deactivated";
import CreateTeam from "pages/CreateTeam";
import SelectTeam from "pages/SelectTeam";
import TeamView from "pages/TeamView";
import VerifyEmail from "pages/VerifyEmail";
import GlobalErrorBoundary from "pages/GlobalErrorBoundary";
import AuthError from "pages/AuthError";
import Oauth from "pages/Oauth";
import OauthDone from "pages/OauthDone";
import Signin from "pages/Signin";
import Signup from "pages/Signup";
import WaitList from "pages/WaitList";
import Profile from "pages/Profile";
import RedeemDiscount from "pages/RedeemDiscount";
import ResetPassword from "pages/ResetPassword";
import AddProjectCallback from "pages/AddProjectCallback";
import axios from "axios";
import { AxiosContext } from "hooks/useAxios";
import useAxios from "hooks/useAxios";
import { SessionContext } from "hooks/useSession";
import ory from "components/kratos/sdk"

Sentry.init({
  dsn: "https://979896ed388e4802ad09f1732c8f1771@o1070625.ingest.sentry.io/6067108",
  environment: process.env.NODE_ENV,
  beforeSend: (event, hint) => {
    // Dynamically set title for axios errors
    if (hint.originalException?.isAxiosError) {
      const originalResponse = hint.originalException?.response;
      if (originalResponse?.data) {
        event.exception.values[0].value = `${originalResponse.data.error}: ${originalResponse.data.message}`;
      }
      event.request = {
        data: {
          path: event.request.url,
        },
        method: hint.originalException.config?.method,
        url: hint.originalException.config?.url,
        query_string: hint.originalException.config?.params,
        headers: hint.originalException.config?.headers,
      };
    }
    if (process.env.NODE_ENV !== "production") {
      console.log("Sentry Event:", event);
      return null;
    }
    return event;
  },
});

function getAxiosClient(navigate, session) {
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_PATH,
  });

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401) {
        navigate("/signin");
      }
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.request.use(async (config) => {
    if (session?.id)
      config.headers.common["X-Auth-Token"] = session.id;
    axios.defaults.withCredentials = true // TODO set somewhere else
    return config
  });

  return axiosInstance;
}


function TeamViewRoutes({ teamId, onLogout, onChangeTeam }) {
  if (!teamId) return <Navigate to="/select-team" />;
  return (
    <TeamView
      key={teamId}
      teamId={teamId}
      onLogout={onLogout}
      onChangeTeam={onChangeTeam}
    />
  );
}

function AppRoutes() {
  const sessionStorage = window.sessionStorage;
  const [session, setSession] = useState(undefined);
  const [fetchingSession, setFetchingSession] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const [teamId, setTeamId] = useState(sessionStorage.getItem("teamId"));

  useEffect(() => {
    if (fetchingSession)
      return
    if (session?.identity) {
      const email = session.identity.traits.email
      if (email) {
        if (location.pathname.startsWith("/verify-email"))
          navigate("/select-team", { replace: true });
      } else {
        if (
          location.pathname !== "/verify-email" &&
          location.pathname !== "/invitation" &&
          location.pathname !== "/signup" &&
          location.pathname !== "/signin" &&
          location.pathname !== "/projects/installation/done"
        )
          navigate("/verify-email", { replace: true });
      }
    } else {
      if (
        ![
          "/reset-password",
          "/invitation",
          "/signin",
          "/signup",
          "/projects/installation/done",
          "/oauth",
          "/oauth/done",
          "/auth-error",
        ].includes(location.pathname)
      )
        navigate("/signin");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingSession, session])

  const handleSetSession = useCallback(() => {
    ory
      .toSession()
      .then(({ data }) => {
        const session = {...data};
        session.refresh = handleSetSession
        setSession(session);
      })
			.catch((err) => {
        if (err.response?.status === 403 && err.response?.data.error?.id === "session_aal2_required" && !["/signin", "/signup"].includes(location.pathname)) {
          console.warn(`Step-up authentication needed, path: ${location.pathname}`)
          navigate("/signin?aal=aal2", { replace: true })
          return
        }
        console.warn(err);
      })
      .finally(() => {
        setFetchingSession(false)
      });
  }, [location.pathname, navigate]);

  useEffect(() => {
    handleSetSession();
  }, [handleSetSession])


  const axiosInstance = useMemo(() => getAxiosClient(navigate, session), [navigate, session]);

  const handleTeamIdChange = (teamId, redirect) => {
    sessionStorage.setItem("teamId", teamId);
    setTeamId(teamId);
    navigate(redirect || "/");
  };

  const handleLogout = async () => {
    try {
      const res = await ory.createBrowserLogoutFlow()
      await ory.updateLogoutFlow({ token: res.data.logout_token})
    } catch(err) {
      // Ignore error if session doesn't exist)
      if (err.response?.data.id !== "session_inactive") {
        throw err;
      }
    }
    Sentry.configureScope((scope) => scope.setUser(null));
    navigate("/signin")
  };

  const handleLogoutSameUrl = async () => {
    try {
      const res = await ory.createBrowserLogoutFlow()
      await ory.updateLogoutFlow({ token: res.data.logout_token})
    } catch(err) {
      // Ignore error if session doesn't exist)
      if (err.response?.data.id !== "session_inactive") {
        throw err;
      }
    }
    Sentry.configureScope((scope) => scope.setUser(null));
    navigate(0)
  };

  const handleNoTeams = async (signal) => {
    const { data: user } = await axiosInstance.get("/rest/v1/user", { signal });
    if (user.state !== "ACTIVE") {
      return navigate("/deactivated", { replace: true });
    }
    return navigate("/create-team");
  };

  const handleSignin = async () => {
    handleSetSession();
    navigate("/")
  };

  if (fetchingSession)
    return null;

  return (
    <SessionContext.Provider value={session}>
      <AxiosContext.Provider value={axiosInstance}>
        <Routes>
          <Route
            path="/verify-email/done"
            element={
              <Navigate to="/select-team" />
            }
          />
          <Route
            path="/verify-email"
            element={<VerifyEmail onLogout={handleLogout} />}
          />
          <Route
            path="/create-team"
            element={<CreateTeam onChangeTeam={handleTeamIdChange} />}
          />
          <Route path="/profile" element={<Profile onLogout={handleLogout} />} />
          <Route
            path="/select-team"
            element={
              <SelectTeam
                onNoTeams={handleNoTeams}
                onChangeTeam={handleTeamIdChange}
              />
            }
          />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/deactivated" element={<Deactivated />} />
          <Route path="/auth-error" element={<AuthError />} />
          <Route path="/oauth" element={<Oauth />} />
          <Route path="/oauth/done" element={<OauthDone />} />
          <Route path="/signin" element={<Signin onLogout={handleLogoutSameUrl} onSignin={handleSignin} />} />
          <Route path="/signup" element={<Signup onSignup={handleSignin} />} />
          {/**<Route path="/waitlist" element={<WaitList />} />*/}
          <Route
            path="/projects/installation/done"
            element={<AddProjectCallback />}
          />
          <Route
            path="/redeem-discount/:discountId"
            element={<RedeemDiscount teamId={teamId} />}
          />
          <Route
            path="*"
            element={
              <TeamViewRoutes
                teamId={teamId}
                onLogout={handleLogout}
                onChangeTeam={handleTeamIdChange}
              />
            }
          />
        </Routes>
      </AxiosContext.Provider>
    </SessionContext.Provider>
  );
}

function App() {
  return (
    <Router>
      <GlobalErrorBoundary>
        <AppRoutes />
      </GlobalErrorBoundary>
    </Router>
  );
}

export default App;
