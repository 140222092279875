import cn from "classnames";

export default function SymbiosisTitle({ className }) {
  return (
    <div className={cn(className, "inline-block whitespace-nowrap")}>
      <span className="text-gray-800 inline-flex text-lg items-center justify-center inline-block font-bold">
        <span
          style={{ transform: "skewX(-30deg)" }}
          className="inline-block pr-2"
        >
          <div className="bg-gray-800 h-3.5 w-3.5" />
        </span>
        symbiosis
      </span>
    </div>
  );
}
