import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import { useAxios } from "hooks";
import PsuedoSiteHeader from "partials/PsuedoSiteHeader";
import ory from "components/kratos/sdk/index.ts"
import { handleFlowError } from "components/kratos/errors"
import { Flow } from "components/kratos"

export default function Oauth() {
  const [flow, setFlow] = useState();
  const location = useLocation();
  const axios = useAxios();
  const navigate = useNavigate();
  const { redirect, flow: flowId, return_to: returnTo } = queryString.parse(location.search)

  useEffect(() => {
    // If the router is not ready yet, or we already have a flow, do nothing.
    if (flow) {
      return
    }

    if (flowId) {
      ory
        .getLoginFlow({ id: String(flowId) })
        .then(({ data }) => {
          // We received the flow - let's use its data and render the form!
          setFlow(data)
        })
        .catch(handleFlowError(navigate, "registration", setFlow))
      return
    }

    ory
      .createBrowserLoginFlow({
        returnTo: returnTo ? String(returnTo) : undefined,
      })
      .then(({ data }) => {
        setFlow(data)
      })
      .catch(async (err) => {
        if (err.response?.data.error?.id === "session_already_available") {
          const teams = await axios.get("/rest/v1/user/team")
          const session = await ory.toSession()
          const token = session.data.id
          window.location.replace(
            `${redirect}?token=${token}&teamId=${teams.data?.[0].id}`
          )
        } else {
          throw err;
        }
      })
      .catch(handleFlowError(navigate, "registration", setFlow))
  }, [flowId, navigate, returnTo, flow, axios, redirect])

  const onSubmit = (values) => {
    window.history.replaceState(null, undefined, `${location.pathname}?flow=${flow?.id}`)
    return ory.updateLoginFlow({
      flow: String(flow?.id),
      updateLoginFlowBody: values,
    })
      .then(() => axios.get("/rest/v1/user/team"))
      .then(async ({ data: { teams } }) => {
        const session = await ory.toSession()
        const token = session.data.id
        window.location.replace(
          `${redirect}?token=${token}&teamId=${teams?.[0].id}`
        )
      })
      .catch(handleFlowError(navigate, "registration", setFlow))
      .catch((err) => {
        // If the previous handler did not catch the error it's most likely a form validation error
        if (err.response?.status === 400) {
          // Yup, it is!
          setFlow(err.response?.data)
          return
        }

        return Promise.reject(err)
      })
  }

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Page content */}
      <main className="flex-grow">
        <section className="bg-gradient-to-b from-gray-100 to-white">
          <PsuedoSiteHeader />
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-5 md:pt-4">
              {/* Page header */}
              <div className="max-w-3xl mx-auto text-center pb-4 md:pb-8">
                <h1 className="h1">Welcome.</h1>
              </div>

              {/* Form */}
              <div className="max-w-sm mx-auto">
                <Flow onSubmit={onSubmit} flow={flow} />
                <div className="text-gray-600 text-center mt-4 text-sm">
                  Don’t have an account?{" "}
                  <Link
                    to={`/signup${location.search}`}
                    className="text-blue-600 hover:underline transition duration-150 ease-in-out"
                  >
                    Sign up
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
