import { useState, useEffect } from "react";
import Input from "./Input";
import { Label, Select } from "components";
import HelperText from "./HelperText";
import { Switch, Listbox } from "@headlessui/react";
import { FiChevronDown, FiCheck } from "react-icons/fi";
import cn from "classnames";
import { Field as FormikField, useField, useFormikContext } from "formik";

const StandardField =
  (type) =>
  ({ name, className, placeholder, ...props }) =>
    (
      <FormikField name={name}>
        {({ field, meta }) => (
          <div className={className}>
            <Input
              valid={!meta.touched || !meta.error ? undefined : false}
              type={type}
              placeholder={placeholder}
              {...field}
              {...props}
            />
            {meta.touched && meta.error && (
              <HelperText valid={false}>{meta.error}</HelperText>
            )}
          </div>
        )}
      </FormikField>
    );

function NumberStepperNested({
  name,
  field,
  meta,
  className,
  placeholder,
  max,
  min,
  ...props
}) {
  const { setFieldValue } = useFormikContext();
  useEffect(() => {
    let boundedValue = field.value;
    if (min) boundedValue = Math.max(min, boundedValue);
    if (max) boundedValue = Math.min(max, boundedValue);
    if (boundedValue !== field.value) setFieldValue(name, boundedValue);
  }, [min, max, field.value, name, setFieldValue]);

  return (
    <div className="w-full">
      <div className={cn(className, "flex")}>
        <button
          disabled={field.value <= min}
          className={cn(
            meta.error && "border-red-600",
            !meta.error && "border-gray-300",
            field.value <= min && "text-gray-400 cursor-not-allowed",
            "rounded-l border px-4 text-sm focus:z-10 focus-visible:outline-none focus:ring focus:ring-blue-300 focus:border-blue-400"
          )}
          type="button"
          onClick={() =>
            setFieldValue(
              name,
              field.value != null && !isNaN(field.value) && field.value !== ""
                ? parseInt(field.value) - 1
                : 0
            )
          }
        >
          -
        </button>
        {field.value != null && placeholder && (
          <div className="absolute font-semibold top-1 left-2 leading-none text-2xs text-gray-500 select-none mt-px">
            {placeholder}
          </div>
        )}
        <input
          className={cn(
            field.value && placeholder ? "pt-3" : "py-2",
            meta.error && "focus:border-red-600 border-red-600",
            !meta.error && "focus:border-gray-300 border-gray-300",
            "min-w-[3rem] text-center text-sm focus:z-10 text-gray-700 bg-white px-2 leading-0 focus:outline-none focus:ring focus:ring-blue-300 focus:border-blue-400 focus:border-x border-x-0 border-y w-full appearance-none"
          )}
          placeholder={placeholder}
          value={field.value}
          type="number"
          max={max}
          min={min}
          {...field}
          {...props}
        />
        <button
          disabled={field.value >= max}
          className={cn(
            meta.error && "border-red-600",
            !meta.error && "border-gray-300",
            field.value >= max && "text-gray-400 cursor-not-allowed",
            "focus:z-10 rounded-r border px-4 text-sm focus-visible:outline-none focus:ring focus:ring-blue-300 focus:border-blue-400"
          )}
          type="button"
          onClick={() =>
            setFieldValue(
              name,
              field.value != null && !isNaN(field.value) && field.value !== ""
                ? parseInt(field.value) + 1
                : 1
            )
          }
        >
          +
        </button>
      </div>
      {meta.touched && meta.error && (
        <HelperText valid={false}>{meta.error}</HelperText>
      )}
    </div>
  );
}

const Field = {
  Text: StandardField("text"),
  Number: StandardField("number"),
  NumberStepper: ({ name, ...props }) => {
    return (
      <FormikField name={name}>
        {({ field, meta }) => (
          <NumberStepperNested
            name={name}
            field={field}
            meta={meta}
            {...props}
          />
        )}
      </FormikField>
    );
  },
  Select: ({ name, className, placeholder, ...props }) => (
    <FormikField name={name}>
      {({ field, meta }) => (
        <div>
          <Select
            valid={!meta.touched || !meta.error ? undefined : false}
            className={className}
            placeholder={placeholder}
            {...field}
            {...props}
          />
          {meta.touched && meta.error && (
            <HelperText className="" valid={false}>
              {meta.error}
            </HelperText>
          )}
        </div>
      )}
    </FormikField>
  ),
  // options is a list of objects containing keys {value, name}
  Listbox: ({ name, options, className }) => {
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(name);
    const [element, setElement] = useState(
      () => options.filter((x) => x.value === field.value)?.[0]?.element
    );
    return (
      <FormikField name={name}>
        {() => (
          <>
            <Listbox
              as="div"
              className={cn(className, "relative")}
              value={field.value}
              onChange={(x) => {
                setElement(x.element);
                setFieldValue(name, x.value);
              }}
            >
              <Listbox.Button
                className={({ open }) =>
                  cn(
                    open && "ring ring-blue-300 border-blue-400",
                    "relative w-full cursor-default border border-gray-300 rounded bg-white py-2 pl-3 pr-10 text-left sm:text-sm focus:outline-none focus:ring focus:ring-blue-300 focus:border-blue-400"
                  )
                }
              >
                <span className="block truncate">{element}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <FiChevronDown className="text-gray-500" aria-hidden="true" />
                </span>
              </Listbox.Button>
              <Listbox.Options className="absolute mt-2 z-30 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {options?.map(({ value, element }) => (
                  <Listbox.Option
                    key={value}
                    className={cn(
                      value === field.value
                        ? "bg-blue-100 text-blue-900"
                        : "text-gray-900",
                      `hover:bg-blue-100 hover:text-blue-900 relative cursor-default select-none py-2 pl-9 pr-4`
                    )}
                    value={{ element, value }}
                  >
                    <span className="block truncate">{element}</span>
                    {value === field.value ? (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                        <FiCheck />
                      </span>
                    ) : null}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Listbox>
            {meta.touched && meta.error && (
              <HelperText className="" valid={false}>
                {meta.error}
              </HelperText>
            )}
          </>
        )}
      </FormikField>
    );
  },
  RadioGroup: ({ children }) => <div>{children}</div>,
  RadioButton: ({ name, children }) => {
    const { setFieldValue } = useFormikContext();
    return (
      <FormikField name={name}>
        {({ field }) => (
          <Label radio className="my-1.5 cursor-pointer">
            <Switch
              checked={field.value}
              onChange={() => setFieldValue(name, !field.value)}
              className={cn(
                field.value ? "bg-blue-500" : "bg-gray-200",
                "focus:outline-none focus:ring-2 relative inline-flex items-center h-6 rounded-full min-w-[2.5rem]"
              )}
            >
              <span
                className={cn(
                  field.value ? "translate-x-4.5" : "translate-x-0.5",
                  "inline-block w-5 h-5 transform bg-white rounded-full transition duration-200 ease-in-out"
                )}
              />
            </Switch>
            <span className="ml-2 mr-1">{children}</span>
          </Label>
        )}
      </FormikField>
    );
  },
};

export default Field;
