import { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { Oval } from "react-loader-spinner";
import { saveAs } from "file-saver";
import { FiDownloadCloud, FiMoreHorizontal, FiPlus } from "react-icons/fi";
import useAxios from "hooks/useAxios";
import useUser from "hooks/useUser";
import useApiResource from "hooks/useApiResource";
import useAuthority from "hooks/useAuthority";
import cn from "classnames";
import { useParams } from "react-router-dom";
import { Label, Menu, Table, Input, Dialog } from "components";
import FullLoader from "components/FullLoader";
import UserIdenticon from "components/UserIdenticon";
import InfoTooltip from "components/InfoTooltip";
import AxiosErrorAlert from "components/AxiosErrorAlert";
import AcceptCancelDialog from "partials/AcceptCancelDialog";

function CreateServiceAccountDialog({ isOpen, onSubmit, onClose }) {
  const axios = useAxios();
  let { clusterId } = useParams();
  let [query, setQuery] = useState();
  let [users, setUsers] = useState();
  let [selectedUser, setSelectedUser] = useState();
  let [isSearching, setIsSearching] = useState();
  let [isFocused, setIsFocused] = useState();

  const handleFocus = (event) => {
    event.target.select();
    setIsFocused(true);
  };
  const handleSetSelectedUser = (user) => {
    setSelectedUser(user);
  };

  useEffect(() => {
    if (query) setIsSearching(true);
    const id = setTimeout(() => {
      if (!query) return;
      axios
        .get(`/rest/v1/team/member?query=${query}`)
        .then((resp) => {
          setUsers(resp.data);
        })
        .finally(() => {
          setIsSearching(false);
        });
    }, 300);

    return () => clearTimeout(id);
  }, [query, setQuery, axios]);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Dialog.Panel>
        <Dialog.Title className="mb-2">Create User Account</Dialog.Title>
        <Formik
          initialValues={{ query: "" }}
          onSubmit={async (_, { setSubmitting, setStatus }) => {
            setStatus();
            try {
              await axios.post(
                `/rest/v1/cluster/${clusterId}/user-service-account/${selectedUser.subjectId}`
              );
            } catch (e) {
              return setStatus(e);
            } finally {
              setSubmitting(false);
            }
            onSubmit();
          }}
        >
          {({ isSubmitting, status }) => (
            <Form autoComplete="off">
              <div>
                <Dialog.Description>
                  A user account is needed to interact with the cluster, for
                  example using <span className="font-mono">kubectl</span>.
                </Dialog.Description>
                <hr className="form-row-divider" />
                <div className="mb-4">
                  <Label className="grid grid-cols-7 py-1 items-center">
                    <span className="form-row-label">Name</span>
                    <div className="relative form-row-input">
                      <Input
                        name="query"
                        placeholder="Search..."
                        autoComplete="off"
                        valid={selectedUser || undefined}
                        className={cn(selectedUser && "font-medium")}
                        onFocus={handleFocus}
                        onChange={(e) => {
                          setSelectedUser(false);
                          setQuery(e.target.value);
                        }}
                        value={selectedUser?.name || query || ""}
                      />
                      {isSearching && isFocused && (
                        <div className="z-10 absolute w-56 p-2 mt-2 text-gray-600 bg-white border rounded-lg shadow-md min-w-max-content text-gray-300 bg-gray-700 left-0 flex justify-center">
                          <Oval
                            color="#888"
                            secondaryColor="#aaa"
                            height={26}
                            width={26}
                          />
                        </div>
                      )}
                      {!isSearching &&
                        !!users?.length &&
                        isFocused &&
                        !selectedUser && (
                          <div className="z-10 absolute w-56 p-2 mt-2 text-gray-600 bg-white border border-gray-100 rounded-lg shadow-md min-w-max-content text-gray-700 left-0">
                            {users.map((user) => (
                              <button
                                type="button"
                                onClick={() => handleSetSelectedUser(user)}
                                key={user.email}
                                className="justify-between inline-flex items-center cursor-pointer w-full px-2 py-1 text-sm font-medium transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800 hover:bg-gray-800 hover:text-gray-200 justify-between"
                              >
                                <span>{user.name}</span>
                              </button>
                            ))}
                          </div>
                        )}
                    </div>
                  </Label>
                  <Label className="grid grid-cols-7 py-1 items-center">
                    <span className="flex gap-1 items-center form-row-label">
                      Role
                      <InfoTooltip text="It's currently not possible to configure any other roles than ClusterAdmin." />
                    </span>
                    <Input
                      disabled
                      className={cn(
                        selectedUser && "font-medium",
                        "form-row-input"
                      )}
                      value="ClusterAdmin"
                    />
                  </Label>
                </div>
                <AxiosErrorAlert className="mx-3 mt-3" error={status} />
              </div>
              <div className="flex justify-end">
                <button
                  disabled={!selectedUser || isSubmitting}
                  type="submit"
                  className="w-full sm:w-auto btn btn-blue"
                >
                  Create
                </button>
                <button
                  type="button"
                  className="w-full ml-3 sm:w-auto btn btn-outline"
                  onClick={onClose}
                >
                  Close
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Dialog.Panel>
    </Dialog>
  );
}

function ServiceAccountTableRow({ id, serviceAccount, onRefresh }) {
  const axios = useAxios();
  let { clusterId } = useParams();
  const isSelf = useUser()?.email === serviceAccount?.email;
  const canDelete = useAuthority().hasAuthority("IS_ADMIN");
  const [dropdownOpen, setDropdownOpen] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [
    isDeleteServiceAccountDialogOpen,
    setIsDeleteServiceAccountDialogOpen,
  ] = useState(false);
  const isUser = !!serviceAccount?.email;
  const handleDownload = async () => {
    setIsSubmitting(true);
    try {
      // TODO check for error
      const { data } = await axios.get(
        `/rest/v1/cluster/${clusterId}/user-service-account/${id}`
      );
      var blob = new Blob([data.kubeConfig], {
        type: "text/plain;charset=utf-8",
      });

      saveAs(blob, "config");
    } finally {
      setIsSubmitting(true);
    }
  };
  const handleDelete = async () => {
    setIsSubmitting(true);
    try {
      await axios.delete(
        `/rest/v1/cluster/${clusterId}/user-service-account/${id}`
      );
      setIsDeleteServiceAccountDialogOpen(false);
      onRefresh();
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <Table.Row key={serviceAccount.email} className="">
      {isUser ? (
        <Table.Cell className="font-medium flex items-center">
          <UserIdenticon
            className="-m-2 mr-3"
            pictureUrl={serviceAccount.pictureUrl}
            email={serviceAccount.email}
            size="1.7rem"
          />
          {serviceAccount.email}
          {isSelf && (
            <button
              onClick={handleDownload}
              className="ml-4 -my-2 btn-xs btn-outline"
            >
              Download
              <FiDownloadCloud className="ml-1 inline-block" />
            </button>
          )}
        </Table.Cell>
      ) : (
        <Table.Cell className="font-medium flex items-center">
          {serviceAccount.apiKeyId}****************
        </Table.Cell>
      )}
      <Table.Cell className="font-mono">
        {serviceAccount.role || "ClusterAdmin"}
      </Table.Cell>
      <Table.Cell>
        <AcceptCancelDialog
          isOpen={isDeleteServiceAccountDialogOpen}
          onSubmit={handleDelete}
          isSubmitting={isSubmitting}
          onClose={() => setIsDeleteServiceAccountDialogOpen(false)}
          title={`Are you sure you want to delete the account of ${serviceAccount.email}?`}
        >
          This action is irreversible.
        </AcceptCancelDialog>
        {canDelete && (
          <Menu>
            <Menu.Button as="div" className="float-right">
              <FiMoreHorizontal
                className="cursor-pointer ml-3"
                onClick={() => setDropdownOpen(!dropdownOpen)}
              />
            </Menu.Button>
            <Menu.Items>
              <Menu.ButtonItem
                disabled={isSubmitting}
                onClick={() => setIsDeleteServiceAccountDialogOpen(true)}
                type="danger"
              >
                Delete
              </Menu.ButtonItem>
            </Menu.Items>
          </Menu>
        )}
      </Table.Cell>
    </Table.Row>
  );
}

export default function ServiceAccounts() {
  const axios = useAxios();
  let { clusterId } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [
    // eslint-disable-next-line no-unused-vars
    isCreateUserServiceAccountDialogOpen,
    setIsCreateUserServiceAccountDialogOpen,
  ] = useState(false);
  const {
    data: userServiceAccounts,
    error: errorUserServiceAccounts,
    refresh: refreshUserServiceAccounts,
    isFetching: isFetchingUserServiceAccounts,
  } = useApiResource(`/rest/v1/cluster/${clusterId}/user-service-account`);
  const handleAddPersonalServiceAccount = async () => {
    setIsSubmitting(true);
    try {
      const { data } = await axios.post(
        `/rest/v1/cluster/${clusterId}/user-service-account`
      );
      var blob = new Blob([data.kubeConfig], {
        type: "text/plain;charset=utf-8",
      });

      saveAs(blob, "config");
      refreshUserServiceAccounts();
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isFetchingUserServiceAccounts)
    return (
      <div className="h-72">
        <FullLoader />
      </div>
    );
  return (
    <div className="my-5">
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Cell>User</Table.Cell>
            <Table.Cell>Cluster Roles</Table.Cell>
            <Table.Cell>
              <button
                onClick={() => setIsCreateUserServiceAccountDialogOpen(true)}
                className="text-blue-500 transition-colors duration-150 focus-visible:text-blue-600 focus:outline-none hover:text-blue-600 font-medium float-right flex items-center"
              >
                <FiPlus className="inline-block mr-1" />
                Add User Account
              </button>
            </Table.Cell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {userServiceAccounts?.map((x) => (
            <ServiceAccountTableRow
              id={x.id}
              serviceAccount={x}
              onRefresh={refreshUserServiceAccounts}
              key={x.email}
            />
          ))}
          {!userServiceAccounts?.length && !errorUserServiceAccounts && (
            <Table.ContentRow>
              <div className="py-3 text-center">
                No user accounts.{" "}
                <button
                  onClick={handleAddPersonalServiceAccount}
                  disabled={isSubmitting}
                  className="mr-1 text-blue-600 hover:text-blue-700 inline"
                >
                  <FiPlus className="mx-1 inline" />
                  click here{" "}
                  {isSubmitting && (
                    <span className="inline-block">
                      <Oval
                        color="rgb(62, 130, 248)"
                        secondaryColor="rgb(62, 130, 248)"
                        height="0.6rem"
                        width="0.6rem"
                      />
                    </span>
                  )}
                </button>{" "}
                to add a personal user account and download an associated
                kubeconfig.
              </div>
            </Table.ContentRow>
          )}
        </Table.Body>
      </Table>
      <CreateServiceAccountDialog
        isOpen={isCreateUserServiceAccountDialogOpen}
        onSubmit={() => {
          refreshUserServiceAccounts();
          setIsCreateUserServiceAccountDialogOpen(false);
        }}
        onClose={() => setIsCreateUserServiceAccountDialogOpen(false)}
      />
    </div>
  );
}
