import { Oval } from "react-loader-spinner";
import { Link, useMatch } from "react-router-dom";
import cn from "classnames";
import useAuthority from "hooks/useAuthority";
import { FeatureGate, IfTestEnvironment } from "components";

function SidebarHeader({ children }) {
  return (
    <div
      style={{ fontSize: "0.950rem", lineHeight: "1.5rem" }}
      className="my-1.5 font-semibold lowercase"
    >
      {children}
    </div>
  );
}

function SidebarLink({ children, to }) {
  const match = !!useMatch(to, { exact: true });
  return (
    <div className="border-l flex">
      <Link
        to={to}
        className={cn(
          match
            ? "border-blue-500 text-blue-500"
            : "border-transparent text-gray-600",
          "flex justify-self-start items-center font-light -ml-[2px] border-l-[3px] leading-7 focus:outline-none focus:border-blue-500 focus:text-blue-500 hover:border-blue-500 hover:text-blue-500"
        )}
      >
        <span className="ml-3 lowercase">{children}</span>
      </Link>
    </div>
  );
}

function ProjectMenuItem({ project }) {
  const href = `/projects/${project.name}`;
  const match = useMatch(`${href}/*`);
  return (
    <div className="flex border-l">
      <Link
        to={href}
        className={cn(
          match
            ? "border-blue-500 text-blue-500"
            : "border-transparent text-gray-600",
          "flex items-center justify-self-start font-light -ml-[2px] border-l-[3px] leading-7 focus:outline-none focus:border-blue-500 focus:text-blue-500 hover:border-blue-500 hover:text-blue-500"
        )}
      >
        <span className="ml-3 flex items-center gap-1">
          <span className="text-ellipsis overflow-hidden lowercase whitespace-nowrap max-w-[11.5rem]">
            {project.name}
          </span>
        </span>
      </Link>
    </div>
  );
}

function ClusterMenuItem({ cluster }) {
  const href = `/clusters/${cluster.name}`;
  const match = useMatch(`${href}/*`);
  return (
    <div className="flex border-l">
      <Link
        to={href}
        className={cn(
          match
            ? "border-blue-500 text-blue-500"
            : "border-transparent text-gray-600",
          "flex items-center justify-self-start font-light -ml-[2px] border-l-[3px] leading-7 focus:outline-none focus:border-blue-500 focus:text-blue-500 hover:border-blue-500 hover:text-blue-500"
        )}
      >
        <span className="ml-3 flex items-center gap-1">
          <span className="text-ellipsis overflow-hidden whitespace-nowrap max-w-[11.5rem]">
            {cluster.name}
          </span>{" "}
          {!["ACTIVE", "FAILED"].includes(cluster.state) && (
            <Oval
              color="rgb(62, 130, 248)"
              secondaryColor="rgb(62, 130, 248)"
              height={15}
              width={15}
            />
          )}
        </span>
      </Link>
    </div>
  );
}

export default function Sidebar({ clusters, projects, children }) {
  const { hasAuthority } = useAuthority();
  return (
    <div className="flex">
      <nav className="w-56 ml-8 flex flex-wrap text-sm flex-col min-h-screen text-gray-700 py-4 fixed z-0">
        <SidebarHeader>Clusters</SidebarHeader>
        <SidebarLink to="/clusters">Overview</SidebarLink>
        {clusters &&
          clusters.map((x) => <ClusterMenuItem key={x.id} cluster={x} />)}
        {hasAuthority("IS_ADMIN") && (
          <SidebarLink to="/clusters/new">Create new</SidebarLink>
        )}
        <FeatureGate name="OBJECT_STORAGE">
          <SidebarHeader>Services</SidebarHeader>
          <SidebarLink to="/object-storage">Object Storage</SidebarLink>
        </FeatureGate>
        <FeatureGate name="SYMBIOSIS_PROJECTS">
          <div className="my-2" />
          <SidebarHeader>
            Projects <span className="font-normal text-xs">preview</span>
          </SidebarHeader>
          {projects &&
            projects.map((x) => <ProjectMenuItem key={x.id} project={x} />)}
          <SidebarLink to="/projects/new">Create new</SidebarLink>
        </FeatureGate>
        <FeatureGate name="IMPORT_CLUSTERS">
          {hasAuthority("IS_ADMIN") && (
            <SidebarLink to="/clusters/import">Import</SidebarLink>
          )}
        </FeatureGate>
        <div className="my-2" />
        <SidebarHeader>Auth & Membership</SidebarHeader>
        <SidebarLink to="/api-keys">API Keys</SidebarLink>
        <SidebarLink to="/users">Users & roles</SidebarLink>
        {hasAuthority("IS_ADMIN") && (
          <>
            <div className="my-2" />
            <SidebarHeader>Settings</SidebarHeader>
            <SidebarLink to="/settings">General</SidebarLink>
            <SidebarLink to="/settings/billing">Billing & invoices</SidebarLink>
          </>
        )}
        <IfTestEnvironment>
          <SidebarLink to="/test-settings">Test settings</SidebarLink>
        </IfTestEnvironment>
      </nav>
      <div
        style={{ minHeight: "calc(100vh - 3.5rem - 1px)" }}
        className="flex flex-col ml-64 w-full"
      >
        {children}
      </div>
    </div>
  );
}
