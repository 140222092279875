import styles from "./HorizontalProgressBar.module.scss";
import cn from "classnames";

export default function HorizontalProgressBar({
  title,
  value,
  strokeColor,
  width = 100,
  height = 24,
}) {
  return (
    <div
      className="relative"
      style={{ height: `${height}px`, width: `${width}px` }}
    >
      <svg width={width} height={height}>
        {value ? (
          <line
            x1="0"
            y1={height / 2}
            x2={value * width}
            y2={height / 2}
            stroke={strokeColor}
            strokeWidth={height}
            strokeDasharray={`${width / 10 - 1} 1`}
            strokeDashoffset="0"
          />
        ) : (
          <line
            x1="0"
            y1={height / 2}
            x2={width}
            y2={height / 2}
            stroke="#f4f4f4"
            strokeWidth={height}
            strokeDasharray={`${width / 10 - 1} 1`}
            strokeDashoffset="0"
          />
        )}
        {value && (
          <line
            x1="0"
            y1={height / 2}
            x2={width}
            y2={height / 2}
            stroke={strokeColor}
            strokeOpacity="0.3"
            strokeWidth={height}
            strokeDasharray={`${width / 10 - 1} 1`}
            strokeDashoffset="0"
          />
        )}
      </svg>
      <span
        className={cn(styles.title, "absolute -top-0.5 text-gray-500 z-10")}
      >
        <b>{title}</b>{" "}
        {value ? <> {(Math.min(1, value) * 100).toFixed(0)}%</> : "?"}
      </span>
      <div className={styles.trapezoid} />
    </div>
  );
}
