import { UiText } from "@ory/client"
import { Alert } from "@ory/themes"

interface MessageProps {
  message: UiText
}

export const Message = ({ message }: MessageProps) => {
  const className = message.type === "error" ? "text-red-500" : "text-gray-700";
  return (
    <div className={className} data-testid={`ui/message/${message.id}`}>
      {message.text}
    </div>
  )
}

interface MessagesProps {
  messages?: Array<UiText>
}

export const Messages = ({ messages }: MessagesProps) => {
  if (!messages) {
    // No messages? Do nothing.
    return null
  }

  return (
    <div>
      {messages.map((message) => (
        <Message key={message.id} message={message} />
      ))}
    </div>
  )
}
