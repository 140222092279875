import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import {
  Link,
  Routes,
  Route,
  useMatch,
  useParams,
  Navigate,
} from "react-router-dom";
import Overview from "./Overview";
import Nodes from "./Nodes";
import useAuthority from "hooks/useAuthority";
import ServiceAccounts from "./ServiceAccounts";
import Volumes from "./Volumes";
import LoadBalancers from "./LoadBalancers";
import Settings from "./Settings";
import useInterval from "hooks/useInterval";
import cn from "classnames";

const routes = [
  {
    title: "Overview",
    path: "",
    component: Overview,
  },
  {
    title: "Nodes",
    path: "nodes",
    component: Nodes,
  },
  {
    title: "Volumes",
    path: "volumes",
    component: Volumes,
  },
  {
    title: "Load Balancers",
    path: "load-balancers",
    component: LoadBalancers,
  },
  {
    title: "User Accounts",
    path: "user-accounts",
    component: ServiceAccounts,
  },
  {
    title: "Settings",
    path: "settings",
    component: Settings,
    requiresAnyAuthoritiesIn: ["IS_ADMIN"],
  },
];

function TabItem({ title, url, path }) {
  const match = !!useMatch(url);
  return (
    <li className="pb-3 mr-6 last:mr-0">
      <Link
        className={cn(
          match
            ? "text-blue-500"
            : "text-gray-500 focus:text-blue-500 focus:outline-none hover:text-blue-500",
          "py-3 whitespace-nowrap font-light"
        )}
        to={path}
      >
        {title}
      </Link>
    </li>
  );
}

const REFRESH_CLUSTER_INTERVAL_MS = 10000;

export default function ClusterView({ cluster, onRefresh }) {
  useInterval(onRefresh, REFRESH_CLUSTER_INTERVAL_MS);
  const { hasAuthority } = useAuthority();
  // TODO change to clusterName
  const { clusterId } = useParams();
  const userAuthorizedForRoute = (route) =>
    !route.requiresAnyAuthoritiesIn ||
    route.requiresAnyAuthoritiesIn.some(hasAuthority);

  // Set cluster context for Sentry
  useEffect(() => {
    Sentry.setContext("cluster", {
      id: cluster.id,
      name: cluster.name,
    });
    return () => Sentry.setContext("cluster", null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cluster.id]);

  return (
    <div className="p-5">
      <h1 className="header my-2 text-ellipsis whitespace-nowrap overflow-hidden max-w-[calc(100vw-20rem)]">
        {cluster.name}
      </h1>
      <nav className="border-b border-gray-200">
        <ul className="text-sm font-medium flex flex-nowrap">
          {routes.filter(userAuthorizedForRoute).map((x) => (
            <TabItem
              key={x.path}
              path={x.path}
              url={["clusters", clusterId, x.path].filter(Boolean).join("/")}
              title={x.title}
            />
          ))}
        </ul>
      </nav>
      <Routes>
        {routes.filter(userAuthorizedForRoute).map((x) => (
          <Route
            key={x.path}
            exact
            path={x.path}
            element={
              <x.component cluster={cluster} onRefreshClusters={onRefresh} />
            }
          />
        ))}
        <Route path="*" render={<Navigate to={`/clusters/${clusterId}`} />} />
      </Routes>
    </div>
  );
}
