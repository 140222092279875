import { useState } from "react";
import * as Yup from "yup";
import CopyToClipboardField from "components/CopyToClipboardField";
import { FiMoreHorizontal, FiPlus } from "react-icons/fi";
import {
  usePrettyTimeElapsedSince,
  useApiResource,
  useAxios,
  useAuthority,
  useTeam,
} from "hooks";
import { Formik } from "formik";
import {
  AxiosErrorAlert,
  FullLoader,
  Label,
  Input,
  Menu,
  Table,
  Dialog,
  Field,
  Alert,
} from "components";

const createBucketSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Bucket name must be longer than 3 characters")
    .max(64, "Bucket name can't be more than 64 characters")
    .required("You need to provide a bucket name"),
  accessKeyId: Yup.string()
    .required("You need to provide a bucket owner (create a Bucket Access first)"),
  acl: Yup.string()
    .required("You need to provide a default ACL"),
});

const createBucketAccessSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Name must be longer than 3 characters")
    .max(64, "Name can't be more than 64 characters")
    .required("You need to provide a name for your access key"),
});


const cannedACLs = [
  {name: "private", description: "Only the bucket owner has full access rights."},
  {name: "public-read", description: "The bucket owner has full access rights. Everyone else (including anonymous users) have read access."},
  {name: "public-read-write", description: "The bucket owner has full access rights. Everyone else (including anonymous users) have read and write access."},
  {name: "authenticated-read", description: "The bucket owner has full access rights. All other authenticated users have read access."},
];

function CreateBucketDialog({ isOpen, onSubmit, onClose }) {
  const axios = useAxios();
  const { team } = useTeam();
  const {
    data: regions,
    error: errorRegions,
    isFetching: isFetchingRegions,
  } = useApiResource("/rest/v1/region");

  const {
    data: bucketAccesses,
    error: errorAccesses,
    isFetching: isFetchingAccesses,
    refresh: refreshAccesses,
  } = useApiResource("/rest/v1/bucket-access");

  if (isFetchingAccesses || isFetchingRegions || errorRegions) return (
    <Dialog open={isOpen} onClose={onClose}>
      <Dialog.Panel>
        <FullLoader />
      </Dialog.Panel>
    </Dialog>
  );
  return (
    <Formik
      validateOnBlur
      validationSchema={createBucketSchema}
      initialValues={{ name: "", accessKeyId: null, regionName: regions[0].name, acl: "private" }}
      onSubmit={async ({accessKeyId, name, regionName, acl}, { setSubmitting, setStatus }) => {
        try {
          await axios.post("/rest/v1/bucket", {
            name,
            regionName,
            acl,
            accessKeyId,
          });
          onSubmit();
          setSubmitting(false);
          onClose();
        } catch (err) {
          setStatus(err);
          setSubmitting(false);
        }
      }}
    >
      {({
        values,
        status,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <Dialog open={isOpen} onClose={onClose}>
          <Dialog.Panel>
            <Dialog.Title>Create Bucket</Dialog.Title>
            <div>
              <Dialog.Description>
                Enter the name of your bucket and the owner of the bucket.
                More finely grained access control can be configured directly using the S3 API for ACLs.
              </Dialog.Description>
              {values.acl.startsWith("public-") && values.name && <Alert>Public buckets that are accessed without an Access Key use a namespaced bucket name.<br />Access this bucket with the name <code>{team.id.replaceAll("-", "")}:{values.name}</code>.</Alert>}
              <Input
                name="name"
                className="my-5"
                placeholder="Bucket name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
              />
              <Field.Listbox
                options={[{value: null, element: <span className="text-gray-500">Select owner</span>}, ...bucketAccesses?.map((v) => ({ value: v.accessKeyId, element: v.name }))]}
                name="accessKeyId"
              />
              <hr className="form-row-divider" />
              <div className="">
                {cannedACLs.map(x => (
                <Label radio key={x.name} className="my-1.5 cursor-pointer w-full">
                  <Input
                    name="acl"
                    value={x.name}
                    type="radio"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={values.acl === x.name}
                  />
                  <div className="grid ml-5 grid-cols-6 items-center w-full">
                    <span className="col-span-2 font-medium text-gray-700">
                      {x.name}
                    </span>
                    <span className="col-span-4 text-gray-600">
                      {x.description}
                    </span>
                  </div>
                </Label>
                ))}
              </div>
            </div>
            <AxiosErrorAlert error={status} />
            <div className="flex justify-end">
              <button
                className="w-full sm:w-auto btn btn-outline"
                type="button"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                className="w-full ml-3 sm:w-auto btn btn-blue"
                type="submit"
                disabled={isSubmitting}
                onClick={handleSubmit}
              >
                Create
              </button>
            </div>
          </Dialog.Panel>
        </Dialog>
      )}
    </Formik>
  );
}

function CreateAccessDialog({ isOpen, onSubmit, onClose }) {
  const axios = useAxios();
  const [bucketAccess, setBucketAccess] = useState();
  const {
    data: regions,
    error: errorRegions,
    isFetching: isFetchingRegions,
  } = useApiResource("/rest/v1/region");
  if (isFetchingRegions || errorRegions) return (
    <Dialog open={isOpen} onClose={onClose}>
      <Dialog.Panel>
        <FullLoader />
      </Dialog.Panel>
    </Dialog>
  );

  return (
    <Dialog open={isOpen} onClose={onClose}>
    {bucketAccess ? (
          <Dialog.Panel>
            <Dialog.Description>
              Your Bucket Access has been created. Copy and store the Secret Key it as you will not
              be able to retrieve it again.
            </Dialog.Description>
            <div>
              <div className="grid grid-cols-10 mx-2 my-1 items-center">
                <div className="col-span-3 font-medium">Access Key ID</div>
                <div className="col-span-7">
                  <CopyToClipboardField text={bucketAccess.accessKeyId}>
                    <span className="font-mono break-all text-gray-700">
                      {bucketAccess.accessKeyId}
                    </span>
                  </CopyToClipboardField>
                </div>
              </div>
              <div className="grid grid-cols-10 mx-2 my-1 items-center">
                <div className="col-span-3 font-medium">Secret Key</div>
                <div className="col-span-7">
                  <CopyToClipboardField text={bucketAccess.secretAccessKey}>
                    <span className="font-mono break-all text-gray-700">
                      {bucketAccess.secretAccessKey}
                    </span>
                  </CopyToClipboardField>
                </div>
              </div>
            </div>
            <div className="flex justify-end">
              <button
                type="button"
                className="w-full sm:w-auto btn btn-outline"
                onClick={onClose}
              >
                Close
              </button>
            </div>
          </Dialog.Panel>
    ) : (
      <Formik
        validateOnBlur
        validationSchema={createBucketAccessSchema}
        initialValues={{ role: "MEMBER", regionName: regions[0].name, emails: "" }}
        onSubmit={async ({ name, regionName }, { setSubmitting, setStatus }) => {
          try {
            const { data } = await axios.post("/rest/v1/bucket-access", {
              name,
              regionName,
            });
            setBucketAccess(data);
            onSubmit();
            setSubmitting(false);
          } catch (err) {
            setStatus(err);
            setSubmitting(false);
          }
        }}
      >
        {({
          values,
            status,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
        }) => (
          <Dialog.Panel>
            <Dialog.Title>Create Bucket Access Key</Dialog.Title>
            <div>
              <Dialog.Description>
                Creates an access key and a secret key that are used to authenticate for bucket operations.
              </Dialog.Description>
              <Input
                name="name"
                className="my-5"
                placeholder="Name of the key"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
              />
            </div>
            <AxiosErrorAlert error={status} />
            <div className="flex justify-end">
              <button
                className="w-full sm:w-auto btn btn-outline"
                type="button"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                className="w-full ml-3 sm:w-auto btn btn-blue"
                type="submit"
                disabled={isSubmitting}
                onClick={handleSubmit}
              >
                Create
              </button>
            </div>
          </Dialog.Panel>
        )}
      </Formik>
    )}
    </Dialog>
  );
}

function BucketDropdown({ name, refreshBuckets }) {
  const axios = useAxios();
  const [dropdownOpen, setDropdownOpen] = useState();
  const { hasAuthority } = useAuthority();
  const actions = [];

  const deleteBucket = async () => {
    await axios.delete(`/rest/v1/bucket/${name}`);
    refreshBuckets();
  };

  if (hasAuthority("IS_ADMIN"))
    actions.push(
      <Menu.ButtonItem
        key="change-to-admin"
        onClick={deleteBucket}
        type="danger"
        className=""
      >
        Delete
      </Menu.ButtonItem>
    );
  if (!actions.length) return null;
  return (
    <Menu>
      <Menu.Button as="div" className="cursor-pointer float-right">
        <FiMoreHorizontal
          className="cursor-pointer float-right"
          onClick={() => setDropdownOpen(!dropdownOpen)}
        />
      </Menu.Button>
      <Menu.Items>{actions}</Menu.Items>
    </Menu>
  );
}

function BucketRow({
  name,
  createdAt,
  refreshBuckets,
}) {
  const createdSince = usePrettyTimeElapsedSince(createdAt);
  return (
    <Table.Row className="">
      <Table.Cell className="font-medium flex items-center">{name}</Table.Cell>
      <Table.Cell>{createdSince}</Table.Cell>
      <Table.Cell>
        <BucketDropdown
          name={name}
          refreshBuckets={refreshBuckets}
        />
      </Table.Cell>
    </Table.Row>
  );
}

function AccessRow({ name, accessKeyId, createdAt, onDelete }) {
  const createdSince = usePrettyTimeElapsedSince(createdAt);
  return (
    <Table.Row key={name}>
      <Table.Cell className="font-medium">{name}</Table.Cell>
      <Table.Cell className="lowercase"><code>{accessKeyId}</code></Table.Cell>
      <Table.Cell>{createdSince}</Table.Cell>
      <Table.Cell>
        <button onClick={onDelete} className="float-right btn-xs btn-outline">
          Remove
        </button>
      </Table.Cell>
    </Table.Row>
  );
}

export default function ObjectStorage() {
  const axios = useAxios();
  const { hasAuthority } = useAuthority();
  const [modalOpen, setModalOpen] = useState(null);
  const {
    data: buckets,
    error: errorBuckets,
    isFetching: isFetchingBuckets,
    refresh: refreshBuckets,
  } = useApiResource("/rest/v1/bucket");

  const {
    data: bucketAccesses,
    error: errorAccesses,
    isFetching: isFetchingAccesses,
    refresh: refreshAccesses,
  } = useApiResource("/rest/v1/bucket-access");

  const handleDeleteAccesses = async (name) => {
    await axios.delete(`/rest/v1/bucket-access/${name}`);
    refreshAccesses();
  };

  if (isFetchingBuckets || isFetchingAccesses) return <FullLoader />;
  return (
    <section className="p-5">
      <div className="mb-3 flex justify-between items-center">
        <div className="my-3 header">Buckets</div>
        {hasAuthority("IS_ADMIN") && (
          <button
            onClick={() => setModalOpen("create-bucket")}
            className="btn-xs btn-blue flex items-center"
          >
            <FiPlus className="inline-block mr-1" />
            Create Bucket
          </button>
        )}
      </div>
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Cell>Name</Table.Cell>
            <Table.Cell>Created</Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {buckets?.map((x) => (
            <BucketRow
              key={x.name}
              name={x.name}
              createdAt={x.createdAt}
              refreshBuckets={refreshBuckets}
            />
          ))}
          {!buckets?.length && !errorBuckets && (
            <Table.ContentRow>
              <div className="py-3 flex justify-center">
                No buckets found
                {hasAuthority("IS_ADMIN") && (
                  <>
                    ,{" "}
                    <button
                      onClick={() => setModalOpen("create-bucket")}
                      className="text-blue-600 hover:text-blue-700 inline-flex items-center"
                      to="/clusters/add"
                    >
                      <FiPlus className="mx-1" /> add new bucket
                    </button>
                  </>
                )}
                .
              </div>
            </Table.ContentRow>
          )}
          {errorBuckets && (
            <Table.ContentRow>
              <div className="text-red-500 text-gray-400 text-center py-3">
                {errorBuckets.message}
              </div>
            </Table.ContentRow>
          )}
        </Table.Body>
      </Table>
      <div className="mb-3 mt-12 flex justify-between items-center">
        <div className="header">Bucket Access Keys</div>
        {hasAuthority("IS_ADMIN") && (
          <button
            onClick={() => setModalOpen("create-access")}
            className="btn-xs btn-blue flex items-center"
          >
            <FiPlus className="inline-block mr-1" />
            Create Access Key
          </button>
        )}
      </div>
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Cell>Name</Table.Cell>
            <Table.Cell>Access Key ID</Table.Cell>
            <Table.Cell>Created</Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {bucketAccesses?.map((x) => (
            <AccessRow
              name={x.name}
              accessKeyId={x.accessKeyId}
              createdAt={x.createdAt}
              onDelete={() => handleDeleteAccesses(x.name)}
            />
          ))}
          {!bucketAccesses?.length && !errorAccesses && (
            <Table.ContentRow>
              <div className="py-3 flex justify-center">
                No access keys found
                {hasAuthority("IS_ADMIN") && (
                  <>
                    ,{" "}
                    <button
                      onClick={() => setModalOpen("create-access")}
                      className="text-blue-600 hover:text-blue-700 inline-flex items-center"
                      to="/clusters/add"
                    >
                      <FiPlus className="mx-1" /> create access key
                    </button>
                  </>
                )}
                .
              </div>
            </Table.ContentRow>
          )}
          {errorAccesses && (
            <Table.ContentRow>
              <div className="text-red-500 text-gray-400 text-center py-3">
                {errorAccesses.message}
              </div>
            </Table.ContentRow>
          )}
        </Table.Body>
      </Table>
      {modalOpen === "create-bucket" && <CreateBucketDialog
        isOpen={modalOpen === "create-bucket"}
        onSubmit={refreshBuckets}
        onClose={() => setModalOpen()}
      />}
      {modalOpen === "create-access" && <CreateAccessDialog
        isOpen={modalOpen === "create-access"}
        onSubmit={refreshAccesses}
        onClose={() => setModalOpen()}
      />}
    </section>
  );
}
