import cn from "classnames";

export default function Input({
  className,
  value,
  placeholder,
  as,
  valid,
  type,
  ...props
}) {
  const cls = cn(
    type === "radio" &&
      "text-blue-600 form-radio focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-offset-0",
    type === "checkbox" &&
      "text-blue-600 form-checkbox focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-offset-0 rounded",
    type !== "radio" &&
      type !== "checkbox" &&
      "block w-full text-sm focus:outline-none leading-5 rounded-md",
    value && placeholder ? "py-2.5" : "py-2.5",
    valid === false && "border-red-600",
    valid === true && "border-green-600",
    valid == null && "border-gray-300",
    "focus:border-blue-400 focus:ring focus:ring-blue-300 focus:outline-none text-sm text-gray-700 bg-white px-2 rounded focus:outline-none border"
  );

  return (
    <div className={cn(className, "relative")}>
      {value && placeholder && (
        <div className="absolute top-0.5 left-2 leading-none text-[0.5rem] text-gray-500 select-none mt-px">
          {placeholder}
        </div>
      )}
      <input
        className={cls}
        placeholder={placeholder}
        value={value}
        type={type}
        {...props}
      />
    </div>
  );
}
