import cn from "classnames";

const typeStyle = {
  success: "bg-emerald-100 text-emerald-900",
  danger: "bg-red-100 text-red-900",
  warning: "bg-orange-100 text-orange-900",
  neutral: "bg-gray-100 text-gray-900",
  info: "bg-blue-100 text-blue-900",
};

const svgStyle = {
  success: "text-emerald-400",
  danger: "text-red-400",
  warning: "text-orange-400",
  neutral: "text-gray-400",
  info: "text-blue-400",
};

export default function Alert({ type = "info", className, children }) {
  return (
    <span
      className={cn(
        className,
        typeStyle[type],
        "inline-block p-4 pl-12 relative rounded-lg leading-5 my-3"
      )}
      role="alert"
    >
      <svg
        className={cn(
          svgStyle[type],
          "h-5 w-5 absolute left-0 top-0 ml-4 mt-4"
        )}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
      {children}
    </span>
  );
}
