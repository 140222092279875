import { useState } from "react";
import useInterval from "hooks/useInterval";
import usePaginatedApiResource from "hooks/usePaginatedApiResource";
import { usePrettyTimeElapsedSince } from "hooks";
import FullLoader from "components/FullLoader";
import { FiPlus } from "react-icons/fi";
import { Table, ClusterStateBadge } from "components";
import Pagination from "components/Pagination";
import useAuthority from "hooks/useAuthority";
import { Link, useNavigate, useLocation } from "react-router-dom";

const CLUSTERS_PER_PAGE = 20;
const REFRESH_CLUSTERS_INTERVAL_MS = 15000;

function ClusterRow({ cluster }) {
  const createdSince = usePrettyTimeElapsedSince(cluster.createdAt);
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Table.Row
      key={cluster.id}
      onClick={() => navigate(`${location.pathname}/${cluster.name}`)}
      className="hover:bg-gray-50 cursor-pointer"
    >
      <Table.Cell className="bg-gray-50">
        <div className="font-bold">{cluster.name}</div>
      </Table.Cell>
      <Table.Cell className="bg-gray-50">
        {cluster.nodes.length} nodes
      </Table.Cell>
      <Table.Cell className="bg-gray-50">
        <ClusterStateBadge state={cluster.state} />
      </Table.Cell>
      <Table.Cell className="bg-gray-50 w-[20%]">
        Created {createdSince}
      </Table.Cell>
    </Table.Row>
  );
}

export default function ClusterList() {
  const { hasAuthority } = useAuthority();
  const [currentPage, setCurrentPage] = useState(0);
  const {
    content: clusters,
    totalElements,
    isFetching,
    error,
    refresh: refreshClusters,
  } = usePaginatedApiResource("/rest/v1/cluster", currentPage, {
    pageSize: CLUSTERS_PER_PAGE,
    sort: "name",
  });
  useInterval(refreshClusters, REFRESH_CLUSTERS_INTERVAL_MS);
  if (isFetching) return <FullLoader />;
  return (
    <div className="p-5">
      <div className="mt-2 mb-6 flex justify-between items-center">
        <div className="header">Kubernetes clusters</div>
        {hasAuthority("IS_ADMIN") && (
          <Link
            to="/clusters/new"
            className="btn-xs btn-blue flex items-center"
          >
            <FiPlus className="inline-block mr-1" />
            Add new
          </Link>
        )}
      </div>
      <Table>
        <Table.Body>
          {clusters?.map((x) => (
            <ClusterRow key={x.id} cluster={x} />
          ))}
          {!clusters?.length && !error && (
            <Table.ContentRow>
              <div className="py-3 flex justify-center">
                No clusters found
                {hasAuthority("IS_ADMIN") && (
                  <>
                    ,{" "}
                    <Link
                      className="text-blue-600 hover:text-blue-700 inline-flex items-center"
                      to="/clusters/new"
                    >
                      <FiPlus className="mx-1" /> add a new cluster
                    </Link>
                  </>
                )}
                .
              </div>
            </Table.ContentRow>
          )}
          {error && (
            <Table.ContentRow>
              <div className="py-3 flex justify-center text-red-500">
                {error.message}
              </div>
            </Table.ContentRow>
          )}
        </Table.Body>
      </Table>
      <Pagination
        resultsPerPage={CLUSTERS_PER_PAGE}
        totalResults={totalElements}
        currentPage={currentPage}
        onChange={setCurrentPage}
      />
    </div>
  );
}
