import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import PsuedoSiteHeader from "partials/PsuedoSiteHeader";
import ory from "components/kratos/sdk/index.ts"
import { handleFlowError } from "components/kratos/errors"
import { Flow } from "components/kratos"

export default function Signin({ onSignin, onLogout }) {
  const [flow, setFlow] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const { flow: flowId, aal, refresh, return_to: returnTo } = queryString.parse(location.search)

  useEffect(() => {
    // If the router is not ready yet, or we already have a flow, do nothing.
    if (flow) {
      return
    }

    if (flowId) {
      ory
        .getLoginFlow({ id: String(flowId), aal, refresh: true })
        .then(({ data }) => {
          // We received the flow - let's use its data and render the form!
          setFlow(data)
        })
        .catch(handleFlowError(navigate, "signin", setFlow))
      return
    }

    ory
      .createBrowserLoginFlow({
        returnTo: returnTo ? String(returnTo) : undefined,
        aal: aal ? String(aal) : undefined,
        refresh: Boolean(refresh),
      })
      .then(({ data }) => {
        setFlow(data)
      })
      .catch(handleFlowError(navigate, "signin", setFlow))
  }, [flowId, aal, refresh, navigate, returnTo, flow])

  const onSubmit = (values) => {
    window.history.replaceState(null, undefined, `${location.pathname}?flow=${flow?.id}`)
    return ory.updateLoginFlow({
      flow: String(flow?.id),
      updateLoginFlowBody: values,
    })
    .then(onSignin)
    .catch(handleFlowError(navigate, "signin", setFlow))
    .catch((err) => {
      // If the previous handler did not catch the error it's most likely a form validation error
      if (err.response?.status === 400) {
        // Yup, it is!
        setFlow(err.response?.data)
        return
      }

      return Promise.reject(err)
    })
  }

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Page content */}
      <main className="flex-grow">
        <section className="bg-gradient-to-b from-gray-100 to-white">
          <PsuedoSiteHeader />
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-5 md:pt-4">
              {/* Page header */}
              <div className="max-w-3xl mx-auto text-center pb-4 md:pb-8">
                <h1 className="h1">Welcome.</h1>
              </div>

              {/* Form */}
                <div className="max-w-sm mx-auto">
                  <Flow onSubmit={onSubmit} flow={flow} />
                  {aal || refresh ? (
                    <button className="btn btn-outline w-full" onClick={onLogout}>
                      Log out
                    </button>
                  ) : (
                    <>
                      <div className="text-gray-600 text-center my-4 text-sm">
                        Don’t have an account?{" "}
                        <Link
                          to={`/signup`}
                          className="text-blue-600 hover:underline transition duration-150 ease-in-out"
                        >
                          Sign up
                        </Link>
                      </div>
                      <div className="text-gray-600 text-center my-4 text-sm">
                        Trouble logging in?{" "}
                        <Link
                          to={`/reset-password`}
                          className="text-blue-600 hover:underline transition duration-150 ease-in-out"
                        >
                          Recover account
                        </Link>
                      </div>
                    </>
                  )}
                </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
