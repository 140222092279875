function timeSince(date) {
  let seconds = Math.floor((new Date() - date) / 1000);
  let interval = seconds / 31536000;

  if (interval > 1) {
    const value = Math.floor(interval);
    return value === 1 ? "1 year ago" : value + " years ago";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    const value = Math.floor(interval);
    return value === 1 ? "1 month ago" : value + " months ago";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    const value = Math.floor(interval);
    return value === 1 ? "1 day ago" : value + " days ago";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    const value = Math.floor(interval);
    return value === 1 ? "1 hour ago" : value + " hours ago";
  }
  interval = seconds / 60;
  if (interval > 1) {
    const value = Math.floor(interval);
    return value === 1 ? "1 minute ago" : value + " minutes ago";
  }
  const value = Math.floor(interval);
  if (interval < 1) {
    return "just now";
  }
  return value === 1 ? "1 second ago" : value + " seconds ago";
}

export default function usePrettyTimeElapsedSince(dateInput) {
  if (dateInput == null) return "N/A";
  const date = new Date(dateInput);
  return timeSince(date);
}
