import cn from "classnames";

export default function Select({
  valid,
  multiple,
  disabled,
  className,
  children,
  ...other
}) {
  const cls = cn(
    valid == null &&
      !disabled &&
      "focus:border-blue-400 border-gray-300 focus:ring focus:ring-blue-300",
    valid == null && disabled && "cursor-not-allowed opacity-50 bg-gray-300",
    !multiple && "leading-5",
    className,
    valid === true &&
      "border-green-600 focus:border-green-400 focus:ring focus:ring-green-200",
    valid === false &&
      "border-red-600 focus:border-red-400 focus:ring focus:ring-red-200",
    "block w-full text-sm focus:outline-none rounded-md"
  );
  return (
    <select
      className={cls}
      disabled={disabled}
      multiple={!!multiple}
      {...other}
    >
      {children}
    </select>
  );
}
