import { useState } from "react";
import { RiInformationLine } from "react-icons/ri";
import cn from "classnames";

export default function InfoTooltip({ text, className }) {
  const [hoverActive, setHoverActive] = useState();
  return (
    <span className={cn(className, "relative font-normal")}>
      <RiInformationLine
        className="inline-block"
        onMouseOver={() => setHoverActive(true)}
        onMouseLeave={() => setHoverActive(false)}
      />
      {hoverActive && (
        <div className="rounded p-2 bg-white w-48 absolute shadow-lg text-xs z-10">
          {text}
        </div>
      )}
    </span>
  );
}
