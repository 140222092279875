import { useEffect } from "react";
import FullLoader from "components/FullLoader";
import { FiPlus, FiArrowRight } from "react-icons/fi";
import { Link, Navigate, useLocation } from "react-router-dom";
import { LoadingButton, Badge } from "components";
import useApiResource from "hooks/useApiResource";
import useAbortController from "hooks/useAbortController";
import useAxios from "hooks/useAxios";
import { HiCog } from "react-icons/hi";

function InWaitList() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Page content */}
      <main className="flex-grow bg-gradient-to-b from-gray-100 to-white">
        <Link
          className="py-5 px-10 float-right flex items-center gap-1"
          to="/profile"
        >
          <HiCog className="inline-block" /> My Account
        </Link>
        <section className="max-w-lg mx-auto px-4 sm:px-6">
          <h1 className="h1 text-center pt-20 pb-8">In wait list.</h1>
          <p className="">
            Your account has not been approved yet.
            <br />
            We will reach out to you by email once you are in.
          </p>
        </section>
      </main>
    </div>
  );
}

export default function SelectTeam({ onNoTeams, onChangeTeam }) {
  const location = useLocation();
  const redirect = location.state?.redirect;
  const axios = useAxios();
  const { signal } = useAbortController();

  const {
    data: teams,
    isFetching: isFetchingTeams,
    error: errorTeams,
  } = useApiResource("/rest/v1/user/team");

  const {
    data: invitations,
    isFetching: isFetchingInvitations,
    error: errorInvitations,
    refresh: refreshInvitations,
  } = useApiResource("/rest/v1/user/invite");

  const {
    data: bouncerStatus,
    isFetching: isFetchingBouncerStatus,
    error: errorBouncerStatus,
  } = useApiResource("/rest/v1/bouncer");

  const teamsAndInvitationsCount = teams?.length + invitations?.length;

  useEffect(() => {
    if (bouncerStatus?.isAllowed === true && teamsAndInvitationsCount === 0) {
      onNoTeams(signal);
    }

    // If there are no invitations and user is member of exactly one team lets select the user's only team.
    if (invitations?.length === 0 && teams?.length === 1) {
      onChangeTeam(teams[0].id, redirect);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teams, invitations, redirect, signal]);

  if (isFetchingTeams || isFetchingInvitations || isFetchingBouncerStatus) {
    return (
      <div className="h-screen">
        <FullLoader />
      </div>
    );
  }

  if (errorTeams || errorInvitations || errorBouncerStatus) {
    throw errorTeams || errorInvitations || errorBouncerStatus;
  }

  const handleAcceptInvitation = async (teamId) => {
    await axios.post(
      `/rest/v1/team/member/accept`,
      {
        teamId,
      },
      { signal }
    );
    onChangeTeam(teamId, redirect);
  };

  const handleRejectInvitation = async (teamId) => {
    await axios.post(
      `/rest/v1/team/member/reject`,
      {
        teamId,
      },
      { signal }
    );
    refreshInvitations();
  };
  if (teamsAndInvitationsCount === 0 && !bouncerStatus.isAllowed)
    return <InWaitList />;

  // Show nothing, should redirect to create team anyway
  if (teamsAndInvitationsCount === 0 && bouncerStatus.isAllowed)
    return <Navigate to="/create-team" />;

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Page content */}
      <main className="flex-grow bg-gradient-to-b from-gray-100 to-white">
        <Link
          className="py-5 px-10 float-right flex items-center gap-1"
          to="/profile"
        >
          <HiCog className="inline-block" /> My Account
        </Link>
        <section className="max-w-lg mx-auto px-4 sm:px-6">
          <h1 className="h1 text-center pt-20 pb-8">Select Team.</h1>
          <div className="grid divide-y rounded-sm">
            {teams.map((team) => (
              <div
                key={team.id}
                className="flex py-3 items-center justify-between"
              >
                <span className="text-center font-medium mx-3 text-lg">
                  {team.name}
                </span>
                <button
                  className="btn-xs btn-outline block"
                  onClick={() => onChangeTeam(team.id, redirect)}
                >
                  Select <FiArrowRight className="inline-block" />
                </button>
              </div>
            ))}
            {invitations.map((invitation) => (
              <div
                key={invitation.team.id}
                className="flex items-center py-3 justify-between"
              >
                <span className="text-center font-medium mx-3 text-lg flex items-center">
                  {invitation.team.name}
                  <Badge type="success" className="ml-2">
                    invitation
                  </Badge>
                </span>
                <div className="flex">
                  <LoadingButton
                    className="btn-xs btn-blue block"
                    onClick={() => handleAcceptInvitation(invitation.team.id)}
                  >
                    Accept
                  </LoadingButton>
                  <LoadingButton
                    className="btn-xs ml-3 btn-outline block"
                    onClick={() => handleRejectInvitation(invitation.team.id)}
                  >
                    Reject
                  </LoadingButton>
                </div>
              </div>
            ))}
            {bouncerStatus.isAllowed && (
              <div className="flex py-3 items-center justify-between">
                <span className="text-center text-gray-500 font-medium mx-3 text-lg flex items-center">
                  Create new team
                </span>
                <div>
                  <Link to="/create-team" className="btn-sm btn-green block">
                    <FiPlus className="w-8" />
                  </Link>
                </div>
              </div>
            )}
          </div>
        </section>
      </main>
    </div>
  );
}
