import { useMemo } from "react";
import cn from "classnames";

const snakeToPascal = (string) =>
  string
    .toLowerCase()
    .split("_")
    .map((substr) => substr.charAt(0).toUpperCase() + substr.slice(1))
    .join("");

export default function NodeStateBadge(props) {
  const { className, state = "ACTIVE" } = props;

  const stateStyle = {
    ACTIVE: "bg-green-100 text-green-700",
    DELETE_IN_PROGRESS: "bg-red-100 text-red-500",
    PENDING: "bg-orange-100 text-orange-500",
    FAILED: "bg-red-100 text-red-500",
    CONNECTED: "bg-orange-100 text-orange-500",
    RECYCLING: "bg-teal-100 text-teal-500",
    OUTDATED: "bg-amber-100 text-amber-500",
    READY: "bg-green-100 text-green-700",
    NOT_READY: "bg-gray-100 text-gray-500",
  };
  const style = stateStyle[state] || "bg-blue-100 text-blue-500";
  const stateStr = useMemo(() => snakeToPascal(state), [state]);

  return (
    <span
      className={cn(
        className,
        style,
        "inline-block rounded text-xs py-0.5 px-1 font-semibold"
      )}
    >
      {stateStr}
    </span>
  );
}
