import * as Yup from "yup";
import {
  Tooltip,
  AxiosErrorAlert,
  Dialog,
  Field,
  Label,
  Menu,
  NodePoolKubeStateBadge,
  Table,
  FullLoader,
  CopyToClipboardField,
  NodeTypeSelector,
} from "components";
import { FieldArray, Form, Formik } from "formik";
import {
  useApiResource,
  useInterval,
  useAxios,
  useToast,
  useAuthority,
} from "hooks";
import { useMemo, useState } from "react";
import { FiDelete, FiMoreHorizontal, FiPlus } from "react-icons/fi";
import AcceptCancelDialog from "partials/AcceptCancelDialog";
import ClusterTelemetryPanel from "partials/ClusterTelemetryPanel";
import FeatureGate from "components/FeatureGate";
import InfoTooltip from "components/InfoTooltip";
import HorizontalProgressBar from "components/HorizontalProgressBar";
import TxRxSpan from "components/TxRxSpan";
import ManifestCopyButton from "components/ManifestCopyButton";

const createPoolSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Pool name must be longer than 3 characters")
    .max(64, "Pool name can't be more than 64 characters")
    .required("You need to provide a name for your pool"),
});

function LabelInputArray({ values }) {
  return (
    <FieldArray name="labels">
      {({ remove, push }) => (
        <div className="col-span-5">
          {values?.labels.map((label, index) => (
            <div
              key={`${index}:${label.toString()}`}
              className="form-row-input py-1 mb-1 relative flex items-start"
            >
              <Label>
                <span className="text-gray-700 font-medium">Key</span>
                <div className="mt-0.5">
                  <Field.Text name={`labels.${index}.key`} />
                </div>
              </Label>
              <Label className="ml-3">
                <span className="text-gray-700 font-medium">Value</span>
                <div className="mt-0.5">
                  <Field.Text name={`labels.${index}.value`} />
                </div>
              </Label>
              <button
                type="button"
                title="Remove node pool"
                className="ml-1 text-gray-500 flex mt-8 inline-block"
                onClick={() => remove(index)}
              >
                <FiDelete className="inline-block ml-1" />
              </button>
            </div>
          ))}
          <button
            className="btn-xs btn-outline w-full flex justify-center items-center"
            type="button"
            onClick={() => push({ key: "", value: "" })}
          >
            <FiPlus className="inline-block mr-0.5" />
            Add Label
          </button>
        </div>
      )}
    </FieldArray>
  );
}

const taintEffectOptions = Object.freeze([
  { value: "NoSchedule", element: "NoSchedule" },
  { value: "PreferNoSchedule", element: "PreferNoSchedule" },
  { value: "NoExecute", element: "NoExecute" },
]);

function TaintInputArray({ values }) {
  return (
    <FieldArray name="taints">
      {({ remove, push }) => (
        <div className="col-span-5">
          {values?.taints.map((taint, index) => (
            <div
              key={`${index}:${taint.toString()}`}
              className="form-row-input py-1 mb-1 relative flex items-start"
            >
              <Label>
                <span className="text-gray-700 font-medium">Key</span>
                <div className="mt-0.5">
                  <Field.Text name={`taints.${index}.key`} />
                </div>
              </Label>
              <Label className="ml-3">
                <span className="text-gray-700 font-medium">Value</span>
                <div className="mt-0.5">
                  <Field.Text name={`taints.${index}.value`} />
                </div>
              </Label>
              <Label className="ml-3">
                <span className="text-gray-700 font-medium">Effect</span>
                <div className="mt-0.5">
                  <Field.Listbox
                    options={taintEffectOptions}
                    name={`taints.${index}.effect`}
                  />
                </div>
              </Label>
              <button
                type="button"
                title="Remove node pool"
                className="ml-1 text-gray-500 flex mt-8 inline-block"
                onClick={() => remove(index)}
              >
                <FiDelete className="inline-block ml-1" />
              </button>
            </div>
          ))}
          <button
            className="btn-xs btn-outline w-full flex justify-center items-center"
            type="button"
            onClick={() => push({ key: "", value: "", effect: "NoSchedule" })}
          >
            <FiPlus className="inline-block mr-0.5" />
            Add Taint
          </button>
        </div>
      )}
    </FieldArray>
  );
}

const hclifyString = str => str.replace("-", "_");

function ManifestCopyWrapper({
  clusterName,
  values
}) {
  const filteredTaints = useMemo(() => values.taints.filter((x) => x.key && x.value), [values.taints]);
  const filteredLabels = useMemo(() => values.labels.filter((x) => x.key && x.value), [values.labels]);
  const pulumiGoText = useMemo(() => `package main

import (
	"github.com/pulumi/pulumi/sdk/v3/go/pulumi"
	"github.com/symbiosis-cloud/pulumi-symbiosis/sdk/go/symbiosis"
)

func main() {
  pulumi.Run(func(ctx *pulumi.Context) error {
    _, err = symbiosis.NewNodePool(ctx, "${values.name}", &symbiosis.NodePoolArgs{
      Cluster:  "${clusterName}",
      NodeType: pulumi.String("${values.nodeTypeName}"),${filteredLabels?.length ? `\n        Labels: map[string]string{${filteredLabels.map(label => `"${label.key}": "${label.value}"`).join(", ")}},` : ""}${filteredTaints?.length ? `\n        Taints: []NodePoolTaintArgs{${filteredTaints.map(taint => `
          {
            Key: "${taint.key}",
            Value: "${taint.value}",
            Effect: "${taint.effect}",
          }`).join(", ")}},` : ""}
      ${values.autoscalerEnabled ? `Quantity: pulumi.Int(${values.quantity}),
      Autoscaling: &symbiosis.NodePoolAutoscalingArgs{
        Enabled: true
        MinSize: ${values.quantity}
        MaxSize: ${values.maxQuantity}
      }` : `      Quantity: pulumi.Int(${values.quantity})`}
    })
    if err != nil {
      return err
    }
    return nil
  })
}`, [clusterName, filteredLabels, filteredTaints, values.autoscalerEnabled, values.maxQuantity, values.name, values.nodeTypeName, values.quantity])

  const pulumiPythonText = useMemo(() => `${hclifyString(values.name)} = symbiosis.NodePool("${values.name}",
    cluster="${clusterName}",
    node_type="${values.nodeTypeName}",
    ${values.autoscalerEnabled ? `quantity=${values.quantity},
    autoscaling=symbiosis.NodePoolAutoscalingArgs(enabled=True, min_size=${values.quantity}, max_size=${values.maxQuantity})` : `quantity=${values.quantity}`}${filteredLabels?.length ? `,\n    labels={${filteredLabels.map(label => `"${label.key}": "${label.value}"`).join(", ")}}` : ""}${filteredTaints?.length ? `,\n    taints=[${filteredTaints.map(taint => `symbiosis.NodePoolTaintArgs(key="${taint.key}", value="${taint.value}", effect="${taint.effect}")`).join(", ")}]` : ""})`, [clusterName, filteredLabels, filteredTaints, values.autoscalerEnabled, values.maxQuantity, values.name, values.nodeTypeName, values.quantity])

  const pulumiTSText = useMemo(() => `import * as pulumi from "@pulumi/pulumi";
import * as symbiosis from "@symbiosis/symbiosis-pulumi";

const ${hclifyString(values.name)} = new symbiosis.NodePool("${values.name}", {
  cluster: "${clusterName}",
  nodeType: "${values.nodeTypeName}",${filteredTaints?.length ? `\n  taints: [${filteredTaints.map(taint => `{
    key: "${taint.key}",
    value: "${taint.value}",
    effect: "${taint.effect}"
  }`).join(", ")}],` : ""}${filteredLabels?.length ? `\n  labels: [${filteredLabels.map(label => `{"${label.key}": "${label.value}"}`).join(", ")}],` : ""}
  ${values.autoscalerEnabled ? `quantity: ${values.quantity},
  autoscaling: {
    enabled: true
    min_size: ${values.quantity}
    max_size: ${values.maxQuantity}
  }` : `quantity: ${values.quantity}`}
});`, [clusterName, filteredLabels, filteredTaints, values.autoscalerEnabled, values.maxQuantity, values.name, values.nodeTypeName, values.quantity])

  const terraformText = useMemo(() => `resource "symbiosis_node_pool" "${hclifyString(values.name)}" {
  cluster = "${clusterName}"
  name = "${values.name}"
  node_type = "${values.nodeTypeName}"
  ${values.autoscalerEnabled ? `quantity = ${values.quantity}

  autoscaling {
    enabled = true
    min_size = ${values.quantity}
    max_size = ${values.maxQuantity}
  }` : `quantity = ${values.quantity}`}${filteredLabels.length ? `

  labels = {\n${filteredLabels.map(label => `    ${label.key} = "${label.value}"`).join("\n")}\n  }`: ""}${filteredTaints.map(taint => `\n\n  taint {
    key = "${taint.key}"
    value = "${taint.value}"
    effect = "${taint.effect}"
  }`).join("")}
}`, [clusterName, filteredLabels, filteredTaints, values.autoscalerEnabled, values.maxQuantity, values.name, values.nodeTypeName, values.quantity])
  return (
    <ManifestCopyButton terraformText={terraformText} pulumiTypescriptText={pulumiTSText} pulumiPythonText={pulumiPythonText} pulumiGoText={pulumiGoText} />
  );
}

function AddNodePoolDialog({ clusterName, isOpen, onSubmit, onClose }) {
  const axios = useAxios();
  const initialName = useMemo(
    () => "pool-" + (Math.random() + 1).toString(36).substring(2, 8),
    []
  );
  const {
    data: nodeTypes,
    isFetching: isFetchingNodeTypes,
    error: errorNodeTypes,
  } = useApiResource("/rest/v1/node-type");
  if (isFetchingNodeTypes) return <FullLoader />;
  if (errorNodeTypes) throw errorNodeTypes;

  return (
    <Dialog open={!!isOpen} onClose={onClose} className="">
      <Dialog.Panel className="max-w-3xl">
        <Dialog.Title>Add Node Pool</Dialog.Title>
        <Formik
          validateOnBlur
          validationSchema={createPoolSchema}
          initialValues={{
            name: initialName,
            quantity: 1,
            maxQuantity: 3,
            autoscalingEnabled: false,
            nodeTypeName: nodeTypes[0].name,
            labels: [],
            taints: [],
          }}
          onSubmit={async (
            {
              name,
              quantity,
              maxQuantity,
              labels,
              taints,
              nodeTypeName,
              autoscalerEnabled,
            },
            { setSubmitting, setStatus }
          ) => {
            setStatus();
            const filteredTaints = taints.filter((x) => x.key && x.value);
            const filteredLabels = labels.filter((x) => x.key && x.value);
            const autoscaling = autoscalerEnabled
              ? {
                  enabled: true,
                  minSize: quantity,
                  maxSize: maxQuantity,
                }
              : undefined;
            try {
              await axios.post(`/rest/v1/node-pool`, {
                clusterName,
                name,
                taints: filteredTaints,
                labels: filteredLabels,
                quantity,
                nodeTypeName,
                autoscaling,
              });
            } catch (e) {
              return setStatus(e);
            } finally {
              setSubmitting(false);
            }
            onSubmit();
          }}
        >
          {({ values, isSubmitting, handleChange, status }) => (
            <Form autoComplete="off">
              <div>
                <Dialog.Description>
                  Configure the name, type, quantity and optionally any taints
                  or labels to add to your new node pool.
                </Dialog.Description>
                <hr className="form-row-divider" />
                <div className="mb-4">
                  <div className="grid grid-cols-7 py-1 items-center">
                    <label
                      htmlFor="name"
                      className="flex text-sm gap-1 items-center form-row-label"
                    >
                      Name
                    </label>
                    <Field.Text name="name" className="form-row-input" />
                  </div>
                </div>
                <div className="mb-4">
                  <div className="grid grid-cols-7 py-1 items-center">
                    <label
                      htmlFor="nodeTypeName"
                      className="flex text-sm gap-1 items-center form-row-label"
                    >
                      Node Type
                    </label>
                    <NodeTypeSelector
                      name="nodeTypeName"
                      fixed
                      nodeTypes={nodeTypes}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="mb-4">
                  <div className="grid grid-cols-7 py-1 items-center">
                    <label
                      htmlFor="autoscalerEnabled"
                      className="flex text-sm gap-1 items-center form-row-label"
                    >
                      Autoscaling
                    </label>
                    <Field.RadioButton name="autoscalerEnabled" />
                  </div>
                </div>
                <div className="mb-4">
                  <div className="grid grid-cols-7 py-1 items-center">
                    <label
                      htmlFor="quantity"
                      className="flex text-sm gap-1 items-center form-row-label"
                    >
                      Quantity
                    </label>
                    <span className="flex col-span-5">
                      <div className="relative">
                        <Field.NumberStepper
                          name="quantity"
                          min={values.autoscalerEnabled ? 0 : 1}
                          className="min-h-[3rem] w-40"
                        />
                        <span className="absolute -top-4 text-xs text-gray-600">
                          Min
                        </span>
                      </div>
                      {values.autoscalerEnabled && (
                        <div className="relative ml-4">
                          <Field.NumberStepper
                            name="maxQuantity"
                            min={Math.max(values.quantity, 1)}
                            className="min-h-[3rem] w-40"
                          />
                          <span className="absolute -top-4 text-xs text-gray-600">
                            Max
                          </span>
                        </div>
                      )}
                    </span>
                  </div>
                </div>
                <div className="mb-4">
                  <div className="grid grid-cols-7 py-1 items-center">
                    <label
                      htmlFor="taints"
                      className="flex text-sm gap-1 items-center form-row-label"
                    >
                      Taints
                    </label>
                    <TaintInputArray values={values} />
                  </div>
                </div>
                <div className="mb-4">
                  <div className="grid grid-cols-7 py-1 items-center">
                    <label
                      htmlFor="taints"
                      className="flex text-sm gap-1 items-center form-row-label"
                    >
                      Labels
                    </label>
                    <LabelInputArray values={values} />
                  </div>
                </div>
                <AxiosErrorAlert className="mx-3 mt-3" error={status} />
              </div>
              <div className="flex gap-2 justify-end">
                <button
                  disabled={isSubmitting}
                  type="submit"
                  className="w-full sm:w-auto btn-sm btn-blue"
                >
                  Add
                </button>
                <ManifestCopyWrapper clusterName={clusterName} values={values} />
                <button
                  type="button"
                  className="w-full sm:w-auto btn-sm btn-outline"
                  onClick={onClose}
                >
                  Close
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Dialog.Panel>
    </Dialog>
  );
}

function UpdateNodePoolDialog({
  id,
  name,
  quantity,
  autoscalerEnabled,
  isOpen,
  onSubmit,
  onClose,
}) {
  const axios = useAxios();

  return (
    <Dialog open={!!isOpen} onClose={onClose}>
      <Dialog.Panel>
        <Dialog.Title>Update Node Pool</Dialog.Title>
        <Formik
          initialValues={{
            name,
            quantity,
            autoscalerEnabled,
            maxQuantity: quantity + 1,
          }}
          onSubmit={async (values, { setSubmitting, setStatus }) => {
            setStatus();
            try {
              await axios.put(`/rest/v1/node-pool/${id}`, {
                name: name !== values.name ? values.name : undefined,
                quantity:
                  quantity !== values.quantity ? values.quantity : undefined,
                autoscaling: {
                  enabled: values.autoscalerEnabled,
                  minSize: values.quantity,
                  maxSize: values.maxQuantity,
                },
              });
            } catch (e) {
              return setStatus(e);
            } finally {
              setSubmitting(false);
            }
            onSubmit();
          }}
        >
          {({ values, isSubmitting, status }) => (
            <Form autoComplete="off">
              <div>
                <div className="mb-5 text-gray-600 text-sm">
                  Change the name or the number of nodes in your node pool.
                </div>
                <hr className="form-row-divider" />
                <div className="mb-4">
                  <div className="grid grid-cols-7 py-1 items-center">
                    <label
                      htmlFor="name"
                      className="flex text-sm gap-1 items-center form-row-label"
                    >
                      Name
                    </label>
                    <Field.Text name="name" className="form-row-input" />
                  </div>
                </div>
                <div className="mb-4">
                  <div className="grid grid-cols-7 py-1 items-center">
                    <label
                      htmlFor="autoscalerEnabled"
                      className="flex text-sm gap-1 items-center form-row-label"
                    >
                      Autoscaling
                    </label>
                    <Field.RadioButton name="autoscalerEnabled" />
                  </div>
                </div>
                <div className="mb-4">
                  <div className="grid grid-cols-7 py-1 items-center">
                    <label
                      htmlFor="quantity"
                      className="flex text-sm gap-1 items-center form-row-label"
                    >
                      Quantity
                    </label>
                    <span className="flex col-span-5">
                      <div className="relative">
                        <Field.NumberStepper
                          name="quantity"
                          min={values.autoscalerEnabled ? 0 : 1}
                          className="min-h-[3rem] w-40"
                        />
                        <span className="absolute -top-4 text-xs text-gray-600">
                          Min
                        </span>
                      </div>
                      {values.autoscalerEnabled && (
                        <div className="relative ml-4">
                          <Field.NumberStepper
                            name="maxQuantity"
                            min={values.quantity}
                            className="min-h-[3rem] w-40"
                          />
                          <span className="absolute -top-4 text-xs text-gray-600">
                            Max
                          </span>
                        </div>
                      )}
                    </span>
                  </div>
                </div>
                <AxiosErrorAlert className="mt-3" error={status} />
              </div>
              <div className="flex justify-end">
                <button
                  disabled={isSubmitting}
                  type="submit"
                  className="w-full sm:w-auto btn btn-blue"
                >
                  Update
                </button>
                <button
                  type="button"
                  className="w-full ml-3 sm:w-auto btn btn-outline"
                  onClick={onClose}
                >
                  Close
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Dialog.Panel>
    </Dialog>
  );
}

function NodePoolTableRow({
  id,
  name,
  quantity,
  autoscalerEnabled,
  autoscalingMin,
  autoscalingMax,
  onSubmit,
  nodes,
  taints,
  nodeTypeName,
  telemetry,
}) {
  const axios = useAxios();
  const toast = useToast();
  const readyNodes = nodes.reduce(
    (a, b) => a + (b.kubeState === "READY" || 0),
    0
  );
  const notReadyNodes = quantity - readyNodes;
  const [isUpdateNodePoolOpen, setIsUpdateNodePoolOpen] = useState(false);
  const [isDeletePoolDialogOpen, setIsDeletePoolDialogOpen] = useState(false);

  const handleSubmitNodePoolUpdate = () => {
    setIsUpdateNodePoolOpen(false);
    onSubmit();
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`/rest/v1/node-pool/${id}`);
      setIsDeletePoolDialogOpen(false);
      onSubmit();
    } catch (err) {
      toast.exception("Failed to delete node pool", err);
    }
  };

  const taintsStrings = taints.map((x) => `${x.key}=${x.value}:${x.effect}`);

  return (
    <Table.Row className="">
      <Table.Cell>
        <UpdateNodePoolDialog
          id={id}
          name={name}
          quantity={quantity}
          maxQuantity={autoscalingMax ?? quantity + 1}
          autoscalerEnabled={autoscalerEnabled}
          isOpen={isUpdateNodePoolOpen}
          onSubmit={handleSubmitNodePoolUpdate}
          onClose={() => setIsUpdateNodePoolOpen(false)}
        />
        <span className="font-medium">{name}</span>
      </Table.Cell>
      <Table.Cell>{nodeTypeName}</Table.Cell>
      <Table.Cell>
        {autoscalerEnabled ? (
          <>
            On (min {autoscalingMin} / max {autoscalingMax})
          </>
        ) : (
          "Off"
        )}
      </Table.Cell>
      <Table.Cell>
        {taints.length > 0 && (
          <Tooltip
            label={
              <span className="underline decoration-dotted">
                {taints.length}×
              </span>
            }
          >
            {taintsStrings.length > 0 && taintsStrings.join("\n")}
          </Tooltip>
        )}
      </Table.Cell>
      <Table.Cell>
        <NodePoolKubeStateBadge quantity={readyNodes} kubeState="READY" />{" "}
        {notReadyNodes > 0 && (
          <NodePoolKubeStateBadge
            quantity={notReadyNodes}
            kubeState="NOT_READY"
          />
        )}
      </Table.Cell>
      <Table.Cell
        className="align-middle"
        style={{
          paddingTop: 0,
          paddingBottom: 0,
          paddingRight: 0,
          paddingLeft: 0,
        }}
      >
        <div className="flex gap-3 items-center">
          <HorizontalProgressBar
            title="CPU"
            value={telemetry?.cpuUsage}
            strokeColor="hsl(150deg 30% 60%)"
          />
          <HorizontalProgressBar
            title="MEM"
            value={telemetry?.memoryUsage}
            strokeColor="hsl(280deg 30% 60%)"
          />
          <TxRxSpan
            tx={telemetry?.txBytesPerSecond}
            rx={telemetry?.rxBytesPerSecond}
          />
        </div>
      </Table.Cell>
      <Table.Cell>
        <AcceptCancelDialog
          isOpen={isDeletePoolDialogOpen}
          onSubmit={handleDelete}
          onClose={() => setIsDeletePoolDialogOpen(false)}
          title={`Are you sure you want to delete ${name}?`}
        >
          This action is irreversible.
        </AcceptCancelDialog>
        <Menu>
          <Menu.Button as="div" className="cursor-pointer float-right">
            <FiMoreHorizontal />
          </Menu.Button>
          <Menu.Items>
            <Menu.ButtonItem
              as="button"
              onClick={() => setIsUpdateNodePoolOpen(true)}
            >
              Update
            </Menu.ButtonItem>
            <Menu.ButtonItem
              as="button"
              type="danger"
              onClick={() => setIsDeletePoolDialogOpen(true)}
            >
              Delete
            </Menu.ButtonItem>
          </Menu.Items>
        </Menu>
      </Table.Cell>
    </Table.Row>
  );
}

function VersionDialog({
  isOpen,
  possibleUpgradeTargets,
  cluster,
  onClose,
  onSubmit,
}) {
  const axios = useAxios();

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Dialog.Panel>
        <Dialog.Title>Update Kubernetes Version</Dialog.Title>
        <Dialog.Description className="text-sm">
          By upgrading we will replace your old nodes with new nodes with the
          correct version. This will increase your number of nodes above the
          node pool maximum.
        </Dialog.Description>
        <Formik
          initialValues={{ kubeVersion: possibleUpgradeTargets?.[0] }}
          onSubmit={async ({ kubeVersion }, { setSubmitting, setStatus }) => {
            try {
              await axios.put(`/rest/v1/cluster/${cluster.name}/version`, {
                kubeVersion,
              });
              onSubmit();
            } catch (e) {
              setStatus(e);
              setSubmitting(false);
            }
          }}
        >
          {({ status }) => (
            <Form>
              <div className="flex items-center my-4">
                <div className="mr-2 text-gray-600">Upgrading to</div>
                {possibleUpgradeTargets.length > 1 ? (
                  <Field.Listbox
                    options={possibleUpgradeTargets.map((x) => ({
                      value: x,
                      element: `v${x}`,
                    }))}
                    name="kubeVersion"
                  />
                ) : (
                  <span className="text-gray-700">
                    v{possibleUpgradeTargets[0]}
                  </span>
                )}
              </div>
              <AxiosErrorAlert className="mx-3 mt-3" error={status} />
              <div className="mt-6 flex justify-end">
                <button
                  className="w-full sm:w-auto btn btn-outline"
                  type="button"
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button
                  className="w-full ml-3 sm:w-auto btn btn-blue"
                  type="submit"
                >
                  Update
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Dialog.Panel>
    </Dialog>
  );
}

const TELEMETRY_REFRESH_INTERVAL = 60 * 1000;

export default function Overview({ cluster, onRefreshClusters }) {
  const totalVCPUs = cluster.nodes
    .map((x) => x.nodeType.vcpu)
    .reduce((acc, val) => acc + val, 0);
  const totalMemoryGi = Math.round(
    cluster.nodes
      .map((x) => x.nodeType.memoryMi)
      .reduce((acc, val) => acc + val, 0) / 1024
  );

  const { hasAuthority } = useAuthority();
  const [versionDialogIsOpen, setVersionDialogIsOpen] = useState(false);
  const {
    data: version,
    isFetching,
    error,
    refresh,
  } = useApiResource(`/rest/v1/cluster/${cluster.name}/version`);

  const { data: poolTelemetry, refresh: refreshTelemetry } = useApiResource(
    `/rest/v1/cluster/${cluster.id}/node-pool/telemetry`
  );
  useInterval(refreshTelemetry, TELEMETRY_REFRESH_INTERVAL);
  const poolToTelemetry = useMemo(
    () => poolTelemetry?.reduce((a, v) => ({ ...a, [v.nodePoolId]: v }), {}),
    [poolTelemetry]
  );

  const [isAddNodePoolOpen, setIsAddNodePoolOpen] = useState();
  const handleSubmitNodePool = () => {
    setIsAddNodePoolOpen(false);
    onRefreshClusters();
  };

  if (isFetching) return null;
  if (error) throw error;

  const canUpgrade = version.possibleUpgradeTargets.length > 0;

  const handleSubmitVersion = () => {
    refresh();
    setVersionDialogIsOpen(false);
  };

  return (
    <div className="py-5 grid gap-5">
      <VersionDialog
        isOpen={versionDialogIsOpen}
        onClose={() => setVersionDialogIsOpen(false)}
        onSubmit={handleSubmitVersion}
        possibleUpgradeTargets={version.possibleUpgradeTargets}
        cluster={cluster}
      />
      <AddNodePoolDialog
        clusterName={cluster.name}
        isOpen={isAddNodePoolOpen}
        onClose={() => setIsAddNodePoolOpen(false)}
        onSubmit={handleSubmitNodePool}
      />
      <section>
        <ClusterTelemetryPanel clusterId={cluster.id} />
      </section>
      <section>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Cell>Node Pool</Table.Cell>
              <Table.Cell>Type</Table.Cell>
              <Table.Cell>Autoscaling</Table.Cell>
              <Table.Cell>Taints</Table.Cell>
              <Table.Cell>State</Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell>
                <button
                  className="text-blue-500 transition-colors duration-150 focus-visible:text-blue-600 focus:outline-none hover:text-blue-600 font-medium float-right flex items-center"
                  onClick={() => setIsAddNodePoolOpen(true)}
                >
                  <FiPlus className="inline-block mr-1" />
                  Add New Pool
                </button>
              </Table.Cell>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {cluster.nodePools
              ?.filter((x) => x.state === 'ACTIVE')
              .map((x) => (
                <NodePoolTableRow
                  key={x.id}
                  id={x.id}
                  name={x.name}
                  quantity={x.desiredQuantity}
                  autoscalerEnabled={x.autoscaling?.enabled ?? false}
                  autoscalingMin={x.autoscaling?.minSize}
                  autoscalingMax={x.autoscaling?.maxSize}
                  taints={x.taints}
                  nodes={x.nodes}
                  nodeTypeName={x.nodeTypeName}
                  onSubmit={onRefreshClusters}
                  telemetry={poolToTelemetry?.[x.id]}
                />
              ))}
          </Table.Body>
        </Table>
      </section>
      <section>
        <div>
          <h3 className="lowercase text-xl tracking-tight font-semibold text-gray-600 mb-2">
            Configuration
          </h3>
          <hr className="mb-3" />
          <ul className="grid grid-cols-2 gap-2">
            <li>
              <table className="text-sm">
                <tbody>
                  <tr>
                    <td className="min-w-[10rem] py-1 text-gray-500">
                      Pod Subnet
                    </td>
                    <td>
                      <CopyToClipboardField text={cluster.podSubnet}>
                        <span className="font-mono break-all text-gray-600">
                          {cluster.podSubnet}
                        </span>
                      </CopyToClipboardField>
                    </td>
                  </tr>
                  <tr>
                    <td className="min-w-[10rem] py-1 text-gray-500">
                      Service Subnet
                    </td>
                    <td>
                      <CopyToClipboardField text={cluster.serviceSubnet}>
                        <span className="font-mono break-all text-gray-600">
                          {cluster.serviceSubnet}
                        </span>
                      </CopyToClipboardField>
                    </td>
                  </tr>
                  <tr>
                    <td className="min-w-[10rem] py-1 text-gray-500">
                      API Server URI
                    </td>
                    <td>
                      <CopyToClipboardField text={cluster.apiServerEndpoint}>
                        <span className="text-sm font-mono break-all text-gray-600">
                          {cluster.apiServerEndpoint}
                        </span>
                      </CopyToClipboardField>
                    </td>
                  </tr>
                </tbody>
              </table>
            </li>
            <li>
              <table className="text-sm">
                <tbody>
                  <tr>
                    <td className="min-w-[10rem] py-1 text-gray-500">
                      Total capacity
                    </td>
                    <td>
                      <span className="text-gray-600">
                        {totalVCPUs} vCPUs {totalMemoryGi} GiB memory
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="min-w-[10rem] py-1 text-gray-500">
                      Kube Version
                    </td>
                    <td>
                      <span className="text-gray-600">
                        v{version.installedKubeVersion}
                        {version.installedKubeVersion !==
                          version.wantedKubeVersion && (
                          <span> → {version.wantedKubeVersion}</span>
                        )}
                        {hasAuthority("IS_ADMIN") &&
                          !version.isUpgradeInProgress &&
                          canUpgrade && (
                            <button
                              onClick={() => setVersionDialogIsOpen(true)}
                              className="ml-2 btn-xs btn-outline"
                            >
                              upgrade
                            </button>
                          )}
                      </span>
                    </td>
                  </tr>
                  <FeatureGate name="HIGHLY_AVAILABLE_CONTROL_PLANES">
                    <tr>
                      <td className="min-w-[10rem] py-1 text-gray-500">
                        HA control plane
                        <InfoTooltip
                          className="pl-1"
                          text="Whether the cluster is configured with control plane replicas."
                        />
                      </td>
                      <td>
                        <span className="text-sm font-mono break-all text-gray-600">
                          {cluster.isHighlyAvailable ? "Enabled" : "Disabled"}
                        </span>
                      </td>
                    </tr>
                  </FeatureGate>
                </tbody>
              </table>
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
}
