import Badge from "./Badge";

export default function ProjectRunStateBadge({ state }) {
  if (state === "COMPLETED") return <Badge type="success">Success</Badge>;
  else if (state === "FAILED") return <Badge type="danger">Failure</Badge>;
  else if (state === "INITIALIZING_ENVIRONMENT" || state === "RUNNING_TESTS")
    return <Badge type="neutral">Running</Badge>;
  else if (state === "PENDING")
    return <Badge className="bg-gray-100 text-gray-500">Pending</Badge>;
  else throw Error("Invalid state");
}
