import { Fragment } from "react";
import { Menu as HeadlessMenu, Transition } from "@headlessui/react";
import cn from "classnames";

export default function Menu({ as = "div", className, ...props }) {
  return (
    <HeadlessMenu
      {...props}
      as={as}
      className={cn("relative text-left", className)}
    />
  );
}

Menu.Button = HeadlessMenu.Button;

Menu.Items = ({ className, fixed, as = "div", ...props }) => (
  <Transition
    as={Fragment}
    enter="transition ease-out duration-150"
    enterFrom="transform opacity-0"
    enterTo="transform opacity-100"
    leave="transition ease-in duration-100"
    leaveFrom="transform opacity-100"
    leaveTo="transform opacity-0"
  >
    <HeadlessMenu.Items
      {...props}
      as={as}
      className={cn(
        className,
        fixed ? "fixed" : "absolute right-0",
        "z-20 w-56 mt-5 py-0.5 px-1 origin-bottom-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none justify-center"
      )}
    />
  </Transition>
);

Menu.Item = ({ className, as = "div", ...props }) => (
  <HeadlessMenu.Item
    {...props}
    as={as}
    className={cn(className, "w-full p-1 flex items-center")}
  />
);

Menu.ButtonItem = ({ className, type, disabled, buttonType, ...props }) => (
  <HeadlessMenu.Item
    {...props}
    type={buttonType}
    as="button"
    disabled={disabled}
    className={cn(
      className,
      disabled ? "grayscale text-gray-400 cursor-not-allowed" : "text-gray-700",
      !disabled && type === "danger" && "hover:bg-red-500",
      !disabled && type !== "danger" && "hover:bg-blue-400 hover:text-white",
      type === "danger" && "bg-red-400",
      "text-left font-medium rounded-md w-full m-0.5 ml-0 p-1.5 text-sm"
    )}
  />
);
