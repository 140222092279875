import useInterval from "hooks/useInterval";
import { Oval } from "react-loader-spinner";
import cn from "classnames";
import { FiCheck, FiAlertCircle } from "react-icons/fi";
import { Badge } from "components";

const REFRESH_CLUSTER_STATUS_INTERVAL_MS = 3000;

function NodeLoader({ node }) {
  let state;
  if (node.state === "CONNECTED" || node.state === "ACTIVE") {
    state = "complete";
  } else if (node.state === "FAILED") {
    state = "failed";
  } else {
    state = "loading";
  }
  return (
    <div
      className={cn(
        state === "failed" && "bg-red-100 text-red-500",
        state === "complete" && "bg-green-100 text-green-500",
        state === "loading" && "bg-gray-100 text-gray-600",
        "flex items-center rounded text-2xs py-2 px-4 font-semibold lowercase flex items-center justify-center font-mono"
      )}
    >
      {node.name}
      <span className="ml-2">
        {state === "loading" && (
          <Oval height={15} width={15} color="black" secondaryColor="black" />
        )}
        {state === "complete" && <FiCheck />}
        {state === "failed" && <FiAlertCircle />}
      </span>
    </div>
  );
}

export default function ClusterLoader({ cluster, onRefresh }) {
  useInterval(onRefresh, REFRESH_CLUSTER_STATUS_INTERVAL_MS);
  return (
    <div className="max-w-xl mx-auto mt-32 w-full flex flex-col items-center justify-center">
      <div className="mb-6">
        <h1 className="text-2xl font-semibold">☕️ Setting up...</h1>
        <span className="font-sm">Waiting time: ~2 min</span>
      </div>
      <div
        className={cn(
          cluster?.nodes?.length > 4 ? "grid-cols-3" : "grid-cols-2",
          "grid items-center gap-2 w-full"
        )}
      >
        <Badge type="neutral" className="col-span-full row-span-2">
          <div className="py-2 px-1.5 flex items-center justify-center text-base text-gray-600 font-mono">
            control-plane
            <span className="ml-2">
              <Oval
                height={15}
                width={15}
                color="black"
                secondaryColor="black"
              />
            </span>
          </div>
        </Badge>
        {cluster?.nodes?.map((x) => (
          <NodeLoader node={x} key={x.id} />
        ))}
      </div>
    </div>
  );
}
