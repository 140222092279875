import useApiResource from "hooks/useApiResource";

export default function InvitationAlert({ invitationId }) {
  const {
    data: invitation,
    isFetching,
    error,
  } = useApiResource(`/rest/v1/user/invite/${invitationId}`);
  if (isFetching || error) return null;
  return (
    <p className="text-gray-600">
      You have been invited to{" "}
      <span className="font-semibold">{invitation.team?.name || "N/A"}</span>.
      <br />
      Make sure to sign up or sign in using{" "}
      <span className="font-semibold">{invitation.email}</span> in order to
      accept the invitation.
    </p>
  );
}
