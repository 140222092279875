import { useState } from "react";
import { Oval } from "react-loader-spinner";
import cn from "classnames";
import useTimeout from "hooks/useTimeout";

export default function FullLoader({ className, delay }) {
  const [showLoader, setShowLoader] = useState();
  useTimeout(() => setShowLoader(true), delay != null ? delay : 1000);
  if (!showLoader) return null;

  return (
    <div
      className={cn(
        className,
        "h-full w-full flex items-center justify-center"
      )}
    >
      <Oval
        color="rgb(62, 130, 248)"
        secondaryColor="rgb(62, 130, 248)"
        height={70}
        width={70}
      />
    </div>
  );
}
