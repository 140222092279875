import { useMemo, useCallback, useState, useEffect } from "react";
import axios from "axios";
import useAxios from "hooks/useAxios";
import useAbortController from "hooks/useAbortController";

export default function useCursorPaginatedApiResource(
  url,
  cursorGetter,
  { pageSize = 20 }
) {
  const axiosInstance = useAxios();
  const [cursor, setCursor] = useState();
  const [pageForward, setPageForward] = useState(true);
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [isFetching, setIsFetching] = useState(true);
  const { signal } = useAbortController();
  const stack = useMemo(() => new Error().stack, []);

  const fetchResource = useCallback(() => {
    setIsFetching(true);
    axiosInstance
      .get(url, {
        signal,
        params: {
          size: pageSize,
          cursor,
          pageForward,
        },
      })
      .then(({ data }) => {
        if (signal.aborted) return;
        setData(data);
        setError();
        setIsFetching(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          err.stack = stack;
          setError(err);
          setIsFetching(false);
        }
      })
      .finally(() => setIsFetching(false));
  }, [axiosInstance, url, pageSize, cursor, pageForward, signal, stack]);

  useEffect(() => {
    fetchResource();
  }, [url, pageSize, cursor, pageForward, fetchResource]);
  const hasNext = data?.hasNext;

  return {
    content: data?.content,
    handlePageForward: () => {
      const lastEntry = data.content[data.content.length - 1];
      const nextCursor = cursorGetter(lastEntry);
      setPageForward(true);
      setCursor(nextCursor);
    },
    handlePageBackward: () => {
      const firstEntry = data.content[0];
      const nextCursor = cursorGetter(firstEntry);
      setPageForward(false);
      setCursor(nextCursor);
    },
    canPageForward: (pageForward && hasNext) || !pageForward,
    canPageBackward: (!pageForward && hasNext) || (pageForward && cursor),
    isFetching,
    error,
    refresh: () => fetchResource(),
  };
}
