import { useState } from "react";
import * as Sentry from "@sentry/react";
import { FiMoreHorizontal, FiPlus } from "react-icons/fi";
import {
  usePrettyTimeElapsedSince,
  useApiResource,
  useAxios,
  useAuthority,
  useUser,
} from "hooks";
import UserIdenticon from "components/UserIdenticon";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import {
  AxiosErrorAlert,
  FullLoader,
  Label,
  Input,
  Menu,
  Table,
  Dialog,
} from "components";
import ory from "components/kratos/sdk"

function InviteMembersDialog({ isOpen, onSubmit, onClose }) {
  const axios = useAxios();
  return (
    <Formik
      initialValues={{ role: "MEMBER", emails: "" }}
      onSubmit={async (values, { setSubmitting, setStatus }) => {
        try {
          await axios.post("/rest/v1/team/member/invite", {
            emails: values.emails
              .split(",")
              .map((x) => x.trim())
              .filter(Boolean),
            role: values.role,
          });
          onSubmit();
          setSubmitting(false);
          onClose();
        } catch (err) {
          setStatus(err);
          setSubmitting(false);
        }
      }}
    >
      {({
        values,
        status,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <Dialog open={isOpen} onClose={onClose}>
          <Dialog.Panel>
            <Dialog.Title>Invite Team Members</Dialog.Title>
            <div>
              <Dialog.Description>
                Enter the email address of the users you wish to invite.
                Separate by comma (,) to invite multiple users at once.
              </Dialog.Description>
              <Input
                name="emails"
                className="my-5"
                placeholder="example-1@email.com, example-2@email.com, ..."
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.emails}
              />
              <hr className="form-row-divider" />
              <div>
                <Label radio className="my-1.5 cursor-pointer">
                  <Input
                    name="role"
                    value="ADMIN"
                    type="radio"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={values.role === "ADMIN"}
                  />
                  <div className="grid ml-5 grid-cols-6 items-center">
                    <span className="col-span-1 font-medium text-gray-700">
                      Admin
                    </span>
                    <span className="col-span-5 text-gray-600">
                      This role is for people who need similar access as the
                      account owner. This role can see and manage almost
                      everything.
                    </span>
                  </div>
                </Label>
                <Label radio className="my-1.5 cursor-pointer">
                  <Input
                    name="role"
                    value="MEMBER"
                    type="radio"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={values.role === "MEMBER"}
                  />
                  <div className="grid ml-5 grid-cols-6 items-center">
                    <span className="col-span-1 font-medium text-gray-700">
                      Member
                    </span>
                    <span className="col-span-5 text-gray-600">
                      Members can view most resources and download kube-configs
                      provided that an admin has created a service account for
                      that user.
                    </span>
                  </div>
                </Label>
              </div>
            </div>
            <AxiosErrorAlert error={status} />
            <div className="flex justify-end">
              <button
                className="w-full sm:w-auto btn btn-outline"
                type="button"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                className="w-full ml-3 sm:w-auto btn btn-blue"
                type="submit"
                disabled={isSubmitting}
                onClick={handleSubmit}
              >
                Add
              </button>
            </div>
          </Dialog.Panel>
        </Dialog>
      )}
    </Formik>
  );
}

function UserTableRowDropdown({ email, role, refreshMembers }) {
  const axios = useAxios();
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState();
  const ownUser = useUser();
  const { hasAuthority } = useAuthority();
  const forSelf = ownUser.email === email;
  const actions = [];

  const changeRole = async (role) => {
    await axios.put(`/rest/v1/team/member/${email}`, {
      role,
    });
    refreshMembers();
  };

  const removeMember = async () => {
    await axios.delete(`/rest/v1/team/member/${email}`);
    refreshMembers();
  };

  const removeSelf = async () => {
    await axios.delete(`/rest/v1/team/member/${email}`);
    navigate("/select-team")
  };

  if (role === "MEMBER" && hasAuthority("IS_ADMIN"))
    actions.push(
      <Menu.ButtonItem
        key="change-to-admin"
        onClick={() => changeRole("ADMIN")}
        className=""
      >
        Change user's role to admin
      </Menu.ButtonItem>
    );
  if (role === "ADMIN" && hasAuthority("IS_ADMIN"))
    actions.push(
      <Menu.ButtonItem
        key="change-to-member"
        onClick={() => changeRole("MEMBER")}
        className=""
      >
        Change user's role to member
      </Menu.ButtonItem>
    );
  if (!forSelf && hasAuthority("IS_OWNER"))
    actions.push(
      <Menu.ButtonItem
        key="transfer-ownership"
        onClick={() => changeRole("OWNER")}
        className=""
      >
        Transfer ownership of team
      </Menu.ButtonItem>
    );
  if (forSelf && !hasAuthority("IS_OWNER"))
    actions.push(
      <Menu.ButtonItem key="leave-team" onClick={removeSelf} className="">
        Leave team
      </Menu.ButtonItem>
    );
  if (!forSelf && hasAuthority("IS_ADMIN"))
    actions.push(
      <Menu.ButtonItem key="remove-user" onClick={removeMember} className="">
        Remove user
      </Menu.ButtonItem>
    );
  if (!actions.length) return null;
  return (
    <Menu>
      <Menu.Button as="div" className="cursor-pointer float-right">
        <FiMoreHorizontal
          className="cursor-pointer float-right"
          onClick={() => setDropdownOpen(!dropdownOpen)}
        />
      </Menu.Button>
      <Menu.Items>{actions}</Menu.Items>
    </Menu>
  );
}

function UserTableRow({
  email,
  name,
  joinedAt,
  pictureUrl,
  role,
  refreshMembers,
}) {
  const joinedSince = usePrettyTimeElapsedSince(joinedAt);
  return (
    <Table.Row key={email} className="">
      <Table.Cell className="font-medium flex items-center">
        <UserIdenticon
          className="mr-3 -my-2"
          pictureUrl={pictureUrl}
          email={email}
          size="1.6rem"
        />
        {name || email}
      </Table.Cell>
      <Table.Cell className="lowercase">{role}</Table.Cell>
      <Table.Cell>{joinedSince}</Table.Cell>
      <Table.Cell>
        <UserTableRowDropdown
          email={email}
          role={role}
          refreshMembers={refreshMembers}
        />
      </Table.Cell>
    </Table.Row>
  );
}

function InvitationRow({ email, joinedAt, role, sentAt, onDelete }) {
  const sentSince = usePrettyTimeElapsedSince(sentAt);
  return (
    <Table.Row key={email}>
      <Table.Cell className="font-medium">{email}</Table.Cell>
      <Table.Cell className="lowercase">{role}</Table.Cell>
      <Table.Cell>{sentSince}</Table.Cell>
      <Table.Cell>
        <button onClick={onDelete} className="float-right btn-xs btn-outline">
          Remove
        </button>
      </Table.Cell>
    </Table.Row>
  );
}

export default function Users() {
  const axios = useAxios();
  const { hasAuthority } = useAuthority();
  const [isInvitationDialogOpen, setIsInvitationDialogOpen] = useState(false);
  const {
    data: members,
    error: errorMembers,
    isFetching: isFetchingMembers,
    refresh: refreshMembers,
  } = useApiResource("/rest/v1/team/member");
  const {
    data: invitations,
    error: errorInvitations,
    isFetching: isFetchingInvitations,
    refresh: refreshInvitations,
  } = useApiResource("/rest/v1/team/member/invite");

  const handleDeleteInvitation = async (email) => {
    await axios.delete(`/rest/v1/team/member/${email}`);
    refreshInvitations();
  };

  if (isFetchingMembers || isFetchingInvitations) return <FullLoader />;
  return (
    <section className="p-5">
      <div className="my-3 header">Team Members</div>
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Cell>User</Table.Cell>
            <Table.Cell>Role</Table.Cell>
            <Table.Cell>Joined</Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {members?.map((x) => (
            <UserTableRow
              key={x.email}
              pictureUrl={x.pictureUrl}
              email={x.email}
              name={x.name}
              role={x.role}
              joinedAt={x.createdAt}
              refreshMembers={refreshMembers}
            />
          ))}
          {errorMembers && (
            <Table.ContentRow>
              <div className="text-red-500 text-gray-400 text-center py-3">
                {errorMembers.message}
              </div>
            </Table.ContentRow>
          )}
        </Table.Body>
      </Table>
      <div className="mb-3 mt-12 flex justify-between items-center">
        <div className="header">Pending Invitations</div>
        {hasAuthority("IS_ADMIN") && (
          <button
            onClick={() => setIsInvitationDialogOpen(true)}
            className="btn-xs btn-blue flex items-center"
          >
            <FiPlus className="inline-block mr-1" />
            Invite members
          </button>
        )}
      </div>
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Cell>Email</Table.Cell>
            <Table.Cell>Role</Table.Cell>
            <Table.Cell>Sent</Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {invitations?.map((x) => (
            <InvitationRow
              email={x.email}
              role={x.role}
              sentAt={x.createdAt}
              onDelete={() => handleDeleteInvitation(x.email)}
            />
          ))}
          {!invitations?.length && !errorInvitations && (
            <Table.ContentRow>
              <div className="py-3 flex justify-center">
                No invitations found
                {hasAuthority("IS_ADMIN") && (
                  <>
                    ,{" "}
                    <button
                      onClick={() => setIsInvitationDialogOpen(true)}
                      className="text-blue-600 hover:text-blue-700 inline-flex items-center"
                      to="/clusters/add"
                    >
                      <FiPlus className="mx-1" /> add new members
                    </button>
                  </>
                )}
                .
              </div>
            </Table.ContentRow>
          )}
          {errorInvitations && (
            <Table.ContentRow>
              <div className="text-red-500 text-gray-400 text-center py-3">
                {errorInvitations.message}
              </div>
            </Table.ContentRow>
          )}
        </Table.Body>
      </Table>
      <InviteMembersDialog
        isOpen={isInvitationDialogOpen}
        onSubmit={refreshInvitations}
        onClose={() => setIsInvitationDialogOpen(false)}
      />
    </section>
  );
}
