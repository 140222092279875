import { Dialog } from "components";

export default function AcceptCancelDialog({
  isOpen,
  onClose,
  onSubmit,
  title,
  children,
  isSubmitting,
  submitButtonTitle = "Proceed",
}) {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Dialog.Panel>
        <Dialog.Title>{title}</Dialog.Title>
        <Dialog.Description>{children}</Dialog.Description>
        <div className="flex mt-8 justify-end">
          <button
            className="w-full sm:w-auto btn btn-outline"
            type="button"
            disabled={isSubmitting}
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="w-full ml-3 sm:w-auto btn btn-red"
            type="submit"
            disabled={isSubmitting}
            onClick={onSubmit}
          >
            {submitButtonTitle}
          </button>
        </div>
      </Dialog.Panel>
    </Dialog>
  );
}
