import FullLoader from "components/FullLoader";
import useApiResource from "hooks/useApiResource";
import usePrettyTimeElapsedSince from "hooks/usePrettyTimeElapsedSince";
import { Table } from "components";

function VolumesTableRow({ volume }) {
  const elapsedSince = usePrettyTimeElapsedSince(volume.createdAt);
  return (
    <Table.Row className="">
      <Table.Cell>
        <span className="font-medium text-gray-700">{volume.volumeName}</span>
      </Table.Cell>
      <Table.Cell>
        <span className="text-gray-700">{volume.storageGi} GiB</span>
      </Table.Cell>
      <Table.Cell>{elapsedSince}</Table.Cell>
    </Table.Row>
  );
}

export default function Volumes({ cluster }) {
  const {
    data: volumes,
    error: errorVolumes,
    isFetching: isFetchingVolumes,
  } = useApiResource(`/rest/v1/volume?clusterId=${cluster.id}`);
  if (errorVolumes) throw errorVolumes;

  if (isFetchingVolumes) return <FullLoader />;

  return (
    <section className="mt-5">
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Cell>Name</Table.Cell>
            <Table.Cell>Size</Table.Cell>
            <Table.Cell>Created</Table.Cell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {volumes?.map((x) => (
            <VolumesTableRow key={x.id} volume={x} />
          ))}
          {!volumes?.length && !errorVolumes && (
            <Table.ContentRow>
              <div className="py-3 flex justify-center">No volumes found.</div>
            </Table.ContentRow>
          )}
        </Table.Body>
      </Table>
    </section>
  );
}
