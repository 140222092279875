import { UiNodeAnchorAttributes } from "@ory/client"
import { UiNode } from "@ory/client"

interface Props {
  node: UiNode
  attributes: UiNodeAnchorAttributes
}

export const NodeAnchor = ({ node, attributes }: Props) => {
  return (
    <button
      data-testid={`node/anchor/${attributes.id}`}
      className="btn btn-outline w-full my-2"
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        e.preventDefault()
        window.location.href = attributes.href
      }}
    >
      {attributes.title.text}
    </button>
  )
}
