import { useState } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Field } from "components";
import { CountryDropdown } from "react-country-region-selector";
import { Alert, Input } from "components";
import cn from "classnames";
import useAxios from "hooks/useAxios";

const addAddressSchema = Yup.object().shape({
  city: Yup.string().required("A city is required"),
  postalCode: Yup.string().required("A postal code is required"),
  addressLine1: Yup.string().required("An address is required"),
});

export default function AddAddress({ onSubmit }) {
  const axios = useAxios();
  const [error, setError] = useState();

  return (
    <Formik
      validationSchema={addAddressSchema}
      initialValues={{
        country: "",
        postalCode: "",
        city: "",
        addressLine1: "",
        addressLine2: "",
      }}
      onSubmit={async (
        { fullName, country, postalCode, city, addressLine1, addressLine2 },
        { setSubmitting }
      ) => {
        setSubmitting(true);

        try {
          await axios.put("/rest/v1/stripe/address", {
            country,
            postalCode,
            city,
            addressLine1,
            addressLine2,
          });
          onSubmit();
        } catch (err) {
          setError(err.message);
        }

        setSubmitting(false);
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        isSubmitting,
        handleSubmit,
        setFieldValue,
      }) => (
        <Form className="max-w-md grid gap-3 mx-auto">
          <h2 className="h4 text-gray-900 mt-16">Billing Address</h2>
          <p className="mb-4 text-gray-500">
            Your billing address will appear on your monthly invoice and should
            be the your home address or the legal address of your business.
          </p>
          <Field.Text
            as={Input}
            name="addressLine1"
            placeholder="Address Line 1"
          />
          <Field.Text
            as={Input}
            name="addressLine2"
            placeholder="Address Line 2"
          />
          <div className="grid grid-cols-5 gap-3">
            <CountryDropdown
              className={cn(
                values.country ? "text-gray-800" : "text-gray-500",
                "flex focus:border-blue-400 focus:ring focus:ring-blue-300 focus:ring-blue-300 focus:outline-none p-2 text-sm leading-5 rounded-md border border-gray-300 bg-white bg-gray-700 col-span-3"
              )}
              onChange={(value) => setFieldValue("country", value)}
              valueType="short"
              onBlur={handleBlur}
              value={values.country}
            />
            <Field.Text
              as={Input}
              name="postalCode"
              className="col-span-2"
              placeholder="Enter postal code"
            />
          </div>
          <Field.Text as={Input} name="city" placeholder="City" />
          {error && <Alert type="danger">{error}</Alert>}
          <button
            className="mt-2 sm:w-auto btn-lg btn-blue"
            disabled={isSubmitting}
            type="button"
            onClick={handleSubmit}
          >
            Save
          </button>
        </Form>
      )}
    </Formik>
  );
}
