import AddPaymentMethodResult from "./AddPaymentMethodResult";
import AddPaymentMethod from "./AddPaymentMethod";
import AddAddress from "./AddAddress";
import { loadStripe } from "@stripe/stripe-js";
import useApiResource from "hooks/useApiResource";
import { Elements } from "@stripe/react-stripe-js";
import { Routes, Route, useNavigate } from "react-router-dom";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export default function BillingSetupFlow() {
  const navigate = useNavigate();

  const { data, isFetching, error } = useApiResource(
    "/rest/v1/stripe/setup-intent"
  );
  if (isFetching) return null;
  if (error) throw error;
  const options = {
    clientSecret: data.clientSecret,
    appearance: {
      /*...*/
    },
  };
  return (
    <Elements stripe={stripePromise} options={options}>
      <Routes>
        <Route
          path="payment-method"
          element={
            <AddPaymentMethod
              returnUrl={`${document.location.origin}/billing-setup/result?redirect=/settings/billing`}
            />
          }
        />
        <Route path="result" element={<AddPaymentMethodResult />} />
        <Route
          path="address"
          element={
            <AddAddress onSubmit={() => navigate("/settings/billing")} />
          }
        />
      </Routes>
    </Elements>
  );
}
