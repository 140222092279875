import Overview from "./Overview";
import Secrets from "./Secrets";
import Settings from "./Settings";

const routes = [
  {
    title: "Overview",
    path: "",
    component: Overview,
  },
  {
    title: "Secrets",
    path: "secrets",
    component: Secrets,
  },
  {
    title: "Settings",
    path: "settings",
    component: Settings,
  },
];

export default routes;
