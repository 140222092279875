import { useState, useRef, useMemo } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Menu } from "components"
import { FiChevronDown, FiCheck } from "react-icons/fi"
import { SiGo, SiPython, SiTypescript, SiTerraform, SiPulumi } from "react-icons/si"
import { usePopper } from 'react-popper';
import { ReactComponent as CrossplaneColor } from 'images/crossplane-icon-color.svg';
import { ReactComponent as CrossplaneGrayscale } from 'images/crossplane-icon-black.svg';
import { ReactComponent as TerraformColor } from 'images/terraform-icon-color.svg';
import { ReactComponent as PythonColor } from 'images/python-icon-color.svg';
import { ReactComponent as TypescriptColor } from 'images/typescript-icon-color.svg';
import { ReactComponent as GolangColor } from 'images/golang-icon-color.svg';
import SyntaxHighlighter from "react-syntax-highlighter";
import { stackoverflowLight as syntaxStyle } from "react-syntax-highlighter/dist/esm/styles/hljs";
import cn from "classnames"

function HoverButton({popover, disabled, children}) {
  const buttonRef = useRef();
  const popperRef = useRef();
  const [isVisible, setVisibility] = useState(false);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes } = usePopper(buttonRef.current, popperRef.current, {
    placement: "top",
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      { name: 'offset', options: { offset: [10, 10], }, },
      { name: 'preventOverflow', options: { altAxis: false, padding: 10 }, },
    ]
  });
  return (
    <>
      {isVisible && !disabled && (
        <div
          ref={popperRef}
          style={styles.popper}
          {...attributes.popper}
        >
          {popover}
          <div ref={setArrowElement} style={styles.arrow} className="absolute" />
        </div>
      )}
      <button
        type="button"
        ref={buttonRef}
        onMouseEnter={() => setVisibility(true)}
        onMouseLeave={() => setVisibility(false)}
      >
        {children}
      </button>
    </>
  );
}

function ManifestCopyButtonInner({ open, terraformText, crossplaneText, pulumiTypescriptText, pulumiPythonText, pulumiGoText }) {
  const [manifestType, setManifestType] = useState("terraform");
  const text = useMemo(() => {
    if (manifestType === "terraform") {
      return terraformText;
    } else if (manifestType === "crossplane") {
      return crossplaneText;
    } else if (manifestType === "pulumi-ts") {
      return pulumiTypescriptText;
    } else if (manifestType === "pulumi-python") {
      return pulumiPythonText;
    } else if (manifestType === "pulumi-go") {
      return pulumiGoText;
    }
  }, [manifestType, crossplaneText, pulumiGoText, pulumiPythonText, pulumiTypescriptText, terraformText]);
  const [icon, title, syntax] = useMemo(() => {
    if (manifestType === "terraform") {
      return [<SiTerraform className="mr-1.5 inline-block" />, "Terraform", "hcl"];
    } else if (manifestType === "crossplane") {
      return [<CrossplaneGrayscale style={{fill: "white"}} />, "Crossplane", "yaml"];
    } else if (manifestType === "pulumi-ts") {
      return [<SiTypescript className="mr-1.5 inline-block" />, "Typescript (Pulumi)", "typescript"];
    } else if (manifestType === "pulumi-python") {
      return [<SiPython className="mr-1.5 inline-block" />, "Python (Pulumi)", "python"];
    } else if (manifestType === "pulumi-go") {
      return [<SiGo className="mr-1.5 inline-block" />, "Go (Pulumi)", "go"];
    }
  }, [manifestType]);
  const [hasCopied, setHasCopied] = useState();
  const handleCopy = () => {
    setHasCopied(true);
  };
  const handleSetManifestType = (type) => {
    setHasCopied(false);
    setManifestType(type);
  };

  return (
    <>
      <HoverButton disabled={open} popover={<div
        className={cn(
          "rounded shadow-lg text-xs bg-white text-gray-700"
        )}
      >
        {hasCopied
          ? <div className="bg-green-400 text-2xs font-medium text-white uppercase tracking-wide py-1.5 px-1 rounded-t border border-gray-300">Copied <FiCheck className="inline-block"/></div>
          : <div className="bg-gray-300 text-2xs font-medium text-gray-600 uppercase tracking-wide py-1.5 px-1 rounded-t border border-gray-300">Copy {title} manifest</div>}
        <div className="font-mono text-2xs overflow-hidden whitespace-pre border-b border-x rounded-b border-gray-300">
          <SyntaxHighlighter language={syntax} style={syntaxStyle}>
            {text}
          </SyntaxHighlighter>
        </div>
      </div>}>
        <CopyToClipboard text={text} onCopy={handleCopy}>
          <span type="button"
            className={cn(manifestType === "crossplane" && "bg-[#183d54]", manifestType.startsWith("pulumi-") && "bg-[#000]", manifestType === "terraform" && "bg-[#5c4fe5]", "inline-flex justify-center items-center rounded-l-md px-2 py-1.5 text-sm font-medium text-white hover:active:bg-opacity-80 hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 h-full")}
          ><div className="w-5 h-5 flex items-center justify-center mr-1">
            {icon}
          </div>
            Copy
          </span>
        </CopyToClipboard>
      </HoverButton>
      <Menu.Button className={cn(manifestType === "crossplane" && "bg-[#183d54]", manifestType.startsWith("pulumi-") && "bg-[#000]", manifestType === "terraform" && "bg-[#5c4fe5]", "inline-flex justify-center items-center rounded-r-md pr-1.5 py-1.5 text-sm font-medium text-white hover:active:bg-opacity-80 hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75")}>
        <span className="pl-1.5 h-5/6 border-l opacity-80"></span>
        <FiChevronDown />
      </Menu.Button>
      <Menu.Items className="-top-8 transform -translate-y-full absolute right-0 origin-top-right">
        <div className="py-1 text-gray-700">
          <Menu.ButtonItem onClick={() => handleSetManifestType("terraform")} as="button" buttonType="button" className="px-1 py-1 w-full flex rounded items-center">
            <div className="h-5 w-5 mr-1 felx"><TerraformColor className="scale-[85%]" /></div>
            <span>Terraform</span>
          </Menu.ButtonItem>
          <Menu.ButtonItem onClick={() => handleSetManifestType("pulumi-ts")} as="button" buttonType="button" className="px-1 py-1 w-full flex rounded items-center">
            <div className="h-5 w-5 mr-1 inline-flex justify-center items-center"><TypescriptColor /></div>
            <span>Pulumi Typescript</span>
          </Menu.ButtonItem>
          <Menu.ButtonItem onClick={() => handleSetManifestType("pulumi-python")} as="button" buttonType="button" className="px-1 py-1 w-full flex rounded items-center">
            <div className="h-5 w-5 mr-1 inline-flex justify-center items-center"><PythonColor /></div>
            <span>Pulumi Python</span>
          </Menu.ButtonItem>
          <Menu.ButtonItem onClick={() => handleSetManifestType("pulumi-go")} as="button" buttonType="button" className="px-1 py-1 w-full flex rounded items-center">
            <div className="h-5 w-5 mr-1 inline-flex justify-center items-center"><GolangColor /></div>
            <span>Pulumi Go</span>
          </Menu.ButtonItem>
          <Menu.ButtonItem disabled as="button" buttonType="button" className="px-1 py-1 w-full flex rounded items-center">
            <div className="h-5 w-5 mr-1 inline-flex justify-center items-center"><CrossplaneColor /></div>
            Crossplane
          </Menu.ButtonItem>
        </div>
      </Menu.Items>
    </>
  )
}

export default function ManifestCopyButton({ terraformText, crossplaneText, pulumiTypescriptText, pulumiPythonText, pulumiGoText }) {
  return (
    <Menu as="div" className="relative flex">
      {({ open }) => (
        <ManifestCopyButtonInner open={open} terraformText={terraformText} crossplaneText={crossplaneText} pulumiTypescriptText={pulumiTypescriptText} pulumiPythonText={pulumiPythonText} pulumiGoText={pulumiGoText} />
      )}
    </Menu>
  )
}
