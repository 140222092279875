import { useState, useMemo } from "react";
import { useApiResource, usePrettyTimeElapsedSince } from "hooks";
import { Link } from "react-router-dom";
import Ansi from "ansi-to-react";
import { useParams } from "react-router-dom";
import { ProjectRunStateBadge } from "components";
import {
  IoChevronForward,
  IoChevronDown,
  IoLogoGithub,
  IoGitCommit,
  IoCheckmarkCircleSharp,
  IoAlertCircleSharp,
} from "react-icons/io5";
import { Oval } from "react-loader-spinner";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneLight as syntaxStyle } from "react-syntax-highlighter/dist/esm/styles/hljs";
import ProjectLayout from "./ProjectLayout";

function LogCell({ runId, test, isOpen }) {
  const {
    data: testDetails,
    isFetching,
    error,
  } = useApiResource(`/rest/v1/project/run/${runId}/test/${test.index}`);
  return (
    isOpen && (
      <td colSpan="3" className="px-4 py-3">
        <code>
          {(isFetching && (
            <span className="flex justify-center">
              <Oval height={35} width={35} />
            </span>
          )) ||
            error?.toString() ||
            testDetails?.log ||
            testDetails?.containerMessage || (
              <span className="font-sans text-gray-600">No logs found</span>
            )}
        </code>
      </td>
    )
  );
}
function TestRow({ runId, test }) {
  const [isOpen, setIsOpen] = useState(null);
  return (
    <>
      <tr key={test.index} className="bg-gray-100">
        <td className="py-3 px-4">
          <div className="flex items-center">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="text-xs text-gray-500 font-medium tracking-wider"
            >
              {isOpen ? (
                <IoChevronDown size="1.3rem" />
              ) : (
                <IoChevronForward size="1.3rem" />
              )}
            </button>
          </div>
        </td>
        <td className="py-3 px-4">
          <div className="leading-5">
            <div className="text-xs text-gray-500 font-medium tracking-wider">
              IMAGE
            </div>
            {test.image}
          </div>
        </td>
        <td className="py-3 px-4">
          <div className="leading-5">
            <div className="text-xs text-gray-500 font-medium tracking-wider">
              COMMAND
            </div>
            <code>{test.command}</code>
          </div>
        </td>
        <td className="py-3 px-4 w-full">
          <div className="flex justify-end">
            {test.state !== "PENDING" && <div>{test.duration}</div>}
            {test.state === "PENDING" && <Oval width={20} height={20} />}
            {test.state === "SUCCESS" && (
              <IoCheckmarkCircleSharp
                size="1.5rem"
                className="text-emerald-500"
              />
            )}
            {test.state === "FAILED" && (
              <IoAlertCircleSharp size="1.5rem" className="text-red-500" />
            )}
          </div>
        </td>
      </tr>
      {isOpen != null && (
        <tr>
          <LogCell runId={runId} test={test} isOpen={isOpen} />
        </tr>
      )}
    </>
  );
}

function BuildLogPanel({ log }) {
  const [isShown, setIsShown] = useState(false);
  return (
    <div className="border rounded-lg overflow-hidden bg-gray-100">
      {isShown ? (
        <div className="px-4 py-3 whitespace-pre">
          <Ansi>{log}</Ansi>
        </div>
      ) : (
        <div className="flex justify-center">
          <button
            onClick={() => setIsShown(true)}
            className="px-4 py-3 text-sm text-gray-600 underline"
          >
            Show logs
          </button>
        </div>
      )}
    </div>
  );
}

function decodeBase64(str) {
  return decodeURIComponent(escape(window.atob(str)));
}

function Run({ run }) {
  const { runId } = useParams();
  const createdSince = usePrettyTimeElapsedSince(run.createdAt);
  const yamlConfigString = useMemo(
    () =>
      run?.symConfigContentBase64 != null &&
      decodeBase64(run?.symConfigContentBase64),
    [run?.symConfigContentBase64]
  );
  return (
    <section className="my-5">
      <div className="grid grid-cols-2 gap-3 max-w-2xl">
        <div>
          <div className="text-xs text-gray-500 font-medium tracking-wider">
            BRANCH
          </div>
          <div className="text-gray-700">
            <IoLogoGithub className="inline-block" /> {run.gitRef}
          </div>
        </div>
        <div>
          <div className="text-xs text-gray-500 font-medium tracking-wider">
            COMMIT
          </div>
          <div className="text-gray-700">
            <IoGitCommit className="inline-block" /> {run.pullRequest.issueName}
          </div>
        </div>
        <div>
          <div className="text-xs text-gray-500 font-medium tracking-wider">
            STATUS
          </div>
          <div className="text-gray-700">
            <ProjectRunStateBadge state={run.state} />
          </div>
        </div>
        <div>
          <div className="text-xs text-gray-500 font-medium tracking-wider">
            CREATED
          </div>
          <div className="text-gray-700">{createdSince}</div>
        </div>
        <div>
          <div className="text-xs text-gray-500 font-medium tracking-wider">
            AUTHOR
          </div>
          <div className="text-gray-700">{run.user}</div>
        </div>
      </div>
      {run.error && (
        <div className="">
          <h2 className="text-2xl mb-2 mt-4 font-semibold text-gray-600">
            Error ❌
          </h2>
          <div className="border rounded-lg overflow-hidden bg-gray-100">
            <div className="px-4 py-3 whitespace-pre">
              <Ansi>{run.error}</Ansi>
            </div>
          </div>
        </div>
      )}
      {run.buildLog && (
        <div className="">
          <h2 className="text-2xl mb-2 mt-4 font-semibold text-gray-600">
            Build
          </h2>
          <BuildLogPanel log={run.buildLog} />
        </div>
      )}
      <div className="">
        <h2 className="text-2xl mb-2 mt-4 font-semibold text-gray-600">
          Tests
        </h2>
        <div className="border rounded-lg overflow-hidden">
          <table className="divide-y w-full">
            <tbody>
              {run.integrationTests.map((x) => (
                <TestRow key={x.index} runId={runId} test={x} />
              ))}
              {!run?.integrationTests?.length && (
                <tr>
                  <td
                    colSpan="3"
                    className="py-3 flex justify-center text-gray-600"
                  >
                    {run.state === "COMPLETED" || run.state === "FAILED"
                      ? "No tests found."
                      : "Initializing..."}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="">
          <h2 className="text-2xl mb-2 mt-4 font-semibold text-gray-600">
            Config
          </h2>
          <div
            style={{ backgroundColor: "rgb(250, 250, 250)" }}
            className="border rounded-lg overflow-hidden py-3 px-4"
          >
            <SyntaxHighlighter language="yaml" style={syntaxStyle}>
              {yamlConfigString || "N/A"}
            </SyntaxHighlighter>
          </div>
        </div>
      </div>
    </section>
  );
}

export default function RunLayout({ project }) {
  const { runId } = useParams();

  const { data: run, error } = useApiResource(`/rest/v1/project/run/${runId}`);
  if (error) throw error;
  const breadcrumbs = run && [
    <Link to={`/projects/${project.name}/preview/${run.pullRequest.id}`}>
      #{run.pullRequest.issueId}
    </Link>,
    run.gitSha.slice(0, 7),
  ];

  return (
    <ProjectLayout breadcrumbs={breadcrumbs} project={project}>
      {run && <Run run={run} />}
    </ProjectLayout>
  );
}
