import { useState } from "react";
import { saveAs } from "file-saver";
import {
  useAxios,
  usePaginatedApiResource,
  usePrettyTimeElapsedSince,
} from "hooks";
import { useParams } from "react-router-dom";
import { Badge, Table } from "components";
import { IoGitBranch } from "react-icons/io5";
import { Link } from "react-router-dom";
import ProjectLayout from "./ProjectLayout";
import { Pagination, ProjectRunStateBadge } from "components";
import { IoCloudDownloadOutline } from "react-icons/io5";

const PULL_REQUESTS_PER_PAGE = 10;
const RUNS_PER_PAGE = 10;

function PrStateBadge({ state }) {
  if (state === "OPEN") return <Badge type="success">Open</Badge>;
  else if (state === "CLOSED") return <Badge type="danger">Closed</Badge>;
  else if (state === "MERGED")
    return <Badge className="bg-purple-100 text-purple-500">Merged</Badge>;
  else throw Error("Invalid state");
}

function PullRequestRow({ pullRequest }) {
  const { projectName } = useParams();
  const axios = useAxios();
  const createdSince = usePrettyTimeElapsedSince(pullRequest.createdAt);
  const handleDownloadConfig = async (clusterName) => {
    const { data } = await axios.get(
      `/rest/v1/cluster/${clusterName}/identity`
    );
    var blob = new Blob([data.kubeConfig], {
      type: "text/plain;charset=utf-8",
    });

    saveAs(blob, "config");
  };

  return (
    <Table.Row key={pullRequest.id}>
      <Table.Cell className="bg-gray-50 w-[20%]">
        <div className="text-gray-600 font-medium">
          <Link to={`/projects/${projectName}/preview/${pullRequest.id}`}>
            {pullRequest.issueName}
          </Link>
        </div>
        <div className="flex items-center">
          <IoGitBranch className="inline-block" /> {pullRequest.gitRef}
        </div>
      </Table.Cell>
      <Table.Cell className="bg-gray-50">
        <div className="text-gray-600">
          <Link to={`/clusters/${pullRequest.previewClusterName}`}>
            {pullRequest.previewClusterName}{" "}
          </Link>
          {pullRequest.previewClusterId && (
            <button
              onClick={() =>
                handleDownloadConfig(pullRequest.previewClusterName)
              }
              className="text-xs text-blue-500"
            >
              <IoCloudDownloadOutline className="inline-block" />
            </button>
          )}
        </div>
        <div className="text-gray-600">
          <PrStateBadge state={pullRequest.pullRequestState} />
        </div>
      </Table.Cell>
      <Table.Cell className="text-right bg-gray-50">
        <span className="text-gray-600">{createdSince}</span>
      </Table.Cell>
    </Table.Row>
  );
}

function RunRow({ run }) {
  const { projectName } = useParams();
  const createdSince = usePrettyTimeElapsedSince(run.createdAt);

  return (
    <Table.Row key={run.id}>
      <Table.Cell className="bg-gray-50 w-[20%]">
        <Link to={`/projects/${projectName}/run/${run.id}`}>
          <div className="text-gray-600 font-medium">{run.commitName}</div>
        </Link>
        <div className="flex items-center">
          <IoGitBranch className="inline-block" /> {run.gitRef}
        </div>
      </Table.Cell>
      <Table.Cell className="bg-gray-50">
        <div className="font-bold">
          <ProjectRunStateBadge state={run.state} />
        </div>
        <div className="text-gray-600"></div>
      </Table.Cell>
      <Table.Cell className="text-right bg-gray-50">
        <span className="text-gray-600">
          {createdSince} by {run.user}
        </span>
        <img
          className="h-8 w-8 rounded shadow ml-3 inline-block"
          src={run.userAvatarUrl}
          alt={`${run.user} avatar`}
        />
      </Table.Cell>
    </Table.Row>
  );
}

function Overview() {
  const { projectName } = useParams();
  const [pullRequestPage, setPullRequestPage] = useState(0);
  const [runPage, setRunPage] = useState(0);
  const {
    content: pullRequests,
    totalElements: pullRequestElements,
    isFetching: isFetchingPrs,
    error: errorPrs,
  } = usePaginatedApiResource(
    `/rest/v1/project/${projectName}/pull-request`,
    pullRequestPage,
    { pageSize: PULL_REQUESTS_PER_PAGE }
  );

  const {
    content: deployments,
    totalElements: runElements,
    isFetching,
    error,
  } = usePaginatedApiResource(`/rest/v1/project/${projectName}/run`, runPage, {
    pageSize: RUNS_PER_PAGE,
  });
  if (isFetching || isFetchingPrs) return null;
  if (error || errorPrs) throw error || errorPrs;

  return (
    <section className="my-5">
      <h1 className="text-xl font-bold text-gray-600 mt-6 mb-4">
        Preview branches
      </h1>
      <Table>
        <Table.Body>
          {pullRequests.map((x) => (
            <PullRequestRow key={x.id} pullRequest={x} />
          ))}
          {!pullRequests?.length && !errorPrs && (
            <Table.ContentRow>
              <div className="py-3 flex justify-center">
                No preview environments found found. Go make a pull request!
              </div>
            </Table.ContentRow>
          )}
        </Table.Body>
      </Table>
      <Pagination
        resultsPerPage={PULL_REQUESTS_PER_PAGE}
        totalResults={pullRequestElements}
        currentPage={pullRequestPage}
        onChange={setPullRequestPage}
      />
      <h1 className="text-xl font-bold text-gray-600 mt-6 mb-4">Deployments</h1>
      <Table>
        <Table.Body>
          {deployments?.map((x) => (
            <RunRow run={x} key={x.id} />
          ))}
          {!deployments?.length && !error && (
            <Table.ContentRow>
              <div className="py-3 flex justify-center">
                No deployments found.
              </div>
            </Table.ContentRow>
          )}
        </Table.Body>
      </Table>
      <Pagination
        resultsPerPage={RUNS_PER_PAGE}
        totalResults={runElements}
        currentPage={runPage}
        onChange={setRunPage}
      />
    </section>
  );
}

export default function OverviewLayout({ project }) {
  return (
    <ProjectLayout project={project}>
      <Overview />
    </ProjectLayout>
  );
}
