import cn from "classnames";

export default function Badge(props) {
  const { className, children, type = "primary" } = props;

  const typeStyle = {
    success: "bg-green-100 text-green-700",
    danger: "bg-red-100 text-red-500",
    warning: "bg-orange-100 text-orange-500",
    neutral: "bg-gray-100 text-gray-500",
    primary: "bg-blue-100 text-blue-500",
  };

  return (
    <span
      className={cn(
        typeStyle[type],
        "rounded text-xs py-0.5 px-1 font-semibold lowercase",
        className
      )}
    >
      {children}
    </span>
  );
}
