import { useEffect } from "react";
import useTeam from "hooks/useTeam";

export default function useFeatureGate(featureGate) {
  const team = useTeam();
  const gate = team?.user?.featureGates?.[featureGate];
  useEffect(() => {
    if (gate == null) {
      console.warn(
        `Feature gate ${featureGate} not found and has possibly been removed`
      );
    }
  }, [gate, featureGate]);

  if (!team?.user) {
    console.warn(
      `Feature gate hook initialized without a team context set, disabling gate`
    );
    return {
      enabled: false,
    };
  }

  return {
    enabled: gate ? gate?.enabled : true,
  };
}
