import { useState, useEffect } from "react";
import { useSession, useAxios, useTeam, useApiResource } from "hooks";
import { Table } from "components";
import { useNavigate } from "react-router-dom";
import { IoLogoGithub } from "react-icons/io5";

export default function AddProject() {
  const session = useSession();
  const {
    data: repositories,
    isFetching: isFetchingRepositories,
    error: errorRepositories,
  } = useApiResource("/rest/v1/github/installation/repository");
  const { team } = useTeam();
  const [statePayload, setStatePayload] = useState();
  useEffect(() => {
    async function fetchToken() {
      const token = session.id
      setStatePayload(JSON.stringify({ teamId: team.id, token }));
    }
    fetchToken();
  }, [team]);
  const axios = useAxios();
  const navigate = useNavigate();
  if (!statePayload || isFetchingRepositories) return null;
  if (errorRepositories) throw errorRepositories;

  const handleCreateProject = async (repo) => {
    const { data } = await axios.post("/rest/v1/project/github", {
      installationId: repo.installationId,
      owner: repo.owner.login,
      repository: repo.name,
    });
    navigate(`/projects/${data.name}`);
  };

  return (
    <section className="p-8 h-full">
      <h1 className="header -mt-1">Add project</h1>
      <p className="text-gray-700 mt-1 mb-3">Connect your GitHub project.</p>
      {repositories?.length > 0 ? (
        <Table className="max-w-xl">
          <Table.Head>
            <Table.Row>
              <Table.Cell>Repo</Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {repositories.map((x) => (
              <Table.Row key={x.id}>
                <Table.Cell>
                  {x.owner.login}/{x.name}
                </Table.Cell>
                <Table.Cell className="text-right">
                  <button
                    className="btn-xs btn-outline"
                    onClick={() => handleCreateProject(x)}
                  >
                    Create project
                  </button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      ) : (
        <a
          className="btn-lg bg-[#24292f] text-white flex items-center w-64 justify-between px-8"
          target="_blank"
          rel="noreferrer"
          href={`https://github.com/apps/${
            process.env.REACT_APP_GITHUB_APP || "symbiosis-projects"
          }/installations/new?state=${statePayload}`}
        >
          <IoLogoGithub size="1.2rem" /> Add GitHub integration
        </a>
      )}
    </section>
  );
}
