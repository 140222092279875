import { useState } from "react";
import cn from "classnames";

export default function Tooltip({ label, className, children }) {
  const [hoverActive, setHoverActive] = useState();
  return (
    <span className={cn(className, "relative font-normal")}>
      <span
        onMouseOver={() => setHoverActive(true)}
        onMouseLeave={() => setHoverActive(false)}
      >
        {label}
      </span>
      {hoverActive && (
        <div className="rounded p-2 bg-white whitespace-pre min-w-48 absolute shadow-lg text-xs z-10">
          {children}
        </div>
      )}
    </span>
  );
}
