export default function CursorPagination({
  canPageForward,
  canPageBackward,
  resultsPerPage,
  onPageForward,
  onPageBackward,
}) {
  return (
    <nav className="flex my-2 justify-end items-center">
      <button
        disabled={!canPageBackward}
        onClick={onPageBackward}
        className="btn-xs btn-outline mr-1"
      >
        Previous
      </button>
      <button
        disabled={!canPageForward}
        onClick={onPageForward}
        className="btn-xs btn-outline"
      >
        Next
      </button>
    </nav>
  );
}
