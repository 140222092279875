import { useMemo, useState, useEffect } from "react";
import useAxios from "hooks/useAxios";
import FullLoader from "components/FullLoader";
import { useNavigate, useSearchParams } from "react-router-dom";
import AddPaymentMethodTab from "partials/cluster/AddPaymentMethodTab";
import ClusterConfigTab from "partials/cluster/ClusterConfigTab";
import useApiResource from "hooks/useApiResource";
import AddAddress from "./BillingSetupFlow/AddAddress";

const tabs = Object.freeze({
  CLUSTER_CONFIG_TAB: 1,
  ADD_BILLING_ADDRESS_TAB: 2,
  ADD_PAYMENT_METHOD_TAB: 3,
});

export default function AddCluster({ onSubmitted }) {
  const [params] = useSearchParams();
  const redirectConfigurationString = useMemo(
    () => params.get("state"),
    [params]
  );

  const [tab, setTab] = useState(tabs.CLUSTER_CONFIG_TAB);
  const [configuration, setConfiguration] = useState();
  const [error, setError] = useState();
  const axios = useAxios();
  const navigate = useNavigate();
  const {
    data: paymentMethod,
    isFetching: isFetchingPaymentMethod,
    error: paymentMethodError,
  } = useApiResource("/rest/v1/stripe");
  if (paymentMethodError) throw paymentMethodError;
  useEffect(() => {
    // Scroll to top after changing tabs
    window.scrollTo(0, 0);
  }, [tab]);

  const handleSubmit = async (configuration) => {
    const nodes = configuration.nodes.map((x) => ({
      name: x.name,
      nodeTypeName: x.nodeTypeName,
      quantity: x.quantity,
      autoscaling: x.autoscalerEnabled
        ? {
            enabled: true,
            minSize: x.quantity,
            maxSize: x.maxQuantity,
          }
        : undefined,
    }));
    const { data } = await axios.post("/rest/v1/cluster", {
      name: configuration.clusterName.toLowerCase().trim(),
      kubeVersion: configuration.kubeVersion,
      regionName: configuration.regionName,
      isHighlyAvailable: configuration.isHighlyAvailable,
      nodes,
      configuration: {
        nginxIngress: !!configuration.enableNginxIngress,
      },
    });
    onSubmitted();
    navigate({
      pathname: `/clusters/${data.name}`,
      state: {
        isNewCluster: true,
      },
    });
  };

  // If we're redirected with a config we try submit it instantly
  useEffect(() => {
    if (redirectConfigurationString)
      handleSubmit(JSON.parse(decodeURI(redirectConfigurationString)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isFetchingPaymentMethod) return null;
  // No need to render as we'll be submitting the configuration
  if (redirectConfigurationString && !error) return <FullLoader />;

  const handleClusterConfigured = async (configuration) => {
    setConfiguration(configuration);
    if (!paymentMethod?.billingAddress)
      return setTab(tabs.ADD_BILLING_ADDRESS_TAB);
    if (!paymentMethod?.paymentMethods?.length)
      return setTab(tabs.ADD_PAYMENT_METHOD_TAB);
    try {
      await handleSubmit(configuration);
      setError(false);
    } catch (err) {
      setError(err);
    }
  };

  const handleBillingAddressAdded = async () => {
    if (!paymentMethod?.paymentMethods?.length) {
      setTab(tabs.ADD_PAYMENT_METHOD_TAB);
    } else {
      try {
        await handleSubmit(configuration);
        setError(false);
      } catch (err) {
        setError(err);
        setTab(tabs.CLUSTER_CONFIG_TAB);
      }
    }
  };

  if (tab === tabs.CLUSTER_CONFIG_TAB)
    return (
      <section className="grid p-8 h-full">
        <ClusterConfigTab
          error={error}
          clusterConfiguration={configuration}
          onSubmit={handleClusterConfigured}
        />
      </section>
    );
  else if (tab === tabs.ADD_BILLING_ADDRESS_TAB)
    return <AddAddress onSubmit={handleBillingAddressAdded} />;
  else if (tab === tabs.ADD_PAYMENT_METHOD_TAB)
    return (
      <AddPaymentMethodTab
        returnUrl={`${
          document.location.origin
        }/billing-setup/result?redirect=/clusters/new&state=${encodeURI(
          JSON.stringify(configuration)
        )}`}
      />
    );
}
