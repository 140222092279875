import { useMemo } from "react";
import { Oval } from "react-loader-spinner";
import FeatureGate from "components/FeatureGate";
import AxiosErrorAlert from "components/AxiosErrorAlert";
import * as Yup from "yup";
import useApiResource from "hooks/useApiResource";
import { FiPlus, FiTrash2 } from "react-icons/fi";
import { Switch } from "@headlessui/react";
import { FullLoader, Badge, Label, Field } from "components";
import InfoTooltip from "components/InfoTooltip";
import { Formik, FieldArray, Form } from "formik";
import NodeTypeSelector from "components/NodeTypeSelector";
import cn from "classnames";
import ManifestCopyButton from "components/ManifestCopyButton";

const addClusterSchema = Yup.object().shape({
  clusterName: Yup.string()
    .min(3, "Name must be longer than 3 characters")
    .max(63, "Name can't be more than 63 characters")
    .matches(
      /^[a-z0-9]+(-[a-z0-9]+)*(\.[a-z0-9]+(-[a-z0-9]+)*)*$/,
      "Name must consist of letters and numbers divided by dashes or dots, see IETF RFC 1123"
    )
    .required("A name is required"),
  regionName: Yup.string().required("A region is required"),
  nodes: Yup.array().of(
    Yup.object().shape({
      nodeTypeName: Yup.string().required("Node type is required"),
      quantity: Yup.number()
        .min(1, "Size has to be positive")
        .max(20, "Contact support to enable larger node pools")
        .required("Size is required"),
    })
  ),
});

const alphanumericals = "0123456789abcdefghijklmnopqrstuvwxyz";
function randomString(length) {
  var result = "";
  for (var i = length; i > 0; --i)
    result +=
      alphanumericals[Math.floor(Math.random() * alphanumericals.length)];
  return result;
}

const hclifyString = str => str.replace("-", "_");

function ManifestCopyWrapper({
  values
}) {

  const pulumiGoText = useMemo(() => `package main

import (
	"github.com/pulumi/pulumi/sdk/v3/go/pulumi"
	"github.com/symbiosis-cloud/pulumi-symbiosis/sdk/go/symbiosis"
)

func main() {
	pulumi.Run(func(ctx *pulumi.Context) error {
		cluster, err := symbiosis.NewCluster(ctx, "${values.clusterName}", &symbiosis.ClusterArgs{
			Region: pulumi.String("${values.regionName}"),
			IsHighlyAvailable: pulumi.String("${values.isHighlyAvailable}"),
			KubeVersion: pulumi.String("${values.kubeVersion}"),
		})
		if err != nil {
			return err
		}
${values.nodes.map(pool => `        _, err = symbiosis.NewNodePool(ctx, "${pool.name}", &symbiosis.NodePoolArgs{
			Cluster:  cluster.Name,
			NodeType: pulumi.String("${pool.nodeTypeName}"),
      ${pool.autoscalerEnabled ? `Quantity: pulumi.Int(${pool.quantity}),
            Autoscaling: &symbiosis.NodePoolAutoscalingArgs{
                Enabled: true
                MinSize: ${pool.quantity}
                MaxSize: ${pool.maxQuantity}
            }` : `      Quantity: pulumi.Int(${pool.quantity})`}
		})
		if err != nil {
			return err
		}`).join("\n\n")}
		return nil
	})
}`, [values.clusterName, values.isHighlyAvailable, values.kubeVersion, values.nodes, values.regionName])

  const pulumiPythonText = useMemo(() => `import pulumi
import symbiosis_pulumi as symbiosis

cluster = symbiosis.Cluster("${values.clusterName}",
    region="${values.regionName}",
    is_highly_available=${values.isHighlyAvailable ? "True" : "False"},
    kube_version="${values.kubeVersion}")

${values.nodes.map(pool => `${hclifyString(pool.name)} = symbiosis.NodePool("${pool.name}",
    cluster=cluster.name,
    node_type="${pool.nodeTypeName}",
    ${pool.autoscalerEnabled ? `quantity=${pool.quantity},
    autoscaling=symbiosis.NodePoolAutoscalingArgs(enabled=True, min_size=${pool.quantity}, max_size=${pool.maxQuantity})` : `quantity=${pool.quantity}`})`).join("\n\n")}`, [values.clusterName, values.isHighlyAvailable, values.kubeVersion, values.nodes, values.regionName])

  const pulumiTSText = useMemo(() => `import * as pulumi from "@pulumi/pulumi";
import * as symbiosis from "@symbiosis/symbiosis-pulumi";

const cluster = new symbiosis.Cluster("${values.clusterName}", {
  region: "${values.regionName}",
  kubeVersion: "${values.kubeVersion}",
  isHighlyAvailable: ${values.isHighlyAvailable ? "true": "false"}
});

${values.nodes.map(pool => `const ${hclifyString(pool.name)} = new symbiosis.NodePool("${pool.name}", {
  cluster: cluster.name,
  nodeType: "${pool.nodeTypeName}",
  ${pool.autoscalerEnabled ? `quantity: ${pool.quantity},
  autoscaling: {
    enabled: true
    minSize: ${pool.quantity}
    maxSize: ${pool.maxQuantity}
  }` : `quantity: ${pool.quantity}`}
});`).join("\n\n")}`, [values.clusterName, values.isHighlyAvailable, values.kubeVersion, values.nodes, values.regionName])

  const terraformText = useMemo(() => `resource "symbiosis_cluster" "${hclifyString(values.clusterName)}" {
  name = "${values.clusterName}"
  region = "${values.regionName}"
  kube_version = "${values.kubeVersion}"
  is_highly_available = ${values.isHighlyAvailable ? "true": "false"}
}

${values.nodes.map(pool => `resource "symbiosis_node_pool" "${hclifyString(pool.name)}" {
  cluster = symbiosis_cluster.${hclifyString(values.clusterName)}.name
  name = "${pool.name}"
  node_type = "${pool.nodeTypeName}"
  ${pool.autoscalerEnabled ? `quantity = ${pool.quantity}
  autoscaling {
    enabled = true
    min_size = ${pool.quantity}
    max_size = ${pool.maxQuantity}
  }` : `quantity = ${pool.quantity}`}
}`).join("\n\n")}`, [values.clusterName, values.isHighlyAvailable, values.kubeVersion, values.nodes, values.regionName])
  return (
    <ManifestCopyButton terraformText={terraformText} pulumiTypescriptText={pulumiTSText} pulumiPythonText={pulumiPythonText} pulumiGoText={pulumiGoText} />
  );
}

export default function ClusterConfigTab({
  error,
  clusterConfiguration,
  onSubmit,
}) {
  const initialName = useMemo(
    () => "kubernetes-" + (Math.random() + 1).toString(36).substring(2, 8),
    []
  );
  const {
    data: nodeTypes,
    isFetching: isFetchingNodeTypes,
    error: errorNodeTypes,
  } = useApiResource("/rest/v1/node-type");

  const {
    data: versions,
    error: errorVersions,
    isFetching: isFetchingVersions,
  } = useApiResource("/rest/v1/cluster/version");

  const {
    data: regions,
    error: errorRegions,
    isFetching: isFetchingRegions,
  } = useApiResource("/rest/v1/region");
  if (isFetchingNodeTypes || isFetchingRegions || isFetchingVersions)
    return <FullLoader />;
  if (errorNodeTypes || errorRegions || errorVersions)
    throw errorNodeTypes || errorRegions || errorVersions;

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={addClusterSchema}
      initialValues={{
        clusterName: initialName,
        regionName: regions[0].name,
        kubeVersion: versions[0],
        isHighlyAvailable: false,
        enableNginxIngress: false, // Deprecated
        nodes: [
          {
            name: `pool-${randomString(8)}`,
            nodeTypeName: nodeTypes[0].name,
            autoscalerEnabled: false,
            quantity: 3,
            maxQuantity: 5,
          },
        ],
        ...clusterConfiguration,
      }}
      onSubmit={async (values, { setSubmitting }) => {
        await onSubmit(values);
        setSubmitting(false);
      }}
    >
      {({ values, setFieldValue, handleChange, isSubmitting }) => (
        <Form noValidate>
          <h1 className="header -mt-1 mb-3">Create a kubernetes cluster</h1>
          <hr />
          <div className="overflow-visible py-4">
            <div className="form-row">
              <span className="form-row-label">
                Cluster name{" "}
                <InfoTooltip text="The cluster name can only contain alphanumerical characters (a to z, 0 to 9) separated by dots or dashes. For more information see IETF RFC 1123." />
              </span>
              <Field.Text
                autoComplete="off"
                name="clusterName"
                className="form-row-input max-w-sm"
              />
            </div>
            <div className="form-row">
              <span className="form-row-label">Region</span>
              <span className="col-span-4 lg:col-span-3">
                <div className="w-32">
                  <Field.Listbox
                    options={regions?.map((r) => ({
                      value: r.name,
                      element: r.name,
                    }))}
                    name="regionName"
                  />
                </div>
              </span>
            </div>
            <div className="form-row">
              <span className="form-row-label">Kubernetes Version</span>
              <span className="col-span-4 lg:col-span-3">
                <div className="w-32">
                  <Field.Listbox
                    options={versions?.map((v) => ({ value: v, element: v }))}
                    name="kubeVersion"
                  />
                </div>
              </span>
            </div>
            <hr className="form-row-divider" />
            <div className="grid grid-cols-7 py-3">
              <span className="form-row-label my-6">Node pools</span>
              <FieldArray name="nodes">
                {({ remove, push }) => (
                  <div className="col-span-4 max-w-sm">
                    {values.nodes.map((node, index) => (
                      <div key={index} className="form-row-input relative">
                        {index > 0 && <hr className="my-4" />}
                        <div className="py-0.5 grid grid-cols-2 gap-3">
                          <Label>
                            <span className="text-gray-700 font-medium">
                              Pool name
                              {index > 0 && (
                                <button
                                  type="button"
                                  title="Remove node pool"
                                  className="ml-2 text-xs inline-block underline"
                                  onClick={() => remove(index)}
                                >
                                  remove{" "}
                                  <FiTrash2
                                    size="0.6rem"
                                    className="inline-block"
                                  />
                                </button>
                              )}
                            </span>
                            <div className="mt-0.5">
                              <Field.Text
                                name={`nodes.${index}.name`}
                                className="min-h-[3rem]"
                              />
                            </div>
                          </Label>
                          <Label>
                            <span className="text-gray-700 font-medium">
                              Node Type
                            </span>
                            <div className="mt-0.5">
                              <NodeTypeSelector
                                name={`nodes.${index}.nodeTypeName`}
                                nodeTypes={nodeTypes}
                                onChange={handleChange}
                              />
                            </div>
                          </Label>
                        </div>
                        <div className="py-0.5 grid grid-cols-2 gap-3">
                          <Label className="flex-1">
                            <span className="text-gray-700 font-medium">
                              {node.autoscalerEnabled ? "Min size" : "Size"}
                            </span>
                            <div className="flex justify-between mt-0.5">
                              <Field.NumberStepper
                                min={node.autoscalerEnabled ? 0 : 1}
                                max={10}
                                name={`nodes.${index}.quantity`}
                                className="w-full"
                              />
                            </div>
                          </Label>
                          <Label
                            className={cn(
                              !node.autoscalerEnabled && "hidden",
                              "flex-1"
                            )}
                          >
                            <span className="text-gray-700 font-medium">
                              Max size
                            </span>
                            <div className="flex justify-between mt-0.5">
                              <Field.NumberStepper
                                min={Math.max(node.quantity, 1)}
                                max={10}
                                name={`nodes.${index}.maxQuantity`}
                                className="w-full"
                              />
                            </div>
                          </Label>
                        </div>
                        <div className="py-1.5 flex items-center">
                          <span className="text-gray-700 text-sm font-medium mr-3">
                            Autoscaling enabled
                          </span>
                          <Field.RadioButton
                            name={`nodes.${index}.autoscalerEnabled`}
                            className=""
                          />
                        </div>
                      </div>
                    ))}
                    <button
                      className="btn-xs btn-outline w-full mt-3 flex justify-center items-center"
                      type="button"
                      onClick={() =>
                        push({
                          name: `pool-${randomString(8)}`,
                          nodeTypeName: nodeTypes[0].name,
                          autoscalerEnabled: false,
                          quantity: 1,
                          maxQuantity: 5,
                        })
                      }
                    >
                      <FiPlus className="inline-block mr-0.5" />
                      Add pool
                    </button>
                  </div>
                )}
              </FieldArray>
            </div>
            <hr className="form-row-divider" />
            <FeatureGate name="HIGHLY_AVAILABLE_CONTROL_PLANES">
              <div className="form-row">
                <span className="form-row-label">Configuration</span>
                <div className="form-row-input">
                  <div className="">
                    <Label radio className="my-1.5 cursor-pointer">
                      <Switch
                        checked={values.isHighlyAvailable}
                        onChange={() =>
                          setFieldValue(
                            "isHighlyAvailable",
                            !values.isHighlyAvailable
                          )
                        }
                        className={`${
                          values.isHighlyAvailable
                            ? "bg-blue-500"
                            : "bg-gray-200"
                        } focus:outline-none focus:ring-2 relative inline-flex items-center h-6 rounded-full w-10 transition-colors duration-200 ease-in-out`}
                      >
                        <span className="sr-only">
                          Highly-available Control Plane
                        </span>
                        <span
                          className={`${
                            values.isHighlyAvailable
                              ? "translate-x-4.5"
                              : "translate-x-0.5"
                          } inline-block w-5 h-5 transform bg-white rounded-full transition duration-200 ease-in-out`}
                        />
                      </Switch>
                      <span className="ml-2 mr-1 font-medium">
                        Highly-available Control Plane (+$40/mo)
                      </span>
                      <InfoTooltip text="Highly-available control planes increases the number of control plane in order to prevent downtime caused by a single node outage." />
                      <Badge className="ml-2" type="neutral">
                        preview
                      </Badge>
                    </Label>
                  </div>
                </div>
              </div>
              <hr className="form-row-divider" />
            </FeatureGate>
            <div className="pt-2">
              <AxiosErrorAlert error={error} />
            </div>
            <div className="flex gap-2 flex-row-reverse items-center">
              <ManifestCopyWrapper values={values} />
              <button
                className="btn btn-blue flex items-center"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting && (
                  <span className="-m-1 mr-1">
                    <Oval
                      color="rgb(255, 255, 255)"
                      secondaryColor="rgb(255, 255, 255, 0.5)"
                      height="1rem"
                      width="1rem"
                    />
                  </span>
                )}
                <span>Create</span>
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
