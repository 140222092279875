import { useMemo, useCallback, useState, useEffect } from "react";
import axios from "axios";
import useAxios from "hooks/useAxios";
import useAbortController from "hooks/useAbortController";

export default function useApiResource(url) {
  const axiosInstance = useAxios();
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [isFetching, setIsFetching] = useState(true);
  const { signal } = useAbortController();
  const stack = useMemo(() => new Error().stack, []);

  const fetchResource = useCallback(() => {
    axiosInstance
      .get(url, { signal })
      .then(({ data }) => {
        if (signal.aborted) return;
        setData(data);
        setError();
        setIsFetching(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          err.stack = stack;
          setError(err);
          setIsFetching(false);
        }
      });
  }, [axiosInstance, url, signal, stack]);

  useEffect(() => {
    fetchResource();
  }, [url, fetchResource]);

  return {
    data,
    isFetching,
    error,
    refresh: () => fetchResource(),
  };
}
