import { Link, useMatch, useParams, Navigate } from "react-router-dom";
import cn from "classnames";
import { IoChevronForward } from "react-icons/io5";
import routes from "./routes";

function TabItem({ title, url, path }) {
  const match = !!useMatch(url);
  return (
    <li className="pb-3 mr-6 last:mr-0">
      <Link
        className={cn(
          match
            ? "text-blue-500"
            : "text-gray-500 focus:text-blue-500 focus:outline-none hover:text-blue-500",
          "py-3 whitespace-nowrap font-light"
        )}
        to={path}
      >
        {title}
      </Link>
    </li>
  );
}

export default function ProjectLayout({ breadcrumbs, project, children }) {
  const { projectName } = useParams();

  if (!project) return <Navigate to="/projects/new" />;

  return (
    <section className="p-5 h-full">
      <h1 className="header my-2">
        <Link to={`/projects/${projectName}`}>{project.name}</Link>
        {breadcrumbs?.map((x) => (
          <span key={x}>
            <IoChevronForward className="inline-block" />
            {x}
          </span>
        ))}
      </h1>
      <nav className="border-b border-gray-200">
        <ul className="text-sm font-medium flex flex-nowrap">
          {routes.map((x) => (
            <TabItem
              key={x.path}
              url={["projects", projectName, x.path].filter(Boolean).join("/")}
              path={["/projects", projectName, x.path]
                .filter(Boolean)
                .join("/")}
              title={x.title}
            />
          ))}
        </ul>
      </nav>
      {children}
    </section>
  );
}
