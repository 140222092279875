import * as Yup from "yup";
import { useState } from "react";
import { AxiosErrorAlert, Dialog, Table, Field } from "components";
import useTeam from "hooks/useTeam";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import useAxios from "hooks/useAxios";
import AcceptCancelDialog from "partials/AcceptCancelDialog";
import useApiResource from "hooks/useApiResource";

const changeTeamNameSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Name must be longer than 3 characters")
    .max(255, "Name can't be more than 255 characters")
    .required("Please specify a name"),
});

const requestLimitIncreaseSchema = Yup.object().shape({
  additionalQuantity: Yup.number()
    .positive("Requested additional capacity must be positive")
    .max(100, "Capacity can only be requested in max 100 units at a time")
    .required(
      "Please specify how many units you wish to have your limit increased by"
    ),
  explanation: Yup.string().required(
    "Please specify the reason for requesting an increase in your service limits"
  ),
  publicUrl: Yup.string().required(
    "Please supply an URL to a profile page or website to verify your authenticity"
  ),
});

function prettyServiceType(type) {
  if (type === "CLUSTER") {
    return "Clusters";
  } else if (type === "LOAD_BALANCER") {
    return "Load Balancers";
  } else if (type === "NODE") {
    return "Nodes";
  } else if (type === "VOLUME") {
    return "Volumes";
  }
  return "Unknown type";
}

const requestQuotaOptions = Object.freeze([
  { value: "NODE", element: "Nodes" },
  { value: "CLUSTER", element: "Clusters" },
  { value: "LOAD_BALANCER", element: "Load Balancers" },
  { value: "VOLUME", element: "Volumes" },
]);

function RequestIncreasedLimitsDialog({ isOpen, onClose }) {
  const axios = useAxios();
  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={requestLimitIncreaseSchema}
      initialValues={{
        serviceType: "NODE",
        additionalQuantity: "0",
        explanation: "",
        publicUrl: "",
      }}
      onSubmit={async (
        { serviceType, additionalQuantity, explanation, publicUrl },
        { setStatus, setSubmitting }
      ) => {
        try {
          await axios.post("/rest/v1/team/service-limit", {
            serviceType,
            additionalQuantity,
            explanation,
            publicUrl,
          });
          onClose();
        } catch (err) {
          setStatus(err);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ status, handleSubmit, isSubmitting }) => (
        <Dialog open={isOpen} onClose={onClose}>
          <Dialog.Panel>
            <Dialog.Title>Request increased limits</Dialog.Title>
            <div>
              <Dialog.Description>
                Please fill in this form to request increased limits for your
                team.
              </Dialog.Description>
              <div className="my-4 grid grid-cols-2 gap-5">
                <div className="items-center">
                  <div className="font-bold text-xs uppercase tracking-wider text-gray-600">
                    Service
                  </div>
                  <div className="h-full mt-1">
                    <Field.Listbox
                      options={requestQuotaOptions}
                      name="serviceType"
                    />
                  </div>
                </div>
                <div className="">
                  <div className="font-bold text-xs uppercase tracking-wider text-gray-600">
                    Additional capacity
                  </div>
                  <div className="mt-1">
                    <Field.NumberStepper
                      name="additionalQuantity"
                      className="h-10"
                      min="0"
                      max="100"
                    />
                  </div>
                </div>
              </div>
              <Field.Text
                name="explanation"
                placeholder="Explanation for requested limit increase"
                className="col-span-4 my-5"
              />
              <Field.Text
                name="publicUrl"
                placeholder="Link to public webpage or profile"
                className="col-span-4 my-5"
              />
            </div>
            <AxiosErrorAlert className="-mt-1" error={status} />
            <div className="mt-1 flex justify-end">
              <button
                className="w-full sm:w-auto btn btn-outline"
                type="button"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                className="w-full ml-3 sm:w-auto btn btn-blue"
                type="submit"
                disabled={isSubmitting}
                onClick={handleSubmit}
              >
                Add
              </button>
            </div>
          </Dialog.Panel>
        </Dialog>
      )}
    </Formik>
  );
}

function ChangeTeamNameDialog({ initialName, isOpen, onClose }) {
  const axios = useAxios();
  const team = useTeam();
  const navigate = useNavigate();
  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={changeTeamNameSchema}
      initialValues={{
        name: initialName,
      }}
      onSubmit={async ({ name }, { setStatus, setSubmitting }) => {
        try {
          await axios.put(`/rest/v1/team/${team.team.id}`, {
            name,
          });
          navigate(0);
        } catch (err) {
          setStatus(err);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ values: { name }, status, handleSubmit, isSubmitting }) => (
        <Dialog open={isOpen} onClose={onClose}>
          <Dialog.Panel>
            <Dialog.Title>Edit team name</Dialog.Title>
            <div>
              <Dialog.Description>
                <b>Note:</b> Team name will be displayed together with billing
                address on your invoices.
              </Dialog.Description>
              <Field.Text name="name" className="col-span-4 my-5" />
            </div>
            <AxiosErrorAlert className="-mt-1" error={status} />
            <div className="mt-1 flex justify-end">
              <button
                className="w-full sm:w-auto btn btn-outline"
                type="button"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                className="w-full ml-3 sm:w-auto btn btn-blue"
                type="submit"
                disabled={isSubmitting || initialName === name}
                onClick={handleSubmit}
              >
                Edit
              </button>
            </div>
          </Dialog.Panel>
        </Dialog>
      )}
    </Formik>
  );
}

export default function General() {
  const [error, setError] = useState();
  const [dialogOpened, setDialogOpened] = useState();
  const [requestCapacityDialogOpen, setRequestCapacityDialogOpen] =
    useState(false);
  const axios = useAxios();
  const navigate = useNavigate();
  const team = useTeam();

  const {
    data,
    isFetching,
    error: limitsError,
  } = useApiResource("/rest/v1/team/service-limit");
  if (isFetching) return null;
  if (limitsError) throw limitsError;

  const handleDeleteTeam = async () => {
    try {
      await axios.delete(`/rest/v1/team/${team.team.id}`);
      navigate("/select-team");
    } catch (err) {
      setError(err);
    }
  };
  const handleCloseDeleteTeamModal = () => {
    setError();
    setDialogOpened();
  };

  return (
    <section className="w-full">
      <ChangeTeamNameDialog
        initialName={team.team.name}
        isOpen={dialogOpened === "editName"}
        onClose={() => setDialogOpened()}
      />
      <div className="rounded-lg p-5">
        <div className="max-w-4xl">
          <h3 className="mt-4 mb-2 h4 text-gray-600">general</h3>
          <div className="grid grid-cols-7 py-3 items-center">
            <span className="text-gray-700 font-medium col-span-4">
              <span className="text-lg">Team name</span>
              <div className="text-sm font-normal">
                Your team name is displayed on invoices and in team invitations.
              </div>
            </span>
            <span className="col-span-2 lg:col-span-1 flex flex-row-reverse">
              <button
                className="ml-4 btn btn-outline items-center"
                type="submit"
                onClick={() => setDialogOpened("editName")}
              >
                Edit
              </button>
            </span>
          </div>
        </div>
        <hr className="my-4" />
        <div>
          <RequestIncreasedLimitsDialog
            isOpen={requestCapacityDialogOpen}
            onClose={() => setRequestCapacityDialogOpen(false)}
          />
          <h3 className="mt-4 mb-1 h4 text-gray-600 flex items-center mt-8">
            team limits{" "}
            <button
              onClick={() => setRequestCapacityDialogOpen(true)}
              type="button"
              className="ml-4 btn-xs btn-outline"
            >
              + Request additional capacity
            </button>
          </h3>
          <div className="mb-2 text-gray-700 text-sm font-normal">
            Core resources have quotas to help prevent malicious actions or
            unexpected billing.
          </div>
          <div className="w-[40rem] py-3 items-center">
            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.Cell className="w-full">Service</Table.Cell>
                  <Table.Cell className="text-right whitespace-nowrap">
                    Limit (current/maximum)
                  </Table.Cell>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {Object.entries(data.limits).map(([key, value]) => (
                  <Table.Row key={key}>
                    <Table.Cell className="font-medium w-full">
                      {prettyServiceType(key)}
                    </Table.Cell>
                    <Table.Cell className="text-right font-medium">
                      {value.current}/{value.max}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        </div>
        <hr className="my-4" />
        <AcceptCancelDialog
          isOpen={dialogOpened === "deleteTeam"}
          onSubmit={handleDeleteTeam}
          onClose={handleCloseDeleteTeamModal}
          title={`Are you sure you want to delete ${team.team.name}?`}
        >
          This action is irreversible.
          <AxiosErrorAlert error={error} />
        </AcceptCancelDialog>
        <div className="max-w-4xl">
          <h3 className="my-4 h4 text-gray-600 mt-8">danger zone</h3>
          <div className="grid grid-cols-7 py-1 items-center">
            <span className="form-row-label">
              <span className="text-lg">Delete team</span>
              <div className="text-sm font-normal">
                This action is irreversible.
              </div>
            </span>
            <span className="form-row-input flex flex-row-reverse">
              <button
                className="mt-3 btn btn-red"
                onClick={() => setDialogOpened("deleteTeam")}
              >
                Delete
              </button>
            </span>
          </div>
        </div>
      </div>
    </section>
  );
}
