import { useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { Menu } from "components";
import { useField } from "formik";
import cn from "classnames";

export default function NodeTypeSelector({ name, nodeTypes, fixed }) {
  const [dropdownOpen, setDropdownOpen] = useState();
  const [, { value }, { setValue }] = useField(name);

  if (!nodeTypes) {
    return (
      <button type="button" className="btn btn-outline" disabled>
        Loading
      </button>
    );
  }

  const handleSetNodeType = (i) => {
    setValue(nodeTypes[i].name);
  };

  const selectedNodeType = nodeTypes.find((x) => x.name === value);

  return (
    <Menu>
      <Menu.Button
        type="button"
        className={({ open }) =>
          cn(
            open && "ring ring-blue-300 border-blue-400",
            "h-[2.65rem] border px-2 border-gray-300 rounded-lg flex items-center justify-between w-48 focus:outline-none focus:ring focus:ring-blue-300 focus:border-blue-400"
          )
        }
        onClick={() => setDropdownOpen(!dropdownOpen)}
      >
        <div className="grid">
          <span className="text-left text-xs">{value}</span>
          <span className="grid grid-cols-2 gap-1 text-2xs">
            <span className="bg-red-100 text-red-500 my-px rounded px-1 font-semibold">
              {selectedNodeType.vcpu} vCPUs
            </span>
            <span className="bg-emerald-100 text-emerald-500 my-px rounded px-1 font-semibold">
              {Math.round(selectedNodeType.memoryMi / 1024)} GiB
            </span>
          </span>
        </div>
        <FiChevronDown
          className="inline-block text-gray-500"
          aria-hidden="true"
        />
      </Menu.Button>
      <Menu.Items
        as="div"
        fixed={fixed}
        className="w-96 grid grid-cols-4 text-center rounded-lg"
      >
        {nodeTypes.map((x, i) => (
          <div
            onClick={() => handleSetNodeType(i)}
            key={x.id}
            className="justify-between bg-white p-px text-xs"
          >
            <div className="hover:bg-blue-100 rounded-lg m-1 p-1 cursor-pointer">
              <div className="font-semibold mb-1 text-xs">{x.name}</div>
              <span className="grid text-2xs">
                <span className="bg-red-100 text-red-500 my-px rounded py-0.5 px-1 font-semibold">
                  {x.vcpu} vCPUs
                </span>
                <span className="bg-emerald-100 text-emerald-500 my-px rounded py-0.5 px-1 font-semibold">
                  {Math.round(x.memoryMi / 1024)} GiB
                </span>
              </span>
            </div>
          </div>
        ))}
      </Menu.Items>
    </Menu>
  );
}
