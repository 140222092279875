import { useState, useMemo } from "react";
import { FiMoreHorizontal } from "react-icons/fi";
import { Field, Menu } from "components";
import cn from "classnames";
import { FiExternalLink } from "react-icons/fi";
import { Formik } from "formik";
import {
  FaCcVisa,
  FaCcMastercard,
  FaCcAmex,
  FaCcDinersClub,
  FaCcDiscover,
  FaCcJcb,
  FaCreditCard,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import useApiResource from "hooks/useApiResource";
import useToast from "hooks/useToast";
import useCursorPaginatedApiResource from "hooks/useCursorPaginatedApiResource";
import {
  AxiosErrorAlert,
  Alert,
  Table,
  Badge,
  Dialog,
  Input,
} from "components";
import CursorPagination from "components/CursorPagination";
import FullLoader from "components/FullLoader";
import useAxios from "hooks/useAxios";
import useAbortController from "hooks/useAbortController";
import * as Yup from "yup";

const TaxIdSchema = Yup.object().shape({
  taxId: Yup.string()
    .min(3, "Name must be longer than 3 characters")
    .required("Required"),
  stripeTaxIdType: Yup.string().required("Required"),
});

function TaxIdVerificationStatus({ status }) {
  let style;
  switch (status) {
    case "verified":
      style = "text-green-500";
      break;
    case "unverified":
      style = "text-red-500";
      break;
    default:
      style = "text-gray-500";
      break;
  }
  return <span className={cn(style, "font-semibold")}>{status}</span>;
}

function PaymentMethodRow({ paymentMethod, onDelete, onSetDefault }) {
  return (
    <tr className="text-gray-700 my-1.5">
      <td className="font-semibold pr-2 py-0.5">
        <span className="capitalize">
          <BrandIcon
            size="1.2rem"
            brand={paymentMethod.cardBrand}
            className="inline-block"
          />{" "}
          {paymentMethod.cardBrand}
        </span>{" "}
        ending in {paymentMethod.last4}
      </td>
      <td className="px-2 py-0.5">
        Expires {paymentMethod.expMonth.toString().padStart(2, "0")}/
        {paymentMethod.expYear}
      </td>
      <td className="pl-2 py-0.5">
        {paymentMethod.isDefaultPaymentMethod ? (
          <span className="font-semibold text-blue-600 text-right">Active</span>
        ) : (
          <Menu className="flex items-center justify-end">
            <Menu.Button>
              <FiMoreHorizontal className="cursor-pointer" />
            </Menu.Button>
            <Menu.Items>
              <Menu.ButtonItem onClick={onSetDefault}>
                Set to default
              </Menu.ButtonItem>
              <Menu.ButtonItem onClick={onDelete}>Delete</Menu.ButtonItem>
            </Menu.Items>
          </Menu>
        )}
      </td>
    </tr>
  );
}

const INVOICES_PER_PAGE = 5;
const COUNTRY_CODE_TO_TAX_ID_TYPE = {
  AE: [{ type: "ae_trn", description: "United Arab Emirates TRN" }],
  AT: [{ type: "eu_vat", description: "EU VAT number" }],
  AU: [
    { type: "au_abn", description: "Australian Business Number (AU ABN)" },
    {
      type: "au_arn",
      description: "Australian Taxation Office Reference Number",
    },
  ],
  BE: [{ type: "eu_vat", description: "EU VAT number" }],
  BG: [{ type: "eu_vat", description: "EU VAT number" }],
  BR: [
    { type: "br_cnpj", description: "Brazilian CNPJ number" },
    { type: "br_cpf", description: "Brazilian CPF number" },
  ],
  CA: [
    { type: "ca_bn", description: "Canadian BN" },
    { type: "ca_gst_hst", description: "Canadian GST/HST number" },
    {
      type: "ca_pst_bc",
      description: "Canadian PST number (British Columbia)",
    },
    { type: "ca_pst_mb", description: "Canadian PST number (Manitoba)" },
    { type: "ca_pst_sk", description: "Canadian PST number (Saskatchewan) " },
    { type: "ca_qst", description: "Canadian QST number (Québec)" },
  ],
  CH: [{ type: "ch_vat", description: "Switzerland VAT number" }],
  CL: [{ type: "cl_tin", description: "Chilean TIN 12.345.67" }],
  CY: [{ type: "eu_vat", description: "EU VAT number" }],
  CZ: [{ type: "eu_vat", description: "EU VAT number" }],
  DE: [{ type: "eu_vat", description: "EU VAT number" }],
  DK: [{ type: "eu_vat", description: "EU VAT number" }],
  EE: [{ type: "eu_vat", description: "EU VAT number" }],
  EL: [{ type: "eu_vat", description: "EU VAT number" }],
  ES: [
    { type: "eu_vat", description: "EU VAT number" },
    { type: "es_cif", description: "Spanish CIF number" },
  ],
  FI: [{ type: "eu_vat", description: "EU VAT number" }],
  FR: [{ type: "eu_vat", description: "EU VAT number" }],
  GB: [{ type: "gb_vat", description: "United Kingdom VAT number" }],
  GE: [{ type: "ge_vat", description: "Georgian VAT " }],
  HK: [{ type: "hk_br", description: "Hong Kong BR number" }],
  HR: [{ type: "eu_vat", description: "EU VAT number" }],
  HU: [{ type: "eu_vat", description: "EU VAT number" }],
  ID: [{ type: "id_npwp", description: "Indonesian NPWP number" }],
  IE: [{ type: "eu_vat", description: "EU VAT number" }],
  IL: [{ type: "il_vat", description: "Israel VAT" }],
  IN: [{ type: "in_gst", description: "Indian GST number" }],
  IS: [{ type: "is_vat", description: "Icelandic VAT" }],
  IT: [{ type: "eu_vat", description: "EU VAT number" }],
  JP: [
    { type: "jp_cn", description: "Japanese Corporate Number" },
    { type: "jp_rn", description: "Japanese Registered Foreign Businesses" },
  ],
  KR: [{ type: "kr_brn", description: "Korean BRN" }],
  LI: [{ type: "li_uid", description: "Liechtensteinian UID number" }],
  LT: [{ type: "eu_vat", description: "EU VAT number" }],
  LU: [{ type: "eu_vat", description: "EU VAT number" }],
  LV: [{ type: "eu_vat", description: "EU VAT number" }],
  MT: [{ type: "eu_vat", description: "EU VAT number" }],
  MX: [{ type: "mx_rfc", description: "Mexican RFC number" }],
  MY: [
    { type: "my_frp", description: "Malaysian FRP number" },
    { type: "my_itn", description: "Malaysian ITN" },
    { type: "my_sst", description: "Malaysian SST number" },
  ],
  NL: [{ type: "eu_vat", description: "EU VAT number" }],
  NO: [{ type: "no_vat", description: "Norwegian VAT number" }],
  NZ: [{ type: "nz_gst", description: "New Zealand GST number" }],
  PL: [{ type: "eu_vat", description: "EU VAT number" }],
  PT: [{ type: "eu_vat", description: "EU VAT number" }],
  RO: [{ type: "eu_vat", description: "EU VAT number" }],
  RU: [
    { type: "ru_inn", description: "Russian INN" },
    { type: "ru_kpp", description: "Russian KPP" },
  ],
  SA: [{ type: "sa_vat", description: "Saudi Arabia VAT" }],
  SE: [{ type: "eu_vat", description: "EU VAT number" }],
  SG: [
    { type: "sg_gst", description: "Singaporean GST" },
    { type: "sg_uen", description: "Singaporean UEN" },
  ],
  SI: [{ type: "eu_vat", description: "EU VAT number" }],
  SK: [{ type: "eu_vat", description: "EU VAT number" }],
  TH: [{ type: "th_vat", description: "Thai VAT " }],
  TW: [{ type: "tw_vat", description: "Taiwanese VAT" }],
  UA: [{ type: "ua_vat", description: "Ukrainian VAT" }],
  US: [{ type: "us_ein", description: "United States EIN" }],
  ZA: [{ type: "za_vat", description: "South African VAT number" }],
};

function BrandIcon({ brand, ...props }) {
  switch (brand) {
    case "visa":
      return <FaCcVisa {...props} />;
    case "mastercard":
      return <FaCcMastercard {...props} />;
    case "amex":
      return <FaCcAmex {...props} />;
    case "diners_club":
      return <FaCcDinersClub {...props} />;
    case "discover":
      return <FaCcDiscover {...props} />;
    case "jcb":
      return <FaCcJcb {...props} />;
    default:
      return <FaCreditCard {...props} />;
  }
}

function TaxIdDialog({ isOpen, onClose, country }) {
  const availableTaxIdTypes = COUNTRY_CODE_TO_TAX_ID_TYPE[country];
  const options = useMemo(
    () =>
      [{ value: "", element: "Select Tax ID type" }].concat(
        availableTaxIdTypes.map((x) => ({
          value: x.type,
          element: x.description,
        }))
      ),
    [availableTaxIdTypes]
  );
  const axios = useAxios();
  const { signal } = useAbortController();
  return (
    <Formik
      initialValues={{
        taxId: "",
        stripeTaxIdType:
          availableTaxIdTypes?.length === 1 ? availableTaxIdTypes[0].type : "",
      }}
      validationSchema={TaxIdSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={async (
        { taxId, stripeTaxIdType },
        { setSubmitting, setStatus }
      ) => {
        setSubmitting(true);
        try {
          await axios.put(
            "/rest/v1/stripe/tax-id",
            {
              taxId,
              stripeTaxIdType,
            },
            { signal }
          );
          onClose();
        } catch (err) {
          setStatus(err);
        }
        setSubmitting(false);
      }}
    >
      {({ status, handleSubmit, isSubmitting }) => (
        <Dialog open={isOpen} onClose={onClose}>
          <Dialog.Panel>
            <Dialog.Title>Configure Tax ID</Dialog.Title>
            <div>
              <Dialog.Description>
                Businesses with a valid Tax ID will have their tax charges
                removed from future invoices.
              </Dialog.Description>
              {availableTaxIdTypes?.length > 1 && (
                <Field.Listbox
                  options={options}
                  placeholder="Tax ID type"
                  name="stripeTaxIdType"
                />
              )}
              {availableTaxIdTypes?.length > 0 ? (
                <Field.Text
                  as={Input}
                  name="taxId"
                  className="my-5"
                  placeholder="Tax ID"
                />
              ) : (
                <span className="text-red-500 font-medium">
                  VAT ID is not supported for your country
                </span>
              )}
              <AxiosErrorAlert className="mb-4" error={status} />
              <div className="flex justify-end">
                <button
                  className="w-full sm:w-auto btn btn-outline"
                  type="button"
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button
                  className="w-full ml-3 sm:w-auto btn btn-blue"
                  type="submit"
                  disabled={isSubmitting || availableTaxIdTypes == null}
                  onClick={handleSubmit}
                >
                  Set
                </button>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      )}
    </Formik>
  );
}

export default function Billing() {
  const axios = useAxios();
  const { signal } = useAbortController();
  const toast = useToast();
  const {
    content: invoices,
    handlePageForward,
    handlePageBackward,
    canPageForward,
    canPageBackward,
    isFetching: isFetchingInvoice,
    error: errorInvoice,
  } = useCursorPaginatedApiResource(
    "/rest/v1/billing/invoice",
    (invoice) => invoice.stripeId,
    { pageSize: INVOICES_PER_PAGE }
  );
  const {
    data: billingInformation,
    isFetching: isFetchingBillingInformation,
    error: billingInformationError,
    refresh: refreshBillingInformation,
  } = useApiResource("/rest/v1/stripe");

  const { data: discount, isFetching: isFetchingDiscount } = useApiResource(
    "/rest/v1/team/discount"
  );
  const [addTaxIdModalOpen, setAddTaxIdModalOpen] = useState(false);

  if (isFetchingInvoice || isFetchingBillingInformation || isFetchingDiscount) {
    return (
      <div className="mx-auto h-full">
        <FullLoader delay={1} />
      </div>
    );
  }

  if (billingInformationError) throw billingInformationError;

  const handleOnCloseTaxIdDialog = () => {
    setAddTaxIdModalOpen(false);
    refreshBillingInformation();
  };

  const handleDeletePaymentMethod = async (paymentMethodStripeId) => {
    try {
      await axios.delete(
        `/rest/v1/stripe/payment-method/${paymentMethodStripeId}`,
        { signal }
      );
      refreshBillingInformation();
    } catch (err) {
      toast.exception("Failed to delete payment method", err);
    }
  };

  const handleDeleteTaxId = async () => {
    try {
      await axios.delete(`/rest/v1/stripe/tax-id`, { signal });
      refreshBillingInformation();
    } catch (err) {
      toast.exception("Failed to delete tax ID", err);
    }
  };

  const handleSetDefaultPaymentMethod = async (paymentMethodStripeId) => {
    try {
      await axios.put(
        `/rest/v1/stripe/payment-method/${paymentMethodStripeId}`,
        {
          signal,
        }
      );
      refreshBillingInformation();
    } catch (err) {
      toast.exception("Failed to change default payment method", err);
    }
  };

  if (errorInvoice) throw errorInvoice;
  return (
    <section className="grid h-full p-5">
      {billingInformation?.billingAddress?.country && (
        <TaxIdDialog
          isOpen={addTaxIdModalOpen}
          onClose={handleOnCloseTaxIdDialog}
          country={billingInformation?.billingAddress?.country}
        />
      )}
      <div className="py-4">
        <h3 className="my-4 h4 text-gray-600">billing settings</h3>
        <div>
          <h4 className="text-lg font-semibold text-gray-600 my-1">Address</h4>
          <p className="mb-2 font-medium text-gray-600">
            The billing address will be displayed on your monthly invoice.
          </p>
          {billingInformation?.billingAddress ? (
            <>
              <div className="text-gray-600">
                {billingInformation.billingAddress?.addressLine1 || "N/A"}
                {billingInformation.billingAddress?.addressLine2 && (
                  <div>{billingInformation.billingAddress.addressLine2}</div>
                )}
                <div>
                  {billingInformation.billingAddress?.postalCode || "N/A"}{" "}
                  {billingInformation.billingAddress?.city || "N/A"}
                </div>
                {billingInformation.billingAddress?.country && (
                  <div>{billingInformation.billingAddress.country}</div>
                )}
              </div>
              <div className="mt-4 mb-8">
                <Link
                  to="/billing-setup/address"
                  className="btn-sm btn-outline"
                >
                  Edit billing address
                </Link>
              </div>
              <div className="my-8">
                <h4 className="text-lg font-semibold text-gray-600 my-1">
                  Tax ID
                </h4>
                <span className="">
                  {billingInformation.taxId ? (
                    <>
                      <p className="text-gray-600">
                        Tax ID:{" "}
                        <span className="font-medium">
                          {billingInformation.taxId.value}
                        </span>
                      </p>
                      <p className="mb-4 text-gray-600">
                        Status:{" "}
                        <TaxIdVerificationStatus
                          status={billingInformation.taxId.verificationStatus}
                        />
                      </p>
                      <button
                        onClick={() => setAddTaxIdModalOpen(true)}
                        className="btn-xs btn-outline"
                      >
                        Edit Tax ID
                      </button>
                      <button
                        onClick={() => handleDeleteTaxId()}
                        className="btn-xs ml-2 btn-outline"
                      >
                        Remove Tax ID
                      </button>
                    </>
                  ) : (
                    <>
                      <p className="mb-4 font-medium text-gray-600">
                        Businesses with a valid Tax ID will have their tax
                        charges removed from future invoices.
                      </p>
                      <button
                        onClick={() => setAddTaxIdModalOpen(true)}
                        className="btn-xs btn-outline"
                      >
                        Add Tax ID
                      </button>
                    </>
                  )}
                </span>
              </div>
            </>
          ) : (
            <div className="mt-4 mb-8">
              <Link
                to="/billing-setup/address?redirect=/settings/billing"
                className="btn-sm btn-outline"
              >
                Configure billing address
              </Link>
            </div>
          )}
        </div>
        <div>
          <h4 className="text-lg font-semibold text-gray-600 my-1">
            Payment Methods
          </h4>
          {billingInformation?.paymentMethods?.length ? (
            <table>
              <tbody>
                {billingInformation.paymentMethods.map((x) => (
                  <PaymentMethodRow
                    key={x.stripeId}
                    paymentMethod={x}
                    onDelete={() => handleDeletePaymentMethod(x.stripeId)}
                    onSetDefault={() =>
                      handleSetDefaultPaymentMethod(x.stripeId)
                    }
                  />
                ))}
              </tbody>
            </table>
          ) : (
            <i className="text-gray-700">No payment method configured</i>
          )}
          <div className="pt-4">
            <Link
              to="/billing-setup/payment-method"
              className="btn-sm btn-outline"
            >
              Add payment method
            </Link>
          </div>
        </div>
      </div>
      {discount && (
        <>
          <hr className="form-row-divider" />
          <div className="">
            <h3 className="my-4 h4 text-gray-600">active discount</h3>
            <Alert type="info" className="my-3 px-8 inline-block">
              <div>
                <b>{discount.name}</b>
              </div>
              <p className="mt-1">
                Reduces your monthly bill by{" "}
                <span className="font-semibold">
                  {discount.amountOff
                    ? `up to ${(discount.amountOff / 100).toFixed(
                        2
                      )} ${discount.currency.toUpperCase()}`
                    : `${discount.percentOff}%`}
                </span>
                .
              </p>
            </Alert>
          </div>
        </>
      )}
      <hr className="form-row-divider my-4" />
      <div className="">
        <h3 className="mb-4 h4 text-gray-600">invoices</h3>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Cell>Created</Table.Cell>
              <Table.Cell>Invoice Number</Table.Cell>
              <Table.Cell>Total</Table.Cell>
              <Table.Cell>Status</Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {invoices?.map((x) => (
              <Table.Row key={x.invoiceNumber} className="text-sm">
                <Table.Cell>
                  <b>{new Date(x.createdAt).toLocaleDateString()}</b>
                </Table.Cell>
                <Table.Cell>{x.invoiceNumber}</Table.Cell>
                <Table.Cell>
                  {(x.total / 100).toFixed(2)}
                  {} <span className="uppercase">{x.currency}</span>
                </Table.Cell>
                <Table.Cell>
                  {x.isPaid ? (
                    <Badge type="success">Paid</Badge>
                  ) : (
                    <Badge type="danger">Not paid</Badge>
                  )}
                </Table.Cell>
                <Table.Cell>
                  {x.invoicePdf && (
                    <a
                      className="text-blue-600 flex items-center justify-end"
                      href={x.invoicePdf}
                    >
                      pdf
                      <FiExternalLink className="inline-block ml-1" />
                    </a>
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
            {!invoices?.length && (
              <Table.ContentRow>
                <div className="py-3 flex justify-center">No invoices yet.</div>
              </Table.ContentRow>
            )}
          </Table.Body>
        </Table>
        <CursorPagination
          resultsPerPage={INVOICES_PER_PAGE}
          canPageForward={canPageForward}
          canPageBackward={canPageBackward}
          onPageForward={handlePageForward}
          onPageBackward={handlePageBackward}
        />
      </div>
    </section>
  );
}
