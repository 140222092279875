import cn from "classnames";

// Deprecated
export default function Label({
  check,
  radio,
  disabled,
  className,
  children,
  ...other
}) {
  const cls = cn(
    (check || radio) && "inline-flex items-center",
    disabled && "opacity-50 cursor-not-allowed",
    className,
    "block text-sm text-gray-700"
  );
  return (
    <label className={cls} {...other}>
      {children}
    </label>
  );
}
