import { useMemo, useCallback, useState, useEffect } from "react";
import axios from "axios";
import useAxios from "hooks/useAxios";
import useAbortController from "hooks/useAbortController";

export default function usePaginatedApiResource(
  url,
  page,
  { pageSize = 20, sort = null }
) {
  const axiosInstance = useAxios();
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [isFetching, setIsFetching] = useState(true);
  const { signal } = useAbortController();
  const stack = useMemo(() => new Error().stack, []);

  const fetchResource = useCallback(() => {
    axiosInstance
      .get(url, {
        signal,
        params: {
          size: pageSize,
          page,
          sort,
        },
      })
      .then(({ data }) => {
        if (signal.aborted) return;
        setData(data);
        setError();
        setIsFetching(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          err.stack = stack;
          setError(err);
          setIsFetching(false);
        }
      })
      .finally(() => setIsFetching(false));
  }, [sort, axiosInstance, url, page, pageSize, signal, stack]);

  useEffect(() => {
    fetchResource();
  }, [url, page, pageSize, fetchResource]);

  return {
    content: data?.content,
    totalElements: data?.totalElements,
    isFetching,
    error,
    refresh: () => fetchResource(),
  };
}
