import { useCallback, useState, useEffect } from "react";
import queryString from "query-string";
import { useNavigate, useLocation } from "react-router-dom";
import { handleFlowError } from "components/kratos/errors"

export default function useOryFlow(get, create, update, callback = () => {}, flowUrlFunc = (pathname, flowId) => `${pathname}?flow=${flowId}`) {
  const [flow, setFlow] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const { flow: flowId, return_to: returnTo } = queryString.parse(location.search)

  useEffect(() => {
    // If the router is not ready yet, or we already have a flow, do nothing.
    if (flow) {
      return
    }

    if (flowId) {
      get(flowId)
        .then(({ data }) => {
          // We received the flow - let's use its data and render the form!
          setFlow(data)
        })
        .catch(handleFlowError(navigate, location.pathname, setFlow))
      return
    }

    create(returnTo)
      .then(({ data }) => {
        setFlow(data)
      })
      .catch(handleFlowError(navigate, location.pathname, setFlow))
  }, [get, create, flowId, location.pathname, navigate, returnTo, flow])

  const onSubmit = useCallback((values) => {
    window.history.replaceState(null, undefined, flowUrlFunc(location.pathname, flow?.id))
    return update(flow, values)
      .then(callback)
      .catch(handleFlowError(navigate, location.pathname, setFlow))
      .catch((err) => {
        // If the previous handler did not catch the error it's most likely a form validation error
        if (err.response?.status === 400) {
          // Yup, it is!
          setFlow(err.response?.data)
          return
        }

      return Promise.reject(err)
    })
  }, [update, callback, flow, flowUrlFunc, location.pathname, navigate])

  return [flow, onSubmit]
}
