import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FiCopy, FiCheckCircle } from "react-icons/fi";
import cn from "classnames";

export default function CopyToClipboardField({ text, onCopy, children }) {
  const [hasCopied, setHasCopied] = useState();
  const [hoverActive, setHoverActive] = useState();
  const handleCopy = () => {
    setHasCopied(true);
    if (onCopy) onCopy();
  };
  const handleMouseExit = () => {
    setHoverActive(false);
    setHasCopied(false);
  };
  return (
    <CopyToClipboard text={text} onCopy={handleCopy}>
      <span
        className="cursor-pointer inline-flex justify-center"
        onMouseOver={() => setHoverActive(true)}
        onMouseLeave={handleMouseExit}
      >
        {hoverActive && (
          <div
            className={cn(
              hasCopied ? "bg-green-600" : "bg-gray-700",
              "rounded mt-6 flex items-center px-4 py-2 absolute shadow-lg text-xs text-white"
            )}
          >
            {hasCopied ? (
              <>
                <FiCheckCircle className="inline-block mr-2" />
                Copied
              </>
            ) : (
              <>
                <FiCopy className="inline-block mr-2" />
                Click to copy
              </>
            )}
          </div>
        )}
        {children}
      </span>
    </CopyToClipboard>
  );
}
