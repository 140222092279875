import useApiResource from "hooks/useApiResource";
import useAxios from "hooks/useAxios";
import useTeam from "hooks/useTeam";
import useUser from "hooks/useUser";
import CopyToClipboardField from "components/CopyToClipboardField";

export default function TestSettings() {
  const team = useTeam();
  const user = useUser();
  const axios = useAxios();
  const { data: paymentMethod, refresh: refreshPaymentMethod } = useApiResource(
    "/rest/v1/stripe/payment-method"
  );

  const handleTestPaymentMethod = async () => {
    await axios.post("/rest/v1/stripe/payment-method", {
      stripeToken: "pm_card_visa",
    });
    refreshPaymentMethod();
  };

  const handleClearPaymentMethod = async () => {
    await axios.delete("/rest/v1/stripe/payment-method");
    refreshPaymentMethod();
  };

  return (
    <div className="grid gap-5 p-5">
      <h1 className="my-4 h4 text-gray-600">test settings</h1>
      <div className="divide-y">
        <div className="form-row">
          <span className="form-row-label">Payment Method</span>
          <div className="form-row-input">
            {paymentMethod ? (
              <>
                (**** **** **** {paymentMethod.last4})
                <button
                  className="ml-3 btn-xs btn-red"
                  onClick={handleClearPaymentMethod}
                >
                  Clear
                </button>
              </>
            ) : (
              <button
                className="btn-xs btn-outline"
                onClick={handleTestPaymentMethod}
              >
                Set to Visa card
              </button>
            )}
          </div>
        </div>
        <div className="form-row">
          <span className="form-row-label">Misc</span>
          <ul className="form-row-input">
            <li className="font-mono">
              Team ID:{" "}
              <CopyToClipboardField text={team.team.id}>
                {team.team.id}
              </CopyToClipboardField>
            </li>
            <li className="font-mono">
              User ID:{" "}
              <CopyToClipboardField text={user.id}>
                {user.id}
              </CopyToClipboardField>
            </li>
            <li className="font-mono">
              Subject ID:{" "}
              <CopyToClipboardField text={user.subjectId}>
                {user.subjectId}
              </CopyToClipboardField>
            </li>
          </ul>
        </div>
        <div className="form-row">
          <span className="form-row-label">Feature Gates</span>
          <ul className="form-row-input">
            {Object.entries(team.user.featureGates).map(([key, value]) => (
              <li key={key} className="font-mono">
                {key}: {value.enabled.toString()}
              </li>
            ))}
          </ul>
        </div>
        <div className="form-row">
          <span className="form-row-label">User Authorities</span>
          <ul className="form-row-input">
            {team.user.authorities.map((x) => (
              <li key={x} className="font-mono">
                {x}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
