import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import InvitationAlert from "partials/InvitationAlert";
import PsuedoSiteHeader from "partials/PsuedoSiteHeader";
import ory from "components/kratos/sdk/index.ts"
import { handleFlowError } from "components/kratos/errors"
import { Flow } from "components/kratos"
import queryString from "query-string";


function Signup({ onSignup }) {
  const [flow, setFlow] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const query = queryString.parse(location.search);
  const invitationId = query.invitationId;
  const { flow: flowId, return_to: returnTo } = queryString.parse(location.search)

  useEffect(() => {
    // If the router is not ready yet, or we already have a flow, do nothing.
    if (flow) {
      return
    }

    // If ?flow=.. was in the URL, we fetch it
    if (flowId) {
      ory
        .getRegistrationFlow({ id: String(flowId) })
        .then(({ data }) => {
          // We received the flow - let's use its data and render the form!
          setFlow(data)
        })
        .catch(handleFlowError(navigate, location.pathname, setFlow))
      return
    }

    // Otherwise we initialize it
    ory
      .createBrowserRegistrationFlow({
        returnTo: returnTo ? String(returnTo) : undefined,
      })
      .then(({ data }) => {
        setFlow(data)
      })
      .catch(handleFlowError(navigate, location.pathname, setFlow))
  }, [flowId, navigate, returnTo, flow, location.pathname])

  const onSubmit = (values) => {
    window.history.replaceState(null, undefined, `${location.pathname}?flow=${flow?.id}`)
    return ory
      .updateRegistrationFlow({
        flow: String(flow?.id),
        updateRegistrationFlowBody: values,
      })
      .then(onSignup)
      .catch(handleFlowError(navigate, location.pathname, setFlow))
      .catch((err) => {
        // If the previous handler did not catch the error it's most likely a form validation error
        if (err.response?.status === 400) {
          // Yup, it is!
          setFlow(err.response?.data)
          return
        }

        return Promise.reject(err)
      })
  }

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Page content */}
      <main className="flex-grow">
        <section className="bg-gradient-to-b from-gray-100 to-white">
          <PsuedoSiteHeader />
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-5 pb-12 md:pt-4 md:pb-8">
              {/* Page header */}
              <div className="max-w-3xl mx-auto text-center pb-4">
                <h1 className="h1">Welcome.</h1>
              </div>

              <div className="max-w-sm mx-auto">
                {invitationId && (
                  <InvitationAlert invitationId={invitationId} />
                )}
                {/* Form */}
                <Flow onSubmit={onSubmit} flow={flow} />
                <div className="text-sm text-gray-500 text-center mt-3">
                  By creating an account, you agree to the{" "}
                  <a
                    className="text-blue-500"
                    onMouseDown={(e) => e.preventDefault()}
                    href={`${process.env.REACT_APP_LANDING_PAGE_PATH}/terms-and-conditions`}
                  >
                    terms &amp; conditions
                  </a>
                  , and our{" "}
                  <a
                    className="text-blue-500"
                    onMouseDown={(e) => e.preventDefault()}
                    href={`${process.env.REACT_APP_LANDING_PAGE_PATH}/privacy-policy`}
                  >
                    privacy policy
                  </a>
                  .
                </div>
                <div className="text-gray-600 text-center mt-4 text-sm">
                  Already got an account?{" "}
                  <Link
                    to={`/signin`}
                    className="text-blue-600 hover:underline transition duration-150 ease-in-out"
                  >
                    Sign in
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default Signup;
