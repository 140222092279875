import { useState, useEffect } from "react";

/**
 * Button that gets disabled and gets a spinner during `onClick` action.
 */
export default function LoadingButton({ onClick, disabled, ...otherProps }) {
  const [isSubmitting, setIsSubmitting] = useState();
  useEffect(() => {
    let isMounted = true;
    if (isSubmitting) {
      onClick().then(() => isMounted && setIsSubmitting(false));
    }
    return () => {
      isMounted = false;
    };
  }, [isSubmitting, onClick]);

  return (
    <button
      disabled={disabled || isSubmitting}
      onClick={() => setIsSubmitting(true)}
      {...otherProps}
    />
  );
}
