import { useMemo, useEffect } from "react";

export default function useAbortController() {
  const controller = useMemo(() => new AbortController(), []);
  useEffect(
    () => () => {
      controller.abort();
    },
    [controller]
  );
  return controller;
}
