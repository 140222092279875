import { TextInput } from "@ory/themes"

import { NodeInputProps } from "./helpers"

export function NodeInputDefault<T>(props: NodeInputProps) {
  const { node, attributes, value = "", setValue, disabled } = props

  // Some attributes have dynamic JavaScript - this is for example required for WebAuthn.
  const onClick = () => {
    // This section is only used for WebAuthn. The script is loaded via a <script> node
    // and the functions are available on the global window level. Unfortunately, there
    // is currently no better way than executing eval / function here at this moment.
    if (attributes.onclick) {
      const run = new Function(attributes.onclick)
      run()
    }
  }

  const state = node.messages.find(({ type }) => type === "error") ? "error" : undefined

  const subtitles = node.messages.map(({ text, id }, k) => (
    <span key={`${id}-${k}`} data-testid={`ui/message/${id}`}>
      {text}
    </span>
  ))
  const title = node.meta.label?.text

  return (
    <div className="my-2">
      {title && (
        <div className="text-gray-800 text-sm font-medium mb-1">
          {title}
        </div>
      )}
      <input className="form-input w-full" disabled={attributes.disabled || disabled} onChange={e => setValue(e.target.value)} value={value} name={attributes.name} type={attributes.type} />
      {subtitles && (
        <div className="my-2 text-red-700 font-medium">
          {subtitles}
        </div>
      )}
    </div>

  );

  // Render a generic text input field.
  return (
    <TextInput
      title={node.meta.label?.text}
      onClick={onClick}
      onChange={(e) => {
        setValue(e.target.value)
      }}
      type={attributes.type}
      name={attributes.name}
      value={value}
      disabled={attributes.disabled || disabled}
      help={node.messages.length > 0}
      state={
        node.messages.find(({ type }) => type === "error") ? "error" : undefined
      }
      subtitle={
        <>
          {node.messages.map(({ text, id }, k) => (
            <span key={`${id}-${k}`} data-testid={`ui/message/${id}`}>
              {text}
            </span>
          ))}
        </>
      }
    />
  )
}
