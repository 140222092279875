import { useState } from "react";
import useApiResource from "hooks/useApiResource";
import { Formik, Form } from "formik";
import { useAxios } from "hooks";
import { useParams } from "react-router-dom";
import { Table, Field } from "components";
import { RiEyeFill, RiEyeOffFill } from "react-icons/ri";
import ProjectLayout from "./ProjectLayout";

function Secret({ secret }) {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <div className="font-mono">
      <button type="button" onClick={() => setIsVisible(!isVisible)}>
        {isVisible ? (
          <RiEyeOffFill size="1.2rem" className="inline-block  mr-1" />
        ) : (
          <RiEyeFill size="1.2rem" className="inline-block mr-1" />
        )}
      </button>
      {isVisible ? secret : "∗∗∗∗∗∗∗∗"}
    </div>
  );
}

function Secrets() {
  const { projectName } = useParams();
  const axios = useAxios();
  const {
    data: secrets,
    isFetching,
    refresh,
    error,
  } = useApiResource(`/rest/v1/project/${projectName}/secret`);
  if (isFetching) return null;
  if (error) throw error;

  const handleDeleteSecret = async (key) => {
    await axios.delete(`/rest/v1/project/${projectName}/secret/${key}`);
    refresh();
  };

  return (
    <section className="my-5">
      <Formik
        initialValues={{
          key: "",
          developmentValue: "",
          previewValue: "",
          productionValue: "",
        }}
        onSubmit={async (
          { key, developmentValue, previewValue, productionValue },
          { setStatus, setSubmitting }
        ) => {
          try {
            await axios.put(`/rest/v1/project/${projectName}/secret/${key}`, {
              developmentValue,
              previewValue,
              productionValue,
            });
            refresh();
          } catch (err) {
            setStatus(err);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, handleSubmit }) => (
          <Form className="w-80 my-5 bg-gray-50 rounded-lg border p-5">
            <h2 className="text-gray-600 text-xs font-medium tracking-wide uppercase">
              Add secret
            </h2>
            <div className="grid gap-3 my-4">
              <Field.Text placeholder="Key" name="key" />
              <Field.Text
                placeholder="Development value"
                name="developmentValue"
              />
              <Field.Text placeholder="Preview value" name="previewValue" />
              <Field.Text
                placeholder="Production value"
                name="productionValue"
              />
            </div>
            <button
              disabled={isSubmitting}
              type="submit"
              onClick={handleSubmit}
              className="btn-outline btn"
            >
              Add
            </button>
          </Form>
        )}
      </Formik>
      <Table>
        <Table.Body>
          {Object.entries(secrets).map(([key, value]) => (
            <Table.Row key={key}>
              <Table.Cell className="w-[25%] bg-gray-50 leading-4">
                <span className="uppercase text-2xs text-gray-500 font-medium tracking-wider">
                  Key
                </span>
                <div className="font-semibold">{key}</div>
              </Table.Cell>
              <Table.Cell className="w-[15%] leading-4 bg-gray-50">
                <span className="uppercase text-2xs text-gray-500 font-medium tracking-wider">
                  Development
                </span>
                <Secret secret={value.developmentValue} />
              </Table.Cell>
              <Table.Cell className="w-[15%] leading-4 bg-gray-50">
                <span className="uppercase text-2xs text-gray-500 font-medium tracking-wider">
                  Preview
                </span>
                <Secret secret={value.previewValue} />
              </Table.Cell>
              <Table.Cell className="w-[15%] leading-4 bg-gray-50">
                <span className="uppercase text-2xs text-gray-500 font-medium tracking-wider">
                  Production
                </span>
                <Secret secret={value.productionValue} />
              </Table.Cell>
              <Table.Cell className="bg-gray-50">
                <button
                  onClick={() => handleDeleteSecret(key)}
                  className="float-right btn-xs btn-outline"
                >
                  Delete
                </button>
              </Table.Cell>
            </Table.Row>
          ))}
          {!Object.keys(secrets).length && !error && (
            <Table.ContentRow>
              <div className="py-3 flex justify-center">
                No secrets configured.
              </div>
            </Table.ContentRow>
          )}
        </Table.Body>
      </Table>
    </section>
  );
}

export default function SecretLayout({ project }) {
  return (
    <ProjectLayout project={project}>
      <Secrets />
    </ProjectLayout>
  );
}
