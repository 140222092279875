import { usePaginatedApiResource } from "hooks";
import { Formik, Form } from "formik";
import { useAxios } from "hooks";
import { useParams } from "react-router-dom";
import { Field } from "components";
import ProjectLayout from "./ProjectLayout";

function Settings({ project, onRefreshProject }) {
  const { projectName } = useParams();
  const axios = useAxios();
  const { content: clusters, isFetching } = usePaginatedApiResource(
    `/rest/v1/cluster`,
    0,
    { sort: "name" }
  );
  if (isFetching) return null;

  return (
    <section className="my-5">
      <Formik
        initialValues={{
          productionBranch: project.configuration.productionBranch,
          clusterId: project.productionClusterId,
        }}
        onSubmit={async (
          { clusterId, productionBranch },
          { setStatus, setSubmitting }
        ) => {
          try {
            await axios.put(`/rest/v1/project/${projectName}`, {
              clusterId,
              configuration: {
                productionBranch,
              },
            });
            onRefreshProject();
          } catch (err) {
            setStatus(err);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, handleSubmit }) => (
          <Form className="w-80 my-5 bg-gray-50 rounded-lg p-5 border">
            <h2 className="text-gray-600 text-xs font-medium tracking-wide uppercase">
              Production branch
            </h2>
            <p className="text-xs text-gray-700 my-1">
              Merging to this branch will apply your <code>sym.yaml</code>{" "}
              configuration to your production cluster. No changes will be
              applied if a production cluster isn't specified.
            </p>
            <Field.Text name="productionBranch" />
            <hr className="my-3" />
            <h2 className="text-gray-600 text-xs font-medium tracking-wide uppercase">
              Production cluster
            </h2>
            <p className="text-xs text-gray-700 my-1">
              Set a production cluster to have your changes automatically
              deployed to a production cluster when merged to the default branch
            </p>
            <Field.Listbox
              options={[
                { value: null, element: "<don't deploy>" },
                ...clusters?.map((x) => ({ value: x.id, element: x.name })),
              ]}
              placeholder="Production cluster"
              name="clusterId"
            />
            <button
              disabled={isSubmitting}
              type="submit"
              onClick={handleSubmit}
              className="btn-outline btn mt-4"
            >
              Save
            </button>
          </Form>
        )}
      </Formik>
    </section>
  );
}

export default function SettingsLayout({ project, onRefreshProject }) {
  return (
    <ProjectLayout project={project}>
      <Settings project={project} onRefreshProject={onRefreshProject} />
    </ProjectLayout>
  );
}
