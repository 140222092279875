import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useStripe } from "@stripe/react-stripe-js";
import useAxios from "hooks/useAxios";

function AddPaymentMethodResult() {
  const stripe = useStripe();
  const axios = useAxios();
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const searchParams = new URLSearchParams(window.location.search);
    const clientSecret = searchParams.get("setup_intent_client_secret");

    // Retrieve the SetupIntent
    stripe.retrieveSetupIntent(clientSecret).then(async ({ setupIntent }) => {
      switch (setupIntent.status) {
        case "succeeded":
          await axios.put(
            `/rest/v1/stripe/payment-method/${setupIntent.payment_method}`
          );
          const redirect = searchParams.get("redirect");
          const state = searchParams.get("state");
          if (state) {
            navigate(`${redirect}?state=${state}`);
          } else {
            navigate(redirect);
          }
          break;

        case "processing":
          setMessage(
            "Processing payment details. We'll update you when processing is complete."
          );
          break;

        case "requires_payment_method":
          setMessage(
            "Failed to process payment details. Please try another payment method."
          );
          break;
        default:
      }
    });
  }, [stripe, navigate, axios]);

  return message;
}

export default AddPaymentMethodResult;
