import { ErrorBoundary } from "@sentry/react";
import { useNavigate } from "react-router-dom";
import useInterval from "hooks/useInterval";
import IfTestEnvironment from "components/IfTestEnvironment";
import PsuedoSiteHeader from "partials/PsuedoSiteHeader";

function RefreshHOC({ delay, children }) {
  const navigate = useNavigate();
  /*
  useInterval(async () => {
    navigate(0);
  }, 10000);
  */

  return children;
}

export default function GlobalErrorBoundaryView({ children }) {
  return (
    <ErrorBoundary
      beforeCapture={(scope) => {
        scope.setTag("location", "global");
      }}
      fallback={({ error, resetError }) => (
        <RefreshHOC delay={3000}>
          <main className="h-screen text-center bg-gradient-to-b from-gray-100 to-white">
            <PsuedoSiteHeader />
            <section className="my-32">
              <h1 className="font-bold text-3xl text-gray-700">
                Looks like something went wrong.
              </h1>
              <h2 className="text-gray-600 text-xl mb-8">
                visit{" "}
                <a
                  className="text-blue-500"
                  href="https://symbiosis.host/status"
                >
                  symbiosis.host/status
                </a>{" "}
                if issues remain.
              </h2>
              <IfTestEnvironment>
                <div className="rounded bg-blue-200">
                  <h1 className="text-xl">Error details</h1>
                  {error?.status && <h1>{error.status}</h1>}
                  <h2>{error?.message || <i>No error message</i>}</h2>
                </div>
              </IfTestEnvironment>
            </section>
          </main>
        </RefreshHOC>
      )}
    >
      {children}
    </ErrorBoundary>
  );
}
