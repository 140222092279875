import { Elements } from "@stripe/react-stripe-js";
import useApiResource from "hooks/useApiResource";
import AddCardForm from "partials/AddCardForm";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export default function AddPaymentMethodTab({ returnUrl }) {
  const { data, isFetching, error } = useApiResource(
    "/rest/v1/stripe/setup-intent"
  );
  if (isFetching) return null;
  if (error) throw error;
  const options = {
    clientSecret: data.clientSecret,
    appearance: {
      /*...*/
    },
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <AddCardForm returnUrl={returnUrl} />;
    </Elements>
  );
}
