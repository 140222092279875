import { useEffect, useState } from "react";
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import { useLocation } from "react-router-dom";

function InvokeOnNavigationHOC({ func, children }) {
  const [currentLocation, setCurrentLocation] = useState();
  const location = useLocation();
  useEffect(() => {
    // Call func when user has navigated away from error boundary
    if (
      func &&
      currentLocation &&
      currentLocation?.pathname !== location?.pathname
    ) {
      func();
    }
    setCurrentLocation(location);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return children;
}

export default function ErrorBoundary({ children }) {
  return (
    <SentryErrorBoundary
      beforeCapture={(scope) => {
        scope.setTag("location", "team-view");
      }}
      fallback={({ error, resetError }) => {
        return (
          <InvokeOnNavigationHOC func={error && resetError}>
            <main className="text-center">
              <div className="mt-32">
                <h1 className="font-semibold text-3xl">
                  Something went wrong.
                </h1>
                <h2>{error?.message}</h2>
              </div>
            </main>
          </InvokeOnNavigationHOC>
        );
      }}
    >
      {children}
    </SentryErrorBoundary>
  );
}
