import { useEffect } from "react";
import { useAxios } from "hooks";
import { FullLoader } from "components";
import queryString from "query-string";
import { useNavigate, useLocation } from "react-router-dom";

export default function AddProjectCallback() {
  const location = useLocation();
  const query = queryString.parse(location.search);
  const { teamId, token } = JSON.parse(query.state);
  const axios = useAxios();
  const navigate = useNavigate();
  useEffect(() => {
    axios
      .post(
        "/rest/v1/github/installation",
        {
          installationId: query["installation_id"],
        },
        {
          headers: {
            "X-Stim-Team": teamId,
            "X-Auth-Token": token,
          },
        }
      )
      .then(() => {
        navigate("/projects/new");
      });
  }, [axios, navigate, teamId, token, query]);
  return <FullLoader />;
}
