import cn from "classnames";
import styles from "./Table.module.css";

export default function Table({ className, ...props }) {
  return <table {...props} className={cn(styles.table, className, "")} />;
}

Table.Head = ({ className, ...props }) => (
  <thead {...props} className={cn(className, "")} />
);

Table.Body = ({ className, ...props }) => (
  <tbody {...props} className={cn(className, "")} />
);

Table.Row = ({ className, ...props }) => (
  <tr {...props} className={cn(className, "")} />
);

Table.Cell = ({ className, ...props }) => (
  <td {...props} className={cn(className, "")} />
);

Table.ContentRow = ({ className, children }) => (
  <tr className={cn(styles.contentRow, className, "")}>
    <td colSpan="1024">{children}</td>
  </tr>
);
