import { useMemo } from "react";
import { useApiResource, useInterval } from "hooks";
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import { Oval } from "react-loader-spinner";

const units = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

function byteFormatter(x) {
  let l = 0,
    n = parseInt(x, 10) || 0;
  while (n >= 1024 && ++l) {
    n = n / 1024;
  }
  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + units[l] + "/s";
}

const NOT_ENOUGH_DATA_THRESHOLD = 3;

function TelemetryChart({
  title,
  formatter,
  data,
  children,
  domain,
  yAxisWidth = 25,
}) {
  const isLoading = data == null;
  const notEnoughData = data?.length < NOT_ENOUGH_DATA_THRESHOLD;
  return (
    <li className="relative">
      <div className="text-gray-600 font-medium mb-1.5">{title}</div>
      <ResponsiveContainer width="100%" height={220}>
        <AreaChart data={data}>
          <CartesianGrid vertical stroke="#eee" />
          <YAxis
            width={yAxisWidth}
            stroke="#eee"
            type="number"
            domain={domain}
            tickFormatter={formatter}
            style={{ fill: "#888", fontSize: 8 }}
          />
          <XAxis
            stroke="#eee"
            tick={{ fill: "#888", fontSize: 8 }}
            tickFormatter={(x) =>
              (x === "auto" ? new Date() : new Date(x)).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })
            }
            dataKey="minute"
          />
          {!isLoading && !notEnoughData && (
            <>
              <Tooltip
                labelStyle={{ fontWeight: "600" }}
                wrapperStyle={{ outline: "none" }}
                itemStyle={{ padding: "0.08rem 0", lineHeight: "1" }}
                contentStyle={{
                  padding: "0.3rem",
                  fontSize: "0.55rem",
                  borderColor: "#ddd",
                  borderRadius: "3px",
                }}
                formatter={formatter}
                labelFormatter={(x) => new Date(x).toLocaleString()}
              />
              {children}
            </>
          )}
        </AreaChart>
      </ResponsiveContainer>
      {notEnoughData && (
        <div
          style={{ marginLeft: `${yAxisWidth / 2}px` }}
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-xs font-semibold tracking-wide text-gray-400 uppercase"
        >
          Not enough data
        </div>
      )}
      {isLoading && (
        <div
          style={{ marginLeft: `${yAxisWidth / 2}px` }}
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-xs font-semibold tracking-wide text-gray-400 uppercase"
        >
          <Oval height={30} width={30} secondaryColor="#999" color="#888" />
        </div>
      )}
    </li>
  );
}

const REFRESH_TELEMETRY_INTERVAL_MS = 5 * 60 * 1000;
const ANIMATION_DURATION = 400;

export default function ClusterTelemetryPanel({ clusterId }) {
  const { data: telemetry, refresh } = useApiResource(
    `/rest/v1/cluster/${clusterId}/telemetry`
  );

  const telemetryReverse = useMemo(
    () => telemetry && [...telemetry].reverse(),
    [telemetry]
  );

  useInterval(async () => {
    refresh();
  }, REFRESH_TELEMETRY_INTERVAL_MS);

  return (
    <ul className="grid grid-cols-3 gap-8 justify-between">
      <TelemetryChart
        title="CPU usage"
        formatter={(x) => `${(x * 100).toFixed()}%`}
        data={telemetryReverse}
        domain={[0, 1]}
        yAxisWidth={25}
      >
        <Area
          name="min"
          dataKey={(x) => Math.min(1, x.minCpuUsage)}
          stackId="1"
          animationDuration={ANIMATION_DURATION}
          stroke="hsl(150deg 30% 60%)"
          fillOpacity="0.3"
          fill="hsl(150deg 30% 60%)"
        />
        <Area
          name="average"
          dataKey={(x) => Math.min(1, x.avgCpuUsage)}
          stackId="2"
          animationDuration={ANIMATION_DURATION}
          stroke="hsl(150deg 30% 60%)"
          fillOpacity="0.3"
          fill="hsl(150deg 30% 60%)"
        />
        <Area
          name="max"
          dataKey={(x) => Math.min(1, x.maxCpuUsage)}
          stackId="3"
          animationDuration={ANIMATION_DURATION}
          stroke="hsl(150deg 30% 60%)"
          fillOpacity="0.3"
          fill="hsl(150deg 30% 60%)"
        />
      </TelemetryChart>
      <TelemetryChart
        title="Memory usage"
        formatter={(x) => `${(x * 100).toFixed()}%`}
        data={telemetryReverse}
        domain={[0, 1]}
        yAxisWidth={25}
      >
        <Area
          name="min"
          dataKey="minMemoryUsage"
          stackId="1"
          animationDuration={ANIMATION_DURATION}
          stroke="hsl(280deg 30% 60%)"
          fillOpacity="0.3"
          fill="hsl(280deg 30% 60%)"
        />
        <Area
          name="average"
          dataKey="avgMemoryUsage"
          stackId="2"
          animationDuration={ANIMATION_DURATION}
          stroke="hsl(280deg 30% 60%)"
          fillOpacity="0.3"
          fill="hsl(280deg 30% 60%)"
        />
        <Area
          name="max"
          dataKey="maxMemoryUsage"
          stackId="3"
          animationDuration={ANIMATION_DURATION}
          stroke="hsl(280deg 30% 60%)"
          fillOpacity="0.3"
          fill="hsl(280deg 30% 60%)"
        />
      </TelemetryChart>
      <TelemetryChart
        title="Public bandwidth"
        formatter={byteFormatter}
        data={telemetryReverse}
        domain={[0, 1024]}
        yAxisWidth={40}
      >
        <Area
          name="min"
          dataKey={(x) => x.minTransmitBytes + x.minReceiveBytes}
          stackId="1"
          animationDuration={ANIMATION_DURATION}
          stroke="hsl(220deg 30% 60%)"
          fillOpacity="0.3"
          fill="hsl(220deg 30% 60%)"
        />
        <Area
          name="average"
          dataKey={(x) => x.avgTransmitBytes + x.avgReceiveBytes}
          stackId="2"
          animationDuration={ANIMATION_DURATION}
          stroke="hsl(220deg 30% 60%)"
          fillOpacity="0.3"
          fill="hsl(220deg 30% 60%)"
        />
        <Area
          name="max"
          dataKey={(x) => x.maxTransmitBytes + x.maxReceiveBytes}
          stackId="3"
          animationDuration={ANIMATION_DURATION}
          stroke="hsl(220deg 30% 60%)"
          fillOpacity="0.3"
          fill="hsl(220deg 30% 60%)"
        />
      </TelemetryChart>
    </ul>
  );
}
