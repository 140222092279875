import FullLoader from "components/FullLoader";
import useApiResource from "hooks/useApiResource";
import { Navigate, useParams } from "react-router-dom";
import ClusterLoader from "./ClusterLoader";
import ClusterView from "./ClusterView";

export default function ClusterRouter() {
  const { clusterId } = useParams();
  const {
    data: cluster,
    isFetching,
    refresh,
    error,
  } = useApiResource(`/rest/v1/cluster/${clusterId}`);

  if (isFetching) {
    return <FullLoader />;
  }

  // Throw error only if we don't have any data, otherwise it might be an error due to a refresh, in which case we ignore it
  if (error && !cluster) {
    throw error;
  }

  if (!cluster) return <Navigate to="/clusters" />;

  if (cluster.state === "FAILED") {
    return (
      <main className="w-full flex flex-col items-center pt-36">
        <div className="text-4xl">😢</div>
        <div className="h4 mt-3">Cluster initialization failed</div>
        <p className="">We are looking into it... Try again in a moment.</p>
      </main>
    );
  }
  if (cluster.state !== "ACTIVE" && cluster.state !== "DELETE_IN_PROGRESS") {
    return <ClusterLoader cluster={cluster} onRefresh={refresh} />;
  }
  return <ClusterView cluster={cluster} onRefresh={refresh} />;
}
