import { Alert } from "components";
import cn from "classnames";

export default function AxiosErrorAlert({ error, className }) {
  if (!error) return null;
  const errorMessage = error.response
    ? error.response.data?.message
    : error.message;
  return (
    <Alert type="danger" className={cn(className, "w-full break-words")}>
      {errorMessage}
    </Alert>
  );
}
