import { Dialog, Input, Label, Menu, Table } from "components";
import CopyToClipboardField from "components/CopyToClipboardField";
import FullLoader from "components/FullLoader";
import { Form, Formik } from "formik";
import useApiResource from "hooks/useApiResource";
import useAuthority from "hooks/useAuthority";
import useAxios from "hooks/useAxios";
import usePrettyTimeElapsedSince from "hooks/usePrettyTimeElapsedSince";
import { useEffect, useState } from "react";
import { FiMoreHorizontal, FiPlus } from "react-icons/fi";

function ApiKeyTableRow({ apiKeyEntity, onDelete }) {
  const elapsedSince = usePrettyTimeElapsedSince(apiKeyEntity.lastUsedAt);
  const lastUsed = apiKeyEntity.lastUsedAt ? elapsedSince : "Never used";
  return (
    <Table.Row key={apiKeyEntity.id} className="">
      <Table.Cell className="font-mono">{apiKeyEntity.token}</Table.Cell>
      <Table.Cell className="lowercase">{apiKeyEntity.role}</Table.Cell>
      <Table.Cell className="max-w-xs break-words">{apiKeyEntity.description}</Table.Cell>
      <Table.Cell>{lastUsed}</Table.Cell>
      <Table.Cell>
        <Menu className="flex items-center justify-end">
          <Menu.Button>
            <FiMoreHorizontal className="cursor-pointer float-right" />
          </Menu.Button>
          <Menu.Items>
            <Menu.ButtonItem onClick={onDelete}>Delete</Menu.ButtonItem>
          </Menu.Items>
        </Menu>
      </Table.Cell>
    </Table.Row>
  );
}

function CreateApiKeyDialog({ isOpen, onSubmit, onClose }) {
  const axios = useAxios();
  const [apiKey, setApiKey] = useState();
  // reset apiKey if modal closes
  useEffect(setApiKey, [setApiKey, isOpen]);

  return (
    <Dialog open={!!isOpen} onClose={onClose}>
      <Dialog.Panel>
        <Dialog.Title>Create Api Key</Dialog.Title>
        {apiKey ? (
          <>
            <Dialog.Description>
              Your Api Key has been created. Copy and store it as you will not
              be able to retrieve it again.
            </Dialog.Description>
            <div>
              <div className="grid grid-cols-10 mx-2 my-1 items-center">
                <div className="col-span-3 font-medium">Api Key</div>
                <div className="col-span-7">
                  <CopyToClipboardField text={apiKey.token}>
                    <span className="font-mono break-all text-gray-700">
                      {apiKey.token}
                    </span>
                  </CopyToClipboardField>
                </div>
              </div>
              <div className="grid grid-cols-10 mx-2 my-1 items-center">
                <div className="col-span-3 font-medium">Permissions</div>
                <div className="col-span-7 lowercase">{apiKey.role}</div>
              </div>
            </div>
            <div className="flex justify-end">
              <button
                type="button"
                className="w-full sm:w-auto btn btn-outline"
                onClick={onClose}
              >
                Close
              </button>
            </div>
          </>
        ) : (
          <Formik
            initialValues={{ role: "MEMBER", description: "" }}
            onSubmit={async (values, { setSubmitting }) => {
              const { data } = await axios.post("/rest/v1/api-keys", {
                role: values.role,
                description: values.description,
              });
              setApiKey(data);
              onSubmit();
              setSubmitting(false);
            }}
          >
            {({ values, handleChange, handleBlur }) => (
              <Form>
                <div>
                  <Dialog.Description>
                    After selecting a role you will be able to see the api-key
                    once, make sure to write it down.
                  </Dialog.Description>
                  <hr className="form-row-divider" />
                  <div>
                    <Label radio className="my-1.5 cursor-pointer">
                      <Input
                        name="role"
                        value="ADMIN"
                        type="radio"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={values.role === "ADMIN"}
                      />
                      <div className="grid ml-5 grid-cols-6 items-center">
                        <span className="col-span-1 font-medium text-gray-700">
                          Admin
                        </span>
                        <span className="col-span-5 text-gray-600">
                          Can create and modify clusters, can also invite
                          members and change payment details.
                        </span>
                      </div>
                    </Label>
                    <Label radio className="my-1.5 cursor-pointer">
                      <Input
                        name="role"
                        value="MEMBER"
                        type="radio"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={values.role === "MEMBER"}
                      />
                      <div className="grid ml-5 grid-cols-6 items-center">
                        <span className="col-span-1 font-medium text-gray-700">
                          Member
                        </span>
                        <span className="col-span-5 text-gray-600">
                          Members can view most resources and download
                          kube-configs.
                        </span>
                      </div>
                    </Label>
                    <Label radio className="mt-4 mb-1.5 w-full">
                      <div className="">
                        <div className="col-span-1 font-medium text-gray-700">
                          Description
                        </div>
                      </div>
                      <Input
                        name="description"
                        className="ml-10 w-full"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Label>
                  </div>
                </div>
                <div className="flex justify-end mt-3">
                  <button
                    type="submit"
                    className="w-full sm:w-auto btn btn-blue"
                  >
                    Create
                  </button>
                  <button
                    type="button"
                    className="w-full ml-3 sm:w-auto btn btn-outline"
                    onClick={onClose}
                  >
                    Close
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </Dialog.Panel>
    </Dialog>
  );
}

export default function ApiKeys() {
  const axios = useAxios();
  const { hasAuthority } = useAuthority();
  const [createApiKeyModalOpen, setCreateApiKeyModalOpen] = useState();
  const { data, error, isFetching, refresh } =
    useApiResource("/rest/v1/api-keys");

  const handleDeleteApiKey = async (id) => {
    await axios.delete(`/rest/v1/api-keys/${id}`);
    refresh();
  };

  if (isFetching) return <FullLoader />;
  return (
    <section className="p-5">
      <div className="my-2 flex justify-between items-center">
        <div className="header">Api Keys</div>
        {hasAuthority("IS_ADMIN") && (
          <button
            onClick={() => setCreateApiKeyModalOpen(true)}
            className="btn-xs btn-blue flex items-center"
          >
            <FiPlus className="inline-block mr-1" />
            Add new
          </button>
        )}
      </div>
      <div className="mb-5 text-gray-500">
        Use Api Keys to connect to Symbiosis from terraform, pulumi or any other
        SDKs or integration.
      </div>
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Cell>Key</Table.Cell>
            <Table.Cell>Role</Table.Cell>
            <Table.Cell>Description</Table.Cell>
            <Table.Cell>Last Used</Table.Cell>
            <Table.Cell />
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {data?.map((x) => (
            <ApiKeyTableRow
              key={x.id}
              onDelete={() => handleDeleteApiKey(x.id)}
              apiKeyEntity={x}
            />
          ))}
          {!data?.length && !error && (
            <Table.ContentRow>
              <div className="py-3 flex justify-center">
                List is empty
                {hasAuthority("IS_ADMIN") && (
                  <>
                    ,{" "}
                    <button
                      onClick={() => setCreateApiKeyModalOpen(true)}
                      className="text-blue-600 hover:text-blue-700 inline-flex items-center"
                      to="/clusters/add"
                    >
                      <FiPlus className="mx-1" /> create a new API Key
                    </button>
                  </>
                )}
                .
              </div>
            </Table.ContentRow>
          )}
          {error && (
            <Table.ContentRow>
              <div className="text-red-500 text-gray-400 text-center py-3">
                {error.message}
              </div>
            </Table.ContentRow>
          )}
        </Table.Body>
      </Table>
      <CreateApiKeyDialog
        isOpen={createApiKeyModalOpen}
        onSubmit={refresh}
        onClose={() => setCreateApiKeyModalOpen(false)}
      />
    </section>
  );
}
