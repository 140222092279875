import cn from "classnames";

export default function NodePoolKubeStateBadge(props) {
  const { className, quantity, kubeState } = props;

  const style =
    kubeState === "READY"
      ? "bg-green-100 text-green-700"
      : "bg-gray-100 text-gray-500";
  const stateStr = kubeState === "READY" ? "Ready" : "NotReady";

  return (
    <span
      className={cn(
        className,
        style,
        "inline-block rounded text-xs py-0.5 px-1 font-semibold"
      )}
    >
      {quantity}× {stateStr}
    </span>
  );
}
