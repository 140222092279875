import { useState, Fragment } from "react";
import UserIdenticon from "components/UserIdenticon";
import useApiResource from "hooks/useApiResource";
import cn from "classnames";
import { Link } from "react-router-dom";
import {
  FiUsers,
  FiPlus,
  FiMessageCircle,
  FiChevronDown,
} from "react-icons/fi";
import { RiExternalLinkLine } from "react-icons/ri";
import { SiDiscord } from "react-icons/si";
import { BsQuestionSquareFill } from "react-icons/bs";
import { Popover, Menu, Transition } from "@headlessui/react";
import { Badge, Textarea } from "components";
import useUser from "hooks/useUser";
import useTeam from "hooks/useTeam";
import useAxios from "hooks/useAxios";
import SymbiosisTitle from "components/SymbiosisTitle";

function WebAppVersionBadge() {
  if (process.env.NODE_ENV === "development") {
    return (
      <Badge className="ml-2" type="danger">
        devel
      </Badge>
    );
  } else {
    return <span className="ml-1 text-md text-gray-700 font-thin">beta</span>;
  }
}

function SupportButton() {
  return (
    <span className="flex items-center mr-3">
      <Popover className="relative">
        <Popover.Button className="text-sm text-gray-600 hover:text-gray-800 focus:outline-gray-700 transition duration-200 ease-in-out flex items-center">
          <BsQuestionSquareFill size="1.3rem" />
        </Popover.Button>
        <Transition
          as={Fragment}
          enter="transition-opacity ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-in duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Popover.Panel className="absolute bg-white text-sm rounded-lg border shadow z-10 w-64 py-3 right-0 mt-2 grid">
            <a
              rel="noreferrer"
              target="_blank"
              href="https://symbiosis.host/docs"
              className="text-blue-600 hover:bg-gray-100 py-1 px-3"
            >
              Documentation <RiExternalLinkLine className="inline-block" />
            </a>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://registry.terraform.io/providers/symbiosis-cloud/symbiosis/latest/docs"
              className="text-blue-600 hover:bg-gray-100 py-1 px-3"
            >
              Terraform Plugin Docs{" "}
              <RiExternalLinkLine className="inline-block" />
            </a>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://discord.gg/bd4DKsgtkS"
              className="text-blue-600 hover:bg-gray-100 py-1 px-3"
            >
              Discord <SiDiscord className="inline-block" />
            </a>
          </Popover.Panel>
        </Transition>
      </Popover>
    </span>
  );
}
function FeedbackButton() {
  const axios = useAxios();
  const [isSubmitting, setIsSubmitting] = useState();
  const [message, setMessage] = useState("");
  const handleSubmit = () => {
    setIsSubmitting(true);
    axios.post("/rest/v1/user/feedback", { message });
    setMessage("");
    setIsSubmitting(false);
  };

  return (
    <span className="flex items-center mr-4">
      <Popover className="relative">
        <Popover.Button className="btn-xs btn-outline flex items-center">
          Feedback
          <FiMessageCircle className="ml-1" size="0.8rem" />
        </Popover.Button>
        <Transition
          as={Fragment}
          enter="transition-opacity ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-in duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Popover.Panel className="absolute bg-white rounded-lg border shadow z-30 w-64 p-3 right-0 mt-2">
            {({ open, close }) => (
              <>
                <h1 className="font-bold text-lg mb-1.5">Feedback</h1>
                <Textarea
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Send us your feedback"
                  rows="5"
                />
                <span className="float-right grid grid-cols-2 gap-2 mt-2">
                  <button
                    disabled={message?.length < 5 || isSubmitting}
                    onClick={() => {
                      handleSubmit();
                      close();
                    }}
                    className="btn-xs btn-blue"
                  >
                    Send
                  </button>
                  <button
                    onClick={close}
                    className={cn(
                      "rounded-lg float-right py-1 px-2 text-xs btn-outline"
                    )}
                  >
                    Close
                  </button>
                </span>
              </>
            )}
          </Popover.Panel>
        </Transition>
      </Popover>
    </span>
  );
}

function BillingEstimate({ forecast }) {
  return (
    <>
      {forecast.currency === "USD"
        ? `$${forecast.monthlyEstimate.toFixed(2)}`
        : `${forecast.monthlyEstimate.toFixed(2)} ${forecast.currency}`}
      {forecast.discountPercentage && (
        <span className="text-blue-500">
          {" "}
          (-{(forecast.discountPercentage).toFixed()}%)
        </span>
      )}
      {forecast.discountAmount && (
        <span className="text-xs text-blue-500">
          {" "}
          (-${forecast.discountAmount.toFixed(2)})
        </span>
      )}
    </>
  );
}

export default function Header({
  billingForecast,
  onLogout,
  onChangeTeam,
  className,
}) {
  const { team } = useTeam();
  const { data: userTeams } = useApiResource("/rest/v1/user/team");
  const otherUserTeams = userTeams?.filter((x) => x.id !== team.id);
  const user = useUser();
  if (!team) return null;
  return (
    <header
      className={cn(
        className,
        "w-full text-gray-600 sticky top-0 bg-gray-50 body-font border-b"
      )}
    >
      <div className="mx-auto h-14 px-8 flex flex-wrap flex-col md:flex-row items-center justify-between">
        <div className="flex items-center">
          <span className="ml-3 relative flex">
            <Link className="mr-3 flex items-baseline" to="/">
              <SymbiosisTitle />
              <WebAppVersionBadge />
            </Link>
            <Menu as="div" className="relative text-left">
              <Menu.Button
                as="button"
                className="pl-3 border-l-2 text-sm flex items-center cursor-pointer"
              >
                <span className="p-px mr-2 h-6 w-6 rounded bg-white text-gray-500 drop-shadow">
                  <FiUsers className="inline-block" />
                </span>
                {team.name}
                <FiChevronDown className="ml-1" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Menu.Items className="absolute left-0 min-w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {otherUserTeams?.length > 0 && (
                    <div className="px-1 py-1">
                      {otherUserTeams?.map((x) => (
                        <Menu.Item
                          className="m-1 flex items-center"
                          as="div"
                          key={x.id}
                        >
                          <button
                            onClick={() => onChangeTeam(x.id)}
                            className="text-left hover:text-white hover:bg-blue-400 rounded-md w-full p-2 text-sm"
                          >
                            {x.name}
                          </button>
                        </Menu.Item>
                      ))}
                    </div>
                  )}
                  <div className="px-1 py-1">
                    <Menu.Item as="div" className="m-1 flex items-center">
                      <span
                        className="text-left text-gray-400 select-none rounded-md w-full p-2 text-sm flex items-center"
                        to="/create-team"
                      >
                        <FiPlus className="mr-1 inline-block" />
                        Create new team
                      </span>
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </span>
        </div>
        <span className="flex title-font font-medium items-center mb-4 md:mb-0 cursor-not-allowed">
          {/* TODO search */}
        </span>
        <div className="flex items-center whitespace-nowrap">
          {billingForecast != null && (
            <span className="mr-4 text-sm">
              Estimated cost{" "}
              <span className="font-medium">
                <BillingEstimate forecast={billingForecast} />
              </span>
            </span>
          )}
          <FeedbackButton />
          <SupportButton />
          <Menu as="div" className="relative inline-block text-left">
            <Menu.Button className="flex min-w-[14rem] items-center text-sm items-center">
              <span className="relative justify-between w-full flex items-center border-l-2 pr-2 pl-3.5 h-7">
                <span className="flex items-center">
                  <UserIdenticon
                    pictureUrl={user.pictureUrl}
                    email={user.email}
                    size="1.4rem"
                    className="mr-2"
                  />
                  <span className="text-ellipsis overflow-hidden">
                    {user.name || user.email}
                  </span>
                </span>
                <FiChevronDown size="16" />
              </span>
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right grid  bg-white rounded-md shadow-lg gap-0.5 ring-1 ring-black ring-opacity-5 focus:outline-none p-1">
                <Menu.Item
                  as={Link}
                  to="/profile"
                  className="text-left hover:text-white hover:bg-blue-400 rounded-md w-full p-2 text-sm"
                >
                  My Account
                </Menu.Item>
                <Menu.Item
                  onClick={onLogout}
                  as="button"
                  className="text-left hover:text-white hover:bg-blue-400 rounded-md w-full p-2 text-sm"
                >
                  Sign out
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </header>
  );
}
