import cn from "classnames";

export default function Textarea({
  valid,
  disabled,
  className,
  children,
  ...props
}) {
  const cls = cn(
    valid == null &&
      !disabled &&
      "focus:border-blue-400 border-gray-300 focus:ring focus:ring-blue-300",
    valid == null && disabled && "cursor-not-allowed opacity-50 bg-gray-300",
    className,
    valid === true &&
      "border-green-600 focus:border-green-400 focus:ring focus:ring-green-200",
    valid === false &&
      "border-red-600 focus:border-red-400 focus:ring focus:ring-red-200",
    "block w-full text-sm rounded-md focus:outline-none"
  );
  return (
    <textarea className={cls} disabled={disabled} {...props}>
      {children}
    </textarea>
  );
}
