import { Fragment } from "react";
import { Transition, Dialog as HeadlessDialog } from "@headlessui/react";
import cn from "classnames";

export default function Dialog({ className, children, ...props }) {
  return (
    <Transition appear show={props.open} as={Fragment}>
      <HeadlessDialog
        {...props}
        className={cn(className, "fixed inset-0 z-50")}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="w-full p-6 flex min-h-full items-center justify-center">
          {children}
        </div>
      </HeadlessDialog>
    </Transition>
  );
}

Dialog.Panel = ({ className, ...props }) => (
  <div className="fixed inset-0 flex items-center justify-center mt-16 p-4">
    <Transition.Child
      as={Fragment}
      enter="ease-out duration-200"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <HeadlessDialog.Panel
        {...props}
        className={cn(
          className,
          "border shadow-xl rounded bg-white max-h-full overflow-y-auto max-w-xl p-6"
        )}
      />
    </Transition.Child>
  </div>
);

Dialog.Title = ({ className, ...props }) => (
  <HeadlessDialog.Title
    {...props}
    className={cn(className, "font-semibold text-xl text-gray-600 mb-3")}
  />
);

Dialog.Overlay = ({ className, ...props }) => (
  <HeadlessDialog.Overlay {...props} className={cn(className, "")} />
);

Dialog.Description = ({ className, ...props }) => (
  <HeadlessDialog.Description
    {...props}
    className={cn(className, "text-gray-500 text-sm pb-2")}
  />
);
