import SymbiosisTitle from "components/SymbiosisTitle";

export default function PsuedoSiteHeader() {
  return (
    <header className="w-full">
      <div className="max-w-6xl mx-auto px-5 sm:px-6">
        <div className="flex items-center justify-between h-16 md:h-20">
          {/* Site branding */}
          <div className="flex-shrink-0 mr-4">
            {/* Prevent triggering onBlur, for example on forms */}
            <a
              onMouseDown={(e) => e.preventDefault()}
              href={process.env.REACT_APP_LANDING_PAGE_PATH}
            >
              <span className="select-none flex items-baseline cursor-pointer focus-visible:opacity-75 hover:opacity-75 text-2xl font-bold leading-tighter tracking-tighter">
                <SymbiosisTitle />{" "}
                <span className="ml-1 text-base font-thin">beta</span>
              </span>
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}
