export default function Pagination({
  totalResults,
  resultsPerPage,
  currentPage,
  onChangePage,
}) {
  const hasNextPage = totalResults > (currentPage + 1) * resultsPerPage;
  const hasPreviousPage = currentPage > 0;
  if (totalResults <= resultsPerPage) return null;
  return (
    <nav className="flex my-2 justify-between items-center">
      <div className="text-sm font-semibold tracking-wide uppercase text-gray-600">
        {totalResults != null && <>{totalResults} results</>}
      </div>
      <div>
        <button
          disabled={!hasPreviousPage}
          onClick={() => onChangePage(currentPage + 1)}
          className="btn-xs btn-outline mr-1"
        >
          Previous
        </button>
        <button
          disabled={!hasNextPage}
          onClick={() => onChangePage(currentPage - 1)}
          className="btn-xs btn-outline"
        >
          Next
        </button>
      </div>
    </nav>
  );
}
