import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { HiLogout } from "react-icons/hi";
import queryString from "query-string";
import ory from "components/kratos/sdk"
import { Flow } from "components/kratos"

export default function VerifyEmail({ onLogout }) {
  const [flow, setFlow] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const { flow: flowId, return_to: returnTo } = queryString.parse(location.search)

  useEffect(() => {
    // If the router is not ready yet, or we already have a flow, do nothing.
    if (flow) {
      return
    }

    // If ?flow=.. was in the URL, we fetch it
    if (flowId) {
      ory
        .getVerificationFlow({ id: String(flowId) })
        .then(({ data }) => {
          setFlow(data)
        })
        .catch((err) => {
          switch (err.response?.status) {
            case 410:
            // eslint-disable-next-line no-fallthrough
            case 403:
              return navigate(location.pathname)
						default:
          }

          throw err
        })
      return
    }

    // Otherwise we initialize it
    ory
      .createBrowserVerificationFlow({
        returnTo: returnTo ? String(returnTo) : undefined,
      })
      .then(({ data }) => {
        setFlow(data)
      })
      .catch((err) => {
        switch (err.response?.status) {
          case 400:
            // Status code 400 implies the user is already signed in
            return navigate("/")
					default:
        }

        throw err
      })
  }, [flowId, returnTo, flow, navigate, location.pathname])

  const handleSubmit = async (values) => {
    window.history.replaceState(null, undefined, `${location.pathname}?flow=${flow?.id}`)

    ory
      .updateVerificationFlow({
        flow: String(flow?.id),
        updateVerificationFlowBody: values,
      })
      .then(({ data }) => {
        // Form submission was successful, show the message to the user!
        setFlow(data)
      })
      .catch((err) => {
        switch (err.response?.status) {
          case 400:
            // Status code 400 implies the form validation had an error
            setFlow(err.response?.data)
            return
          case 410:
            const newFlowID = err.response.data.use_flow_id
            navigate(`${location.pathname}?flow=${newFlowID}`)
            return
					default:
            throw err
        }
      })
  }

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Page content */}
      <main className="flex-grow">
        <button
          className="py-5 px-10 float-right flex items-center gap-1"
          onClick={onLogout}
        >
          <HiLogout className="inline-block" /> Sign out
        </button>
        <section className="bg-gradient-to-b from-gray-100 to-white">
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-12 pb-12 md:pt-20 md:pb-20">
              {/* Page header */}
              <div className="max-w-3xl mx-auto text-center pb-4 md:pb-12">
                <h1 className="h1">Your email needs verification.</h1>
              </div>

              {/* Form */}
              <div className="max-w-sm mx-auto">
                <div className="mb-4 grid gap-3">
                  <p>
                    In order to use our service you need to verify your email
                    address.
                  </p>
                  <p>
                    Look for the verification mail in your inbox or spam-folder.
                  </p>
                </div>
                <Flow onSubmit={handleSubmit} flow={flow} />
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
