const units = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

function byteFormatter(x) {
  let l = 0,
    n = parseInt(x, 10) || 0;
  while (n >= 1024 && ++l) {
    n = n / 1024;
  }
  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l] + "/s";
}

export default function TxRxSpan({ tx, rx }) {
  return (
    <div
      style={{ color: "hsl(220deg 30% 60%)" }}
      className="text-2xs font-mono"
    >
      <div>
        <b>tx▲</b> {tx ? byteFormatter(tx) : "? B/s"}
      </div>
      <div>
        <b>rx▼</b> {rx ? byteFormatter(rx) : "? B/s"}
      </div>
    </div>
  );
}
