import { useState, useMemo } from "react";
import { FiMoreHorizontal } from "react-icons/fi";
import useAxios from "hooks/useAxios";
import { Menu, Table, NodeStateBadge } from "components";
import {
  usePrettyTimeElapsedSince,
  useInterval,
  useToast,
  useApiResource,
} from "hooks";
import AcceptCancelDialog from "partials/AcceptCancelDialog";
import HorizontalProgressBar from "components/HorizontalProgressBar";
import TxRxSpan from "components/TxRxSpan";

function NodeTableRow({
  onSubmit,
  nodeName,
  nodeTypeName,
  nodePoolName,
  state,
  createdAt,
  canRecycle,
  canDelete,
  telemetry,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const axios = useAxios();
  const toast = useToast();
  const elapsedSince = usePrettyTimeElapsedSince(createdAt);
  const [isDeleteNodeDialogOpen, setIsDeleteNodeDialogOpen] = useState(false);

  const handleDeleteNode = async () => {
    setIsSubmitting(true);
    try {
      await axios.delete(`/rest/v1/node/${nodeName}`);
      setIsDeleteNodeDialogOpen(false);
      setIsSubmitting(false);
      onSubmit();
    } catch (err) {
      toast.exception("Failed to delete node", err);
      setIsSubmitting(false);
    }
  };

  const handleRecycleNode = async () => {
    try {
      await axios.put(`/rest/v1/node/${nodeName}/recycle`);
      onSubmit();
    } catch (err) {
      toast.exception("Failed to recycle node", err);
    }
  };

  return (
    <Table.Row className="">
      <Table.Cell>
        <span className="font-medium">{nodeName}</span>
      </Table.Cell>
      <Table.Cell>{nodeTypeName}</Table.Cell>
      <Table.Cell>
        <NodeStateBadge state={state} />
      </Table.Cell>
      <Table.Cell>{nodePoolName}</Table.Cell>
      <Table.Cell
        className="align-middle"
        style={{
          paddingTop: 0,
          paddingBottom: 0,
          paddingRight: 0,
          paddingLeft: 0,
        }}
      >
        <div className="flex gap-3 items-center">
          <HorizontalProgressBar
            title="CPU"
            value={telemetry?.cpuUsage}
            strokeColor="hsl(150deg 30% 60%)"
          />
          <HorizontalProgressBar
            title="MEM"
            value={telemetry?.memoryUsage}
            strokeColor="hsl(280deg 30% 60%)"
          />
          <TxRxSpan
            tx={telemetry?.txBytesPerSecond}
            rx={telemetry?.rxBytesPerSecond}
          />
        </div>
      </Table.Cell>
      <Table.Cell>{elapsedSince}</Table.Cell>
      <Table.Cell>
        <AcceptCancelDialog
          isOpen={isDeleteNodeDialogOpen}
          onSubmit={handleDeleteNode}
          onClose={() => setIsDeleteNodeDialogOpen(false)}
          title={`Are you sure you want to delete ${nodeName}?`}
          isSubmitting={isSubmitting}
        >
          This action is irreversible.
        </AcceptCancelDialog>
        {(canDelete || canRecycle) && (
          <Menu>
            <Menu.Button as="div" className="cursor-pointer float-right">
              <FiMoreHorizontal />
            </Menu.Button>
            <Menu.Items>
              {canRecycle && (
                <Menu.ButtonItem as="button" onClick={handleRecycleNode}>
                  Recycle
                </Menu.ButtonItem>
              )}
              {canDelete && (
                <Menu.ButtonItem
                  as="button"
                  type="danger"
                  onClick={() => setIsDeleteNodeDialogOpen(true)}
                >
                  Delete
                </Menu.ButtonItem>
              )}
            </Menu.Items>
          </Menu>
        )}
      </Table.Cell>
    </Table.Row>
  );
}

// We want to display some states that are not native to the state attribute on a Node, such as when a Node is being recycled which is based on the priority attribute.
function getClientNodeState(cluster, node) {
  if (node.priority < 1) return "RECYCLING";
  if (node.kubeVersion !== cluster.kubeVersion) return "OUTDATED";
  if (node.state === "ACTIVE")
    return node.kubeState === "UNKNOWN" ? "NOT_READY" : node.kubeState;
  return node.state;
}

const TELEMETRY_REFRESH_INTERVAL = 60 * 1000;

export default function Nodes({ cluster, onRefreshClusters }) {
  // TODO paginate instead
  const nodes = cluster.nodePools
    ?.flatMap((x) => x.nodes.map((y) => ({ ...y, nodePoolName: x.name })))
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  const { data: poolTelemetry, refresh: refreshTelemetry } = useApiResource(
    `/rest/v1/cluster/${cluster.id}/node/telemetry`
  );
  useInterval(refreshTelemetry, TELEMETRY_REFRESH_INTERVAL);
  const nodeToTelemetry = useMemo(
    () => poolTelemetry?.reduce((a, v) => ({ ...a, [v.nodeId]: v }), {}),
    [poolTelemetry]
  );

  return (
    <section className="mt-5">
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Cell>Name</Table.Cell>
            <Table.Cell>Type</Table.Cell>
            <Table.Cell>State</Table.Cell>
            <Table.Cell>Pool</Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell>Created</Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {nodes?.map((x) => (
            <NodeTableRow
              key={x.id}
              nodeName={x.name}
              nodeTypeName={x.nodeType.name}
              nodePoolName={x.nodePoolName}
              state={getClientNodeState(cluster, x)}
              onSubmit={onRefreshClusters}
              createdAt={x.createdAt}
              telemetry={nodeToTelemetry?.[x.id]}
              canRecycle={!x.isMaster}
              canDelete={
                ["ACTIVE", "FAILED"].includes(x.state) &&
                cluster.nodes.length > 1
              }
            />
          ))}
        </Table.Body>
      </Table>
    </section>
  );
}
