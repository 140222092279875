import { useState, useEffect, useCallback } from "react";
import queryString from "query-string";
import { AxiosErrorAlert, Dialog, Field } from "components";
import { useNavigate, useLocation } from "react-router-dom";
import { useSession, useAxios } from "hooks";
import useOryFlow from "hooks/useOryFlow";
import { IoReturnDownBackOutline } from "react-icons/io5";
import { Formik, Form } from "formik";
import ory from "components/kratos/sdk/index.ts"
import { Flow, Messages } from "components/kratos"

function DeactivateUserDialog({ isOpen, onClose, onSubmit }) {
  const axios = useAxios();

  return (
    <Formik
      initialValues={{ deleteUser: false }}
      onSubmit={async ({ deleteUser }, { setStatus, setSubmitting }) => {
        const path = deleteUser
          ? "/rest/v1/user/delete"
          : "/rest/v1/user/deactivate";
          try {
            await axios.post(path);
            onSubmit();
          } catch (err) {
            setStatus(err);
          } finally {
            setSubmitting(false);
          }
      }}
    >
      {({ status, isSubmitting, handleSubmit }) => (
        <Form>
          <Dialog open={isOpen} onClose={onClose}>
            <Dialog.Panel>
              <Dialog.Title>Deactivate Account</Dialog.Title>
              <Dialog.Description>
                Are you sure you want to deactivate your account? This action is
                irreversible.
              </Dialog.Description>
              <Field.RadioGroup>
                <Field.RadioButton name="deleteUser">
                  <b>Delete all data.</b> Selecting this option will purge all
                  account data and will require you to create a new account to
                  use Symbiosis in the future.
                </Field.RadioButton>
              </Field.RadioGroup>
              <AxiosErrorAlert error={status} />
              <div className="flex mt-8 justify-end">
                <button
                  className="w-full sm:w-auto btn btn-outline"
                  type="button"
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button
                  className="w-full ml-3 sm:w-auto btn btn-red"
                  type="submit"
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                >
                  Deactivate
                </button>
              </div>
            </Dialog.Panel>
          </Dialog>
        </Form>
      )}
    </Formik>
  );
}

const oryToTitle = {
  "password": "Change password",
  "profile": "Change name",
  "oidc": "Manage social logins",
  "totp": "Manage 2FA TOTP Authenticator App",
  "webauthn": "Manage Hardware Tokens and Biometrics",
};

const oryToDescription = {
  "password": "Change password",
  "profile": "Change name",
  "oidc": "Manage social logins",
  "totp": <>Add a TOTP Authenticator App to your account to improve your account
    security. Popular Authenticator Apps are{" "}
    <a href="https://www.lastpass.com" rel="noreferrer" target="_blank">
      LastPass
    </a>{" "}
    and Google Authenticator (
    <a
      href="https://apps.apple.com/us/app/google-authenticator/id388497605"
      target="_blank"
      rel="noreferrer"
    >
      iOS
    </a>
    ,{" "}
    <a
      href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US"
      target="_blank"
      rel="noreferrer"
    >
      Android
    </a>
    ).</>,
  "webauthn": "Use Hardware Tokens (e.g. YubiKey) or Biometrics (e.g. FaceID, TouchID) to enhance your account security.",
};

function OryFlowDialog({ isOpen, onClose, modal }) {
  const flowUrlFunc = useCallback((pathname, flowId) => `${pathname}?modal=${modal}&flow=${flowId}&return_to=${encodeURIComponent(`${pathname}?modal=${modal}&flow=${flowId}`)}`, [modal]);
  const [flow, onSubmitFlow] = useOryFlow(
    (flowId) => ory.getSettingsFlow({ id: String(flowId) }),
    (returnTo) => ory.createBrowserSettingsFlow({ returnTo: returnTo ? String(returnTo) : undefined }),
    (flow, values) => ory.updateSettingsFlow({ flow: String(flow?.id), updateSettingsFlowBody: values, }),
    onClose,
    flowUrlFunc,
  );

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Dialog.Panel>
        <Dialog.Title>{oryToTitle[modal]}</Dialog.Title>
        <Dialog.Description>{oryToDescription[modal]}</Dialog.Description>
        <Messages messages={flow?.ui.messages} />
        <Flow
          hideGlobalMessages
          onSubmit={onSubmitFlow}
          flow={flow}
          only={modal}
        />
      </Dialog.Panel>
    </Dialog>
  );
}

export default function Profile({ onLogout }) {
  const location = useLocation();
  const { flow: flowId, modal } = queryString.parse(location.search)
  const [modalOpen, setModalOpen] = useState(modal);
  const navigate = useNavigate();
  const session = useSession();

  const handleSubmitDeactivate = () => {
    onLogout();
  };

  useEffect(() => {
    const params = new URLSearchParams();
    if (modal || modalOpen)
      params.append("modal", modalOpen ?? modal)
    if (flowId)
      params.append("flow", flowId)
    window.history.replaceState(null, undefined, `${location.pathname}?${params.toString()}`)
  }, [modal, modalOpen, location.pathname, flowId]);

  const handleClose = () => {
    navigate({ pathname: "", search: "", replace: false})
    session.refresh();
    setModalOpen();
  };

  if (!session)
    return null;

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <DeactivateUserDialog
        isOpen={modalOpen === "deactivate"}
        onClose={() => setModalOpen()}
        onSubmit={handleSubmitDeactivate}
      />
      <OryFlowDialog
        initialName={session.identity.traits.name}
        isOpen={["name", "profile", "password", "oidc", "totp", "webauthn"].includes(modalOpen)}
        key={modalOpen}
        onClose={handleClose}
        modal={modalOpen}
      />
      {/*  Page content */}
      <main className="flex-grow bg-gradient-to-b from-gray-100 to-white">
        <section className="max-w-xl mx-auto">
          <h1 className="h1 text-center pt-20 pb-8">My Account.</h1>
          <div className="grid gap-8">
            <table className="">
              <tbody>
                <tr className="border-b">
                  <td className="py-3 text-gray-500 text-lg flex items-center">
                    Name
                  </td>
                  <td className="text-gray-700 py-3 px-5">
                    {session.identity.traits?.name || <i>none</i>}
                  </td>
                  <td className="text-right">
                    <button
                      className="btn-sm btn-outline"
                      onClick={() => setModalOpen("profile")}
                    >
                      Change
                    </button>
                  </td>
                </tr>
                <tr className="border-b">
                  <td className="py-3 text-gray-500 text-lg flex items-center">
                    Password
                  </td>
                  <td className="text-gray-700 py-3 px-5">
                    Change your password
                  </td>
                  <td className="text-right">
                    <button
                      className="btn-sm btn-outline"
                      onClick={() => setModalOpen("password")}
                    >
                      Change
                    </button>
                  </td>
                </tr>
                <tr className="border-b">
                  <td className="py-3 text-gray-500 text-lg flex items-center">
                    Social login
                  </td>
                  <td className="text-gray-700 py-3 px-5">
                    Manage your social logins with Github or Google
                  </td>
                  <td className="text-right">
                    <button
                      className="btn-sm btn-outline"
                      onClick={() => setModalOpen("oidc")}
                    >
                      Manage
                    </button>
                  </td>
                </tr>
                <tr className="border-b">
                  <td className="py-3 text-gray-500 text-lg flex items-center">
                    Authenticator
                  </td>
                  <td className="text-gray-700 py-3 px-5">
                    Manage Authenticator Apps for 2FA
                  </td>
                  <td className="text-right">
                    <button
                      className="btn-sm btn-outline"
                      onClick={() => setModalOpen("totp")}
                    >
                      Manage
                    </button>
                  </td>
                </tr>
                <tr className="border-b">
                  <td className="py-3 text-gray-500 text-lg flex items-center">
                    WebAuthn
                  </td>
                  <td className="text-gray-700 py-3 px-5">
                    Manage Hardware Tokens and Biometrics
                  </td>
                  <td className="text-right">
                    <button
                      className="btn-sm btn-outline"
                      onClick={() => setModalOpen("webauthn")}
                    >
                      Manage
                    </button>
                  </td>
                </tr>
                <tr>
                  <td className="text-gray-500 py-3 text-lg">Deactivate</td>
                  <td className="text-gray-700 py-3 px-5">
                    Make sure you have deleted or transfered ownership of any
                    teams that you own.
                  </td>
                  <td className="text-right">
                    <button
                      className="btn-sm btn-red"
                      onClick={() => setModalOpen("deactivate")}
                    >
                      Deactivate
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="flex justify-end">
            <button
              className="mr-1.5 btn-sm btn-outline mt-10"
              onClick={onLogout}
            >
              Sign out
            </button>
            <button
              className="ml-1.5 btn-sm btn-outline mt-10"
              onClick={() => navigate(-1)}
            >
              Go back <IoReturnDownBackOutline className="inline-block" />
            </button>
          </div>
        </section>
      </main>
    </div>
  );
}
