import { useState } from "react";
import useAxios from "hooks/useAxios";
import { Link, useParams, useNavigate, Navigate } from "react-router-dom";
import useApiResource from "hooks/useApiResource";
import LoadingButton from "components/LoadingButton";
import { Alert } from "components";
import { FiArrowRight } from "react-icons/fi";

export default function RedeemDiscount({ teamId }) {
  const axios = useAxios();
  const navigate = useNavigate();
  const [teamIdForApply, setTeamIdForApply] = useState(teamId);
  const { discountId } = useParams();

  const {
    data: teams,
    isFetching: isFetchingTeams,
    error: errorTeams,
  } = useApiResource("/rest/v1/user/team");

  const {
    data: discount,
    isFetching,
    error,
  } = useApiResource(`/rest/v1/discount/${discountId}`);

  if (isFetching || isFetchingTeams) return null;

  if (!teams.length) {
    return <Navigate to="/create-team" />;
  }

  if (error && error?.response?.status !== 404) throw error;
  if (errorTeams) throw errorTeams;

  const handleAcceptDiscount = async () => {
    axios.defaults.headers.common["X-Stim-Team"] =
      teamIdForApply || teams[0].id;
    await axios.post(`/rest/v1/discount/${discountId}`);
    navigate("/");
  };
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Page content */}
      <main className="flex-grow">
        <section className="bg-gradient-to-b from-gray-100 to-white">
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-12 pb-12 md:pt-20 md:pb-20">
              {/* Page header */}
              <div className="max-w-3xl mx-auto text-center pb-4">
                <h1 className="h1">Redeem Discount.</h1>
              </div>

              <div className="max-w-sm mx-auto">
                {error ? (
                  <>
                    <Alert type="danger">
                      <p className="font-medium">Discount was not found.</p>
                      <p className="mt-2">
                        Most likely it was already redeemed or the code was
                        invalid. Please contact support if you have any
                        questions.
                      </p>
                    </Alert>
                    <div className="flex justify-end">
                      <div>
                        <button
                          onClick={() => {
                            navigate("/");
                          }}
                          className="mt-5 btn text-white bg-blue-600 hover:bg-blue-700 w-full"
                        >
                          Continue
                          <FiArrowRight className="ml-2 inline-block" />
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <Alert type="info">
                      Collect this discount to receive{" "}
                      <span className="font-semibold">
                        {discount.amountOff
                          ? `${(discount.amountOff / 100).toFixed(
                              2
                            )} ${discount.currency.toUpperCase()}`
                          : `${discount.percentOff}%`}
                      </span>{" "}
                      off{" "}
                      {discount.durationMonths
                        ? `for ${discount.durationMonths} months`
                        : "monthly"}
                      .
                    </Alert>
                    <div className="text-medium mt-4">
                      Applying to{" "}
                      {teams.length > 1 ? (
                        <select
                          value={teamIdForApply || teams[0]?.id}
                          onChange={(e) => setTeamIdForApply(e.target.value)}
                        >
                          {teams.map((x) => (
                            <option key={x.id} value={x.id}>
                              {x.name}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <b>{teams[0].name}</b>
                      )}
                    </div>
                    {/* Form */}
                    <div className="max-w-sm mx-auto">
                      <form>
                        <div className="flex flex-wrap -mx-3 mt-6">
                          <div className="w-full px-3">
                            <LoadingButton
                              onClick={handleAcceptDiscount}
                              className="btn text-white bg-blue-600 hover:bg-blue-700 w-full"
                            >
                              Apply
                            </LoadingButton>
                            <span className="block text-center cursor-pointer mt-2 btn text-gray-500 border-gray-200 border hover:bg-gray-100 w-full">
                              <Link to="/">Ignore</Link>
                            </span>
                          </div>
                        </div>
                      </form>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
