import { useState } from "react";
import { saveAs } from "file-saver";
import {
  useAxios,
  useApiResource,
  usePaginatedApiResource,
  usePrettyTimeElapsedSince,
} from "hooks";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Badge, Table } from "components";
import { IoGitBranch } from "react-icons/io5";
import { Pagination, ProjectRunStateBadge } from "components";
import {
  IoCloudDownloadOutline,
  IoLogoGithub,
  IoGitPullRequest,
} from "react-icons/io5";
import ProjectLayout from "./ProjectLayout";

function PrStateBadge({ state }) {
  if (state === "OPEN") return <Badge type="success">Open</Badge>;
  else if (state === "CLOSED") return <Badge type="danger">Closed</Badge>;
  else if (state === "MERGED")
    return <Badge className="bg-purple-100 text-purple-500">Merged</Badge>;
  else throw Error(`Invalid state: ${state}`);
}

const RUNS_PER_PAGE = 10;

function Preview({ pullRequest }) {
  const { projectName, pullRequestId } = useParams();
  const [runPage, setRunPage] = useState(0);
  const {
    content: runs,
    isFetching: isFetchingRun,
    totalElements: runElements,
    error: errorRun,
  } = usePaginatedApiResource(
    `/rest/v1/project/pull-request/${pullRequestId}/run`,
    runPage,
    { pageSize: RUNS_PER_PAGE }
  );
  const axios = useAxios();
  const createdSince = usePrettyTimeElapsedSince(pullRequest?.createdAt);

  const handleDownloadConfig = async (clusterName) => {
    const { data } = await axios.get(
      `/rest/v1/cluster/${clusterName}/identity`
    );
    var blob = new Blob([data.kubeConfig], {
      type: "text/plain;charset=utf-8",
    });

    saveAs(blob, "config");
  };

  if (isFetchingRun) return null;
  if (errorRun) throw errorRun;
  console.log(pullRequest);

  return (
    <section className="my-5">
      <div className="grid grid-cols-2 gap-3 max-w-2xl">
        <div>
          <div className="text-xs text-gray-500 font-medium tracking-wider">
            PULL REQUEST
          </div>
          <div className="text-gray-700">
            <IoGitPullRequest className="inline-block" />{" "}
            {pullRequest.issueName}{" "}
            <PrStateBadge state={pullRequest.pullRequestState} />
          </div>
        </div>
        <div>
          <div className="text-xs text-gray-500 font-medium tracking-wider">
            BRANCH
          </div>
          <div className="text-gray-700">
            <IoLogoGithub className="inline-block" /> {pullRequest.gitRef}
          </div>
        </div>
        <div>
          <div className="text-xs text-gray-500 font-medium tracking-wider">
            CREATED
          </div>
          <div className="text-gray-700">{createdSince}</div>
        </div>
        <div>
          <div className="text-xs text-gray-500 font-medium tracking-wider">
            PREVIEW ENVIRONMENT
          </div>
          {pullRequest.previewClusterId ? (
            <div className="text-gray-700">
              <Link to={`/clusters/${pullRequest.previewClusterName}`}>
                {pullRequest.previewClusterName}{" "}
              </Link>
              <button
                onClick={() =>
                  handleDownloadConfig(pullRequest.previewClusterName)
                }
                className="text-xs text-blue-500"
              >
                kubeconfig <IoCloudDownloadOutline className="inline-block" />
              </button>
            </div>
          ) : (
            <div className="text-gray-700">N/A</div>
          )}
        </div>
      </div>
      <h2 className="text-2xl mb-2 mt-4 font-semibold text-gray-600">
        Deployments
      </h2>
      <Table>
        <Table.Body>
          {runs?.map((x) => (
            <Table.Row key={x.id}>
              <Table.Cell className="bg-gray-50 w-[20%]">
                <Link to={`/projects/${projectName}/run/${x.id}`}>
                  <div className="text-gray-600 font-medium">
                    {x.commitName}
                  </div>
                </Link>
                <div className="flex items-center">
                  <IoGitBranch className="inline-block" /> {x.gitRef}
                </div>
              </Table.Cell>
              <Table.Cell className="bg-gray-50">
                <div className="font-bold">
                  <ProjectRunStateBadge state={x.state} />
                </div>
                <div className="text-gray-600"></div>
              </Table.Cell>
              <Table.Cell className="text-right bg-gray-50">
                <span className="text-gray-600">By {x.user}</span>
                <img
                  className="h-8 w-8 rounded shadow ml-3 inline-block"
                  src={x.userAvatarUrl}
                  alt={`${x.user} avatar`}
                />
              </Table.Cell>
            </Table.Row>
          ))}
          {!runs?.length && (
            <Table.ContentRow>
              <div className="py-3 flex justify-center">
                No deployments found. Go make a pull request!
              </div>
            </Table.ContentRow>
          )}
        </Table.Body>
      </Table>
      <Pagination
        resultsPerPage={RUNS_PER_PAGE}
        totalResults={runElements}
        currentPage={runPage}
        onChange={setRunPage}
      />
    </section>
  );
}

export default function PreviewLayout({ project }) {
  const { pullRequestId } = useParams();
  const { data: pullRequest, error } = useApiResource(
    `/rest/v1/project/pull-request/${pullRequestId}`
  );
  if (error) throw error;
  const breadcrumbs = pullRequest && [`#${pullRequest.issueId}`];

  return (
    <ProjectLayout breadcrumbs={breadcrumbs} project={project}>
      {pullRequest && <Preview pullRequest={pullRequest} />}
    </ProjectLayout>
  );
}
